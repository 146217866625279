import React from 'react'
import { Box, Typography, useTheme } from '@mui/material';
import Dolhin from './img/1.png';
import Smart from './img/2.png';
import CarPay from './img/3.png';
import Ewa from './img/4.png';
import Labs from './img/5.png';
import Slider from 'react-slick';


const images = [Dolhin, Smart, CarPay, Ewa, Labs];

const OurPartners = () => {
    const theme = useTheme();

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 0,
        cssEase: "linear",
        centerPadding: "20px",
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: "140px",
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: "90px",
                },
            },
        ],
    };
    return (
        <Box sx={{
            width: '100%',
            // mt: { xs: 2, sm: 0 }, py: { xs: 4, sm: 0 }
        }}>
            <Slider {...settings} >
                {images.map((image, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            component="img"
                            src={image}
                            alt={`Partner ${index + 1}`}
                            sx={{
                                width: '100%',
                                maxWidth: '300px',
                                height: 'auto',
                                objectFit: 'contain',
                                borderRadius: '16px',
                            }}
                        />
                    </Box>
                ))}
            </Slider>
        </Box>
    )
}

export default OurPartners
