import * as React from "react"

function LogoBeepFooter() {
    return (
        <svg
            width={177}
            height={68}
            viewBox="0 0 177 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.257 13.424a17.528 17.528 0 00-10.3 3.314V1.108A1.1 1.1 0 007.86 0H1.098A1.1 1.1 0 000 1.107V47.16a1.1 1.1 0 001.098 1.107H7.86a1.1 1.1 0 001.098-1.107v-1.611a17.528 17.528 0 0010.3 3.314c9.765 0 17.694-7.942 17.694-17.723 0-9.782-7.929-17.715-17.695-17.715zm0 27.458c-5.367 0-9.72-4.36-9.72-9.735 0-5.376 4.353-9.736 9.72-9.736 5.367 0 9.72 4.36 9.72 9.735 0 5.376-4.353 9.736-9.72 9.736zM74.338 41.493a1.11 1.11 0 01.015 1.588C69.596 47.747 63.573 49 59.221 49c-12.663 0-19.593-7.941-19.593-17.685 0-10.079 7.86-18.02 18.86-18.02 9.195 0 18.008 6.17 18.503 19.006a1.108 1.108 0 01-1.105 1.153H49.493c.198 4.268 4.132 7.406 10.46 7.406 3.186 0 6.373-1.466 8.728-3.42a1.103 1.103 0 011.464.045l4.193 4.01zm-24.647-14.05h17.123c-.664-4.406-5.131-6.07-8.463-6.07-3.194-.008-7.463 1.466-8.66 6.07z"
                fill="#222221"
            />
            <path
                d="M170.105 36.385c3.53 0 6.328 2.756 6.328 6.376 0 3.55-2.798 6.23-6.328 6.23-3.598 0-6.266-2.68-6.266-6.23 0-3.62 2.668-6.377 6.266-6.377zm2.745-4.238h-5.428a.997.997 0 01-.991-.917l-2.249-29.115a.994.994 0 01.991-1.07h9.918c.58 0 1.029.49.991 1.07l-2.249 29.115a.984.984 0 01-.983.917z"
                fill="#fff"
            />
            <path
                d="M157.228 31.162c0-9.782-7.929-17.723-17.694-17.723a17.528 17.528 0 00-10.3 3.314v-1.611a1.1 1.1 0 00-1.098-1.107h-6.762a1.1 1.1 0 00-1.098 1.107V62.43a1.1 1.1 0 001.098 1.107h6.762a1.1 1.1 0 001.098-1.107V45.555a17.528 17.528 0 0010.3 3.314c9.773.016 17.694-7.918 17.694-17.707zm-7.974 0c0 5.375-4.353 9.735-9.72 9.735-5.367 0-9.721-4.36-9.721-9.735 0-5.376 4.354-9.736 9.721-9.736s9.72 4.36 9.72 9.736zM114.27 41.493a1.11 1.11 0 01.015 1.588C109.528 47.747 103.505 49 99.152 49 86.49 49 79.56 41.058 79.56 31.314c0-10.079 7.86-18.02 18.86-18.02 9.194 0 18.007 6.17 18.503 19.006a1.108 1.108 0 01-1.106 1.153H89.425c.198 4.268 4.132 7.406 10.459 7.406 3.187 0 6.373-1.466 8.729-3.42a1.104 1.104 0 011.464.045l4.193 4.01zm-24.655-14.05h17.123c-.664-4.406-5.131-6.07-8.463-6.07-3.194-.008-7.455 1.466-8.66 6.07z"
                fill="#222221"
            />
        </svg>
    )
}

export default LogoBeepFooter
