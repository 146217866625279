import * as React from "react"

function CalendarIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
        >
            <path
                d="M14.309 1.942h-1.844V.81a.715.715 0 00-.71-.71h-.863a.715.715 0 00-.71.71v1.116H5.886V.81a.715.715 0 00-.71-.71h-.863a.715.715 0 00-.71.71v1.116H1.759C.778 1.925 0 2.72 0 3.685V14.34c0 .98.795 1.759 1.759 1.759h12.533c.98 0 1.759-.795 1.759-1.76V3.685c.017-.947-.778-1.742-1.742-1.742zm0 13.176H1.759a.758.758 0 01-.761-.761V6.052h14.055v8.305a.744.744 0 01-.744.76z"
                fill="#868C99"
            />
            <path
                d="M4.854 7.591h-1.86a.455.455 0 00-.457.457v1.404c0 .253.203.456.457.456h1.86a.455.455 0 00.457-.456V8.048a.455.455 0 00-.457-.457zM8.981 7.591h-1.86a.455.455 0 00-.457.457v1.404c0 .253.203.456.457.456h1.86a.455.455 0 00.457-.456V8.048a.455.455 0 00-.457-.457zM13.126 7.591h-1.86a.455.455 0 00-.457.457v1.404c0 .253.203.456.456.456h1.86a.455.455 0 00.457-.456V8.048a.455.455 0 00-.456-.457zM4.854 11.312h-1.86a.455.455 0 00-.457.457v1.403c0 .254.203.457.457.457h1.86a.455.455 0 00.457-.457V11.77a.455.455 0 00-.457-.457zM8.981 11.312h-1.86a.455.455 0 00-.457.457v1.403c0 .254.203.457.457.457h1.86a.455.455 0 00.457-.457V11.77a.455.455 0 00-.457-.457zM13.126 11.312h-1.86a.455.455 0 00-.457.457v1.403c0 .254.203.457.456.457h1.86a.455.455 0 00.457-.457V11.77a.455.455 0 00-.456-.457z"
                fill="#868C99"
            />
        </svg>
    )
}

export default CalendarIcon
