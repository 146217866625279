import React from 'react'
import { Box, Typography, useTheme, Button, useMediaQuery } from "@mui/material";
import BacgroundImg from './img/BacgroundImg.png'
import iPhoneImg from './img/iPhoneImg.png'
import OrantaIcon from './img/OrantaIcon';
import DownloadIcon from './img/DownloadIcon';
import IconWithText from '../uknown/IconWithText';


const CaseStudyBlock = () => {
    const theme = useTheme();
    const isLocalTablet = useMediaQuery(`(max-width:800px)`);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                margin: '0 auto',
                width: "100%",
                mb: { xs: 8, md: 12 },
            }}
        >
            {isLocalTablet ? <>
                <IconWithText text="Discover how Beep!" variant="h1" color="grey.650" align="left" />
                <IconWithText text="helped Oranta" variant="h1" color="grey.650" iconSize="large" align="left" />
            </>
                :
                <IconWithText text="Discover how Beep! helped Oranta" variant="h1" color="grey.650" iconSize="large" align="left" />}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    mt: 8,
                    width: "100%",
                    position: "relative",
                    // maxWidth: "1145px",
                    minHeight: "760px",
                    background: { xs: 'rgba(0, 0, 0, 0.5)', md: 'rgba(0, 0, 0, 0.5)' },
                    borderRadius: "20px",
                    overflow: "hidden",
                }}
            >
                {/* <Box
                    sx={{
                        // flex: 7,
                        position: "absolute",
                        // left: 100,
                        // p: 1,
                        backgroundImage: `url(${BacgroundImg})`,
                        backgroundSize: "cover",
                        // backgroundOrigin: "border-box",
                        // backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        zIndex: -1,
                        // backgroundPositionY: 'right',
                        // backgroundPosition: 'right',
                        display: "flex",
                        // flexDirection: "column",
                        // justifyContent: "flex-end",
                        // padding: "48px",
                        // gap: "24px",
                    }}
                /> */}
                <Box
                    component="img"
                    src={BacgroundImg}
                    sx={{
                        position: "absolute",
                        height: { xs: "60%", md: "auto" },
                        objectFit: "cover",
                        width: "100%",
                        zIndex: -1,
                        // minHeight: "760px",
                        // width: "78%",
                    }}
                />

                <Box
                    sx={{
                        flex: 7,
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        padding: "48px",
                        gap: "16px",
                    }}
                >
                    <OrantaIcon />

                    <Typography
                        variant='h2' sx={{
                            fontSize: { xs: '24px', sm: '32px', md: '40px', },
                            textAlign: 'left', maxWidth: '90%',
                            lineHeight: { xs: '28px', md: '40px' }, mt: 3
                        }}>
                        Insurance company Oranta launches new mobile digital ecosystem for car drivers
                    </Typography>

                    <Typography
                        variant='body2' sx={{
                            textAlign: 'left',
                            color: theme.palette.text.primary,
                            maxWidth: '80%',
                            fontWeight: 600
                        }}>

                        The Beep! app customised for Oranta delivers a comprehensive suite
                        of services for car owners. Key features include the ability to
                        purchase various types of insurance, AI-powered car damage
                        inspections, and flexible roadside assistance. It also provides
                        efficient traffic fine management, convenient parking solutions,
                        and integrated fuel discounts.
                    </Typography>

                    <Button
                        variant="contained"
                        endIcon={<DownloadIcon />}
                        sx={{
                            marginTop: 4,
                            height: "48px",
                            width: "212px",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            textTransform: "none",
                            fontSize: "16px",
                            borderRadius: '12px',
                        }}
                        component="a"
                        href="../../../public/Oranta Case.pdf"
                        download="Oranta Case.pdf"
                    >
                        Download as PDF
                    </Button>
                </Box>

                <Box
                    sx={{
                        flex: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "end",
                        backgroundColor: theme.palette.grey[900],
                        borderRadius: "20px",
                        // m: 1
                    }}
                >
                    <Box
                        component="img"
                        src={iPhoneImg}
                        alt="iPhone mockup"
                        sx={{
                            width: "402px",
                            height: "auto",
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};



// const CaseStudyBlock = () => {
//     const theme = useTheme();

//     return (
//         <Box sx={{
//             display: "flex",
//             flexDirection: "column",
//         }}>
//             <CaseStudyIcon />

//             <Box sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 position: 'relative',
//             }}>
//                 <Box
//                     sx={{
//                         // height: '100%',
//                         bottom: 0,
//                         position: 'absolute',
//                         maxWidth: '1145px',
//                         width: '100%',
//                         minHeight: '760px',
//                         backgroundImage: `url(${BacgroundImg})`,
//                         backgroundSize: 'cover',
//                         backgroundRepeat: 'no-repeat',
//                         borderRadius: '20px',
//                         backgroundPosition: 'center',
//                         display: 'flex',
//                         overflow: 'hidden',
//                         padding: '4px',
//                     }}
//                 />

//                 <Grid
//                     container
//                     sx={{
//                         position: "relative",
//                         width: "100%",
//                         height: "100%",
//                         display: "flex",
//                     }}
//                 >

//                     <Grid
//                         item
//                         xs={12}
//                         lg={7}
//                         sx={{
//                             display: 'flex',
//                             flexDirection: 'column',
//                             justifyContent: 'flex-end',
//                             padding: '48px',
//                             gap: '24px',
//                         }}
//                     >
//                         <OrantaIcon />

//                         <Typography variant='h2' sx={{ fontSize: '32px !important', maxWidth: '80%', lineHeight: '50px' }}>
//                             Insurance company Oranta launches new mobile digital ecosystem for car drivers
//                         </Typography>

//                         <Typography variant='body1' sx={{ maxWidth: '72%' }}>
//                             The Beep! app customised for Oranta delivers a comprehensive suite of services for car owners. Key features include the ability to purchase various types of insurance, AI-powered car damage inspections, and flexible roadside assistance. It also provides efficient traffic fine management, convenient parking solutions, and integrated fuel discounts.
//                         </Typography>

//                         <Button
//                             variant="contained"
//                             endIcon={<DownloadIcon />}
//                             sx={{
//                                 mt: 4,
//                                 height: '48px',
//                                 width: '212px',
//                                 backgroundColor: theme.palette.primary.main,
//                                 color: theme.palette.primary.contrastText,
//                                 textTransform: 'none',
//                             }}>
//                             Download as PDF
//                         </Button>
//                     </Grid>

//                     <Grid
//                         item
//                         xs={12}
//                         lg={5}
//                         sx={{
//                             display: 'flex',
//                             flexDirection: 'column',
//                             justifyContent: 'center',
//                             borderRadius: '20px',
//                             height: '752px',
//                             width: '540px',
//                             backgroundColor: theme.palette.background.default,
//                         }}
//                     >
//                         <Box
//                             component="img"
//                             src={iPhoneImg}
//                             alt="Demo Image"
//                             sx={{
//                                 // width: '100%',
//                                 // height: '100%',
//                                 // width: '373px',
//                                 // height: '768px',
//                                 minWidth: '373px',
//                                 height: "auto",
//                                 // objectFit: 'cover',
//                             }}
//                         />
//                     </Grid>
//                 </Grid>

//             </Box>
//         </Box>
//     )
// }

export default CaseStudyBlock


