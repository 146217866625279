import React, { useState } from 'react'
import OurSolutionsIcon from './img/OurSolutionsIcon'
import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import HoverList from './components/HoverList'
import ActiveApp from './img/ActiveApp'
import AppInactive from './img/AppInactive'
import DevAppInactive from './img/DevAppInactive'
import DevAppActive from './img/DevAppActive'
import WhiteLabelInactive from './img/WhiteLabelInactive'
import WhiteLabelActive from './img/WhiteLabelActive'


const solutionsData = [
    {
        iconActive: <WhiteLabelActive />,
        iconInactive: <WhiteLabelInactive />,
        primaryTitle: 'Beep! White Label Platform',
        secondaryTitle: 'If you don’t have an app',
        description: 'We deliver a beautifully designed and highly engaging fully tested white label version of the Beep! Platform in your brand colours. Why request the Beep! White Label Platform?',
        options: [
            {
                title: 'Designed for Car Drivers',
                description: 'Where most companies design their apps around the product they want to sell, Beep! White Label is different. The app is designed first and foremost to engage the car driver and once engaged, the white label partner can sell their own and any other car services'
            },
            {
                title: 'Robust Architecture',
                description: 'The Beep! White Label Platform has been built by one team within one architecture. This makes it extremely easy to deploy and support'
            },
            {
                title: 'Business Risk',
                description: 'Our core platform is available “out of the box” which reduces development time (design, development and testing), project cost and increases your speed to market'
            },
            {
                title: 'Confidence',
                description: 'Your project risk is greatly reduced in the knowledge that the entire platform has been fully-tested and is operating successfully'
            },
            {
                title: 'Scalability',
                description: 'The platform is designed to grow with your business and can accommodate growth in user numbers, data volume, and overall system complexity'
            },
        ]
    },
    {
        iconActive: <ActiveApp />,
        iconInactive: <AppInactive />,
        primaryTitle: 'Beep! Embedded Platform',
        secondaryTitle: 'If you have an app',
        description: 'We can embed our engaging car services and features into your existing app. Why request Beep! Embedded Platform?',
        options: [
            {
                title: 'Retain current services',
                description: 'With Beep! Embedded Platform you get to retain all the services that your customers enjoy from your existing app'
            },
            {
                title: 'Retain current user base',
                description: 'Your loyal users do not need to download a new app, they simply update their existing app to benefit from the new services'
            },
            {
                title: 'Flexibility',
                description: 'You get to determine the timing of the delivery of new services on your app'
            },
            {
                title: 'Business Risk',
                description: 'Deploying our stable and widely used car services components reduces development time and increases your speed to market'
            },
            {
                title: 'Confidence',
                description: 'Your project risk is reduced in the knowledge that the deployed services have been fully-tested and operating successfully'
            },
            {
                title: 'Scalability',
                description: 'The services are built to grow with your business and can accommodate growth in user numbers, data volume, and overall system complexity'
            },
        ]
    },
    {
        iconActive: <DevAppActive />,
        iconInactive: <DevAppInactive />,
        primaryTitle: 'Beep! App Development Services',
        secondaryTitle: 'If you need bespoke development',
        description: 'We can develop a tailored solution that meets your specific requirements, providing the necessary flexibility and scalability to suit your unique needs',
        options: [
            {
                title: 'End-to-End App Development',
                description: 'We offer comprehensive app development services tailored specifically for the car services ecosystem but also including financial services such as car loans and in-app payments. From initial concept to final deployment, our dedicated team ensures every detail aligns with your business needs'
            },
            {
                title: 'Customized Solutions for the Car Services Ecosystem',
                description: 'Drawing on years of experience, we design bespoke applications that enhance user experiences, streamline processes, and drive efficiency'
            },
            {
                title: 'Agile Development Approach',
                description: 'Our agile methodology allows for rapid iteration and flexibility, ensuring your app development aligns with evolving business goals'
            },
            {
                title: 'User-Centric App Design',
                description: 'We prioritize user-centric design, crafting intuitive and engaging apps that deliver exceptional functionality and seamless interactions for your customers across the car services ecosystem'
            },
            {
                title: 'Scalable and Future-Proof Applications',
                description: 'Our apps are built with scalability in mind, ensuring they grow with your business. We use the latest technologies to future-proof your applications, making them adaptable to new market demands and technological advancements'
            }
        ]
    },
]

interface IProps {
    setOpenEstimate: (value: boolean) => void;
}

const OurSolutions = ({ setOpenEstimate }: IProps) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
            }}
        >
            <OurSolutionsIcon />
            <Grid
                container
                spacing={2}
                item
                md={10.5}
                sx={{
                    marginTop: "64px",
                    flexGrow: 1,
                    alignItems: "stretch",
                    justifyContent: { xs: "center", sm: "center", md: "flex-start" },
                }}
            >
                {solutionsData.map((solution, index) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const [hover, setHover] = useState(false);

                    return (
                        <Grid item xs={12} md={4} display="flex" justifyContent="center" key={index}>
                            <Grid
                                container
                                sx={{
                                    padding: "20px",
                                    height: "100%",
                                    flexDirection: "column",
                                    border: "1px solid",
                                    borderColor: theme.palette.grey[600],
                                    borderRadius: "20px",
                                    backgroundColor: theme.palette.grey[700],
                                    maxWidth: "400px",
                                    transition: "all 0.3s ease-in-out",
                                    ":hover": {
                                        backgroundColor: theme.palette.grey[600],
                                    },
                                }}
                            >
                                <Box
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 2,
                                        minHeight: "300px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "124px",
                                            height: "124px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
                                            position: "relative",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 10,
                                                opacity: hover ? 0 : 1,
                                                transition: "opacity 0.3s ease-in-out",
                                            }}
                                        >
                                            {solution.iconInactive}
                                        </Box>

                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 10,
                                                opacity: hover ? 1 : 0,
                                                transition: "opacity 0.3s ease-in-out",
                                            }}
                                        >
                                            {solution.iconActive}
                                        </Box>
                                    </Box>


                                    <Typography
                                        variant="h5"
                                        sx={{
                                            textAlign: "center",
                                            mt: 6, color: theme.palette.text.primary
                                        }}
                                    >
                                        {solution.secondaryTitle}
                                    </Typography>

                                    <Typography
                                        variant="subtitle1"
                                        sx={{ fontSize: "12px", fontWeight: 700, }}
                                    >
                                        {solution.primaryTitle}
                                    </Typography>

                                    <Typography color='grey.400' sx={{ fontSize: "12px", fontWeight: 700, textAlign: "center" }}>
                                        {solution.description}
                                    </Typography>
                                </Box>
                                <HoverList options={solution.options} />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            <Button
                variant="contained"
                onClick={() => setOpenEstimate(true)}
                sx={{
                    mt: 12,
                    borderRadius: '12px',
                    textTransform: 'none',
                    padding: theme.spacing(3, 12),
                    fontWeight: 700,
                }}
            >
                Get an estimate!
            </Button>
        </Box>
    );
};

export default OurSolutions