import React from 'react';
import { Box, Typography } from '@mui/material';

interface CardItemProps {
    title: string;
    subtitle: string;
    description?: string;
    imageUrl: string;
    isActive?: boolean;
}


const CardItem: React.FC<CardItemProps> = ({ title, subtitle, imageUrl, description, isActive }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '24px',
                overflow: 'hidden',
                backgroundColor: 'grey.600',
                transition: 'opacity 0.3s ease',
                mb: { xs: 16, sm: 32 },
                width: { xs: '80%', sm: '90%' },
                maxWidth: { xs: '312px' },
                margin: '0 auto',
                flex: 1,
                minHeight: "100%",
                alignItems: "stretch",
            }}
        >
            <Box
                component="img"
                src={imageUrl}
                alt={title}
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    padding: '24px 16px',
                    gap: '16px',
                    color: 'text.primary',
                    alignItems: 'stretch',
                    // minHeight: '100%',
                    minHeight: '220px'
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        color: 'text.primary',
                        textAlign: 'center',
                    }}
                >
                    {title}
                </Typography>

                <Typography
                    variant="body2"
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {subtitle}
                </Typography>
            </Box>
        </Box>
    );
};

export default CardItem;
