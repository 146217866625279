import * as React from "react"

function Banks() {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x={0.5} width={47} height={48} rx={10} fill="#24262C" />
            <path
                d="M16 33h16.2M18.7 29.4v-6.3M22.3 29.4v-6.3M25.898 29.4v-6.3M29.5 29.4v-6.3M24.099 15l7.2 4.5h-14.4l7.2-4.5z"
                stroke="#F7B500"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default Banks
