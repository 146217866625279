import * as React from "react"

function AcquireIcon() {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M19.062 18.588a2.99 2.99 0 01-3.33 3.295H8.268a2.987 2.987 0 01-3.33-3.295 4.877 4.877 0 015.194-5.19h3.735a4.879 4.879 0 015.195 5.19zm-7.054-7.154a3.668 3.668 0 10-3.682-3.668 3.68 3.68 0 003.682 3.668zm7.095-2.701H17.42a.314.314 0 00-.303.24 5.113 5.113 0 01-1.476 2.563.314.314 0 00.168.524 5.87 5.87 0 013.902 2.908.314.314 0 00.293.188h.44a2.288 2.288 0 002.542-2.532 3.656 3.656 0 00-3.882-3.891zm-1.339-6.727a2.792 2.792 0 00-2.433 1.418.285.285 0 00-.022.21.266.266 0 00.11.16 5.131 5.131 0 011.82 3.526.27.27 0 00.08.18.324.324 0 00.217.09 2.799 2.799 0 10.228-5.587v.003zM1.014 12.624a2.287 2.287 0 002.543 2.532h.44a.314.314 0 00.292-.188 5.871 5.871 0 013.903-2.908.315.315 0 00.167-.524 5.112 5.112 0 01-1.475-2.563.314.314 0 00-.304-.24H4.896a3.654 3.654 0 00-3.881 3.891zM3.443 4.8a2.802 2.802 0 002.793 2.804c.078 0 .154 0 .228-.01A.324.324 0 006.68 7.5a.27.27 0 00.081-.179 5.133 5.133 0 011.82-3.526.266.266 0 00.11-.16.285.285 0 00-.022-.21 2.794 2.794 0 00-5.231 1.375l.004-.002z"
                fill="#222221"
            />
        </svg>
    )
}

export default AcquireIcon
