import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import AboutUsIcon from './img/AboutUsIcon';


const AboutUsBlock: React.FC = () => {
    // const theme = useTheme();
    const style = {
        color: 'grey.400',
        textAlign: 'left',
        fontWeight: 700,
    }

    return (
        <Box
            sx={{
                py: 16,
                overflow: 'hidden',
                flexDirection: 'column',
                display: 'flex', alignItems: 'center'
            }}
        >
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'start', mb: 8 }}>
                    <AboutUsIcon />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, maxWidth: { xs: '100%', sm: '490px' }, }}>
                    <Typography variant="h4" sx={{ textAlign: 'left', color: 'grey.700', fontSize: '32px !important' }}>
                        Why Beep?
                    </Typography>

                    <Typography variant="h6" sx={{ ...style }}>
                        We are all drivers and know that the benefits of car-ownership, such as the freedom it brings, are often overshadowed by the frustration, hassle and cost of actually managing a car. These pains vary in frequency from daily (parking), weekly (fuel), semi-annual (tyre swaps), annual (insurance, MOT) to events that occur once every few years such as selling a car and buying a replacement.
                    </Typography>
                    <Typography variant="h6" sx={{ ...style }}>
                        There are countless apps which seek to solve one or perhaps two car-related pains but owing to the nature of the service and the intermittent timing of their use, they are not particularly engaging and tend to fall into disuse.
                    </Typography>
                    <Typography variant="h6" sx={{ ...style, mb: 4 }}>
                        By creating an engaging app with additional car services, single service businesses can transform their relationship with their customers and be front of mind for the sale of their own services while earning revenues on third party services.
                    </Typography>

                    <Typography variant="h4" sx={{ fontSize: '32px !important', textAlign: 'left', color: 'grey.700', }}>
                        Meet our Team
                    </Typography>
                    <Typography variant="h6" sx={{ ...style }}>
                        Our team brings a wealth of knowledge and experience from a diverse range of global companies within key sectors such as motor insurance, car repairs, roadside assistance, banking and digital payment solutions. Together we have built a market-leading disrupter platform which transforms the way in which car services businesses engage with their customers
                    </Typography>
                </Box>
            </Grid>
        </Box>
    );
};


export default AboutUsBlock;
