import React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import CardHelp from '../uknown/CardHelp';
import Dealerships from './img/1_Dealerships';
import Autoclubs from './img/2_Autoclubs';
import Carservice from './img/3_Carservice';
import FuelRetailers from './img/4_FuelRetailers';
import Others from './img/5_Others';
import CarFinance from './img/7_CarFinance';
import Assistance from './img/8_Assistance';
import Insurers from './img/6_Insurers';
// import ExclamationMarkIcon from '../../icons/ExclamationMarkIcon';
import IconWithText from '../uknown/IconWithText';
// import HeaderHelpIcon from './img/HeaderHelpIcon';
import Banks from './img/6_Banks';
// import IconButtonWithText from '../uknown/Button';


const WhoWeHelpBlock: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    // const localBreakpoints = {
    //     sm: 600,
    //     md: 900,
    // };

    // const isLocalTablet = useMediaQuery(`(max-width:${localBreakpoints.md}px)`);

    const options = [
        {
            title: 'Car Dealerships',
            // descrWith: { xs: '400px', sm: '600px' },
            description: 'Beep! enables dealerships to reach a broader audience and streamline their sales processes',
            icon: <Dealerships />,
            size: 4,
            positionStyle: { left: 0 }
        },
        {
            title: 'Car Services Networks',
            // descrWith: { xs: '400px', sm: '600px' },
            description: 'The app allows car service providers to publish their services, arrange bookings, create special offers and attract new clients, thereby expanding their customer base and increasing engagement',
            icon: <Carservice />,
            size: 4,
            positionStyle: isMobile ? { left: 0 } : { right: 0 }
        },
        {
            title: 'Fuel Retailers',
            // descrWith: { xs: '400px', sm: '600px' },
            description: 'Through Beep!, fuel retailers can offer customers the convenience of locating nearby stations, accessing up-to-date fuel prices, receive discounts and make payments directly within the app. Fuel retailers have access to a large base of regular customers but often fail to cross-sell other services such as insurance, car sales and car finance. With Beep! they can offer those services and increase their revenues',
            icon: <FuelRetailers />,
            size: 4,
            positionStyle: isMobile ? { right: 0 } : { left: 0 }
        },
        {
            title: 'Car Finance Providers',
            description: 'By offering the Beep! app (with its valuation module), finance providers can identify when drivers are considering replacing their car and proactively offer them the ability to explore, compare, and secure car loans directly within the platform. In addition, they get the opportunity to differentiate their offer by accessing the platform’s analytics to offer special credit programmes (car loans, instalment payments, credit cards)',
            icon: <CarFinance />,
            size: 4,
            positionStyle: { left: 0 }
        },

        {
            title: 'Roadside Assistance Providers',
            description: 'Beep!’s integrated roadside assistance module connects users with roadside assistance services either through their own API or Beep’s bespoke platform',
            icon: <Assistance />,
            size: 4,
            positionStyle: { right: 0 }
        },
        {
            title: 'Auto Clubs',
            // descrWith: { xs: '400px', sm: '500px' },
            description: 'Beep! offers auto clubs the opportunity to provide their members with a centralized platform for various car-related services, enhancing member engagement and satisfaction',
            icon: <Autoclubs />,
            size: 4,
            // positionStyle: isMobile ? { right: 0 } : { left: '50%', transform: 'translateX(-50%)' }
        },
        {
            title: 'Car Insurers',
            descrWith: { xs: '400px', sm: '500px' },
            description: 'Insurance has to be actively sold each year and retention is notoriously challenging. By providing an engaging daily-relevant driver app, insurers are well-placed to encourage their customers to renew with them rather than look to another insurance company. The in-app telematics module enables insurers to gather comprehensive data, supporting more precise underwriting. Additionally, insurers can easily leverage advanced technologies to introduce telematics-based products, AI-powered online CASCO products, and AI-driven claims management solutions',
            icon: <Insurers />,
            size: 4,
            // positionStyle: isMobile ? { left: 0 } : { left: '50%', transform: 'translateX(-50%)' }
            positionStyle: { left: 0 }
        },
        {
            title: 'Banks',
            // descrWith: { xs: '400px', sm: '500px' },
            description: 'AWith Beep!, a bank can offer banking products related to the car (such as car loans) and a digital card for car-related services through which they can receive discounts on car-related payments',
            icon: <Banks />,
            size: 4,
            // positionStyle: isMobile ? { left: 0 } : { left: '50%', transform: 'translateX(-50%)' }
        },
        {
            title: 'Other car-related businesses',
            // descrWith: { xs: '400px', sm: '500px' },
            description: 'Any business with a large car-owning customer base',
            icon: <Others />,
            size: 4,
            // positionStyle: { right: 0 }
            // positionStyle: isMobile ? { left: 0 } : { left: '50%', transform: 'translateX(-50%)' }
        },
    ];

    return (
        <Grid
            container
            // spacing={isMobile ? 6 : 22}
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                mt: 15,
                mb: 30
            }}
        >
            <Grid
                item
                xs={12}
                md={5}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pl: 12
                }}
            >
                <IconWithText
                    text="Who we help"
                    variant="h1"
                    color='secondary.main'
                    iconSize="large"
                    textStyle={{
                        letterSpacing: -0.5,
                        fontWeight: 700,
                        textAlign: 'left',
                        color: theme.palette.secondary.main,
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'start', width: '78%', mt: 6 }}>
                    <Typography variant="h6" sx={{ textAlign: 'left' }}>
                        We help our white-label partners enhance their customer engagement with an all-in-one car ecosystem app, unlocking new revenue streams and a competitive edge
                    </Typography>
                </Box>
            </Grid>

            <Grid
                container
                spacing={isTablet ? 2 : 4}
                item
                xs={12}
                md={7}
            >
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    xs={12}
                    md={10}
                >
                    <Grid
                        container
                        spacing={4}
                    >
                        {options.map((option, index) => (
                            <Grid
                                key={index}
                                item
                                xs={6}
                                sm={option.size}
                                md={option.size}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <CardHelp key={index} {...option} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};


export default WhoWeHelpBlock;
