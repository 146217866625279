import React, { useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


import img1 from "./img/team/1.P.Lovas.png";
import img2 from "./img/team/2.J.Richards.png";
import img3 from "./img/team/3.Y.Glotov.png";
import img4 from "./img/team/4.A.Arshinskyi.png";
import img5 from "./img/team/5.Y.Solomakha.png";
import img6 from "./img/team/6.O.Primorozenko.png";
import img7 from "./img/team/7.A.Tupchienko.png";
import img8 from "./img/team/8.A.Kostenko.png";
import img9 from "./img/team/9.D.Okumus.png";


const sliders = [
  {
    name: "Peter Lovas",
    position: "Executive Chairman",
    img: {
      src: img1,
      alt: "Peter Lovas",
    },
  },
  {
    name: "John Richards",
    position: "COO",
    img: {
      src: img2,
      alt: "John Richards",
    },
  },
  {
    name: "Yevhen Glotov",
    position: "CPO",
    img: {
      src: img3,
      alt: "Yevhen Glotov",
    },
  },
  {
    name: "Aleksandr Arshinskyi",
    position: "CTO",
    img: {
      src: img4,
      alt: "Aleksandr Arshinskyi",
    },
  },

  {
    name: "Yurii Solomakha",
    position: "Tech Lead ",
    img: {
      src: img5,
      alt: "Yurii Solomakha",
    },
  },
  {
    name: "Olga Primorozenko",
    position: "Payment services, Bonus program, Wallet & Virtual card Product Manager",
    img: {
      src: img6,
      alt: "Olga Primorozenko",
    },
  },
  {
    name: "Andrii Tupchiienko",
    position: "Insurance & Telematics, Roadside assistance Product Manager",
    img: {
      src: img7,
      alt: "Andrii Tupchiienko",
    },
  },
  {
    name: "Anton Kostenko",
    position: "Car sales, Parking, Car services Product Manager",
    img: {
      src: img8,
      alt: "Car sales, Parking, Car services Product Manager",
    },
  },
  {
    name: "Derya Okumus",
    position: "Turkey Country Manager",
    img: {
      src: img9,
      alt: "Derya Okumus",
    },
  },
];

const CustomPrevArrow = ({ onClick }: { onClick?: () => void }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "-100px",
      left: "50%",
      transform: "translateX(-520%)",
      zIndex: 10,
      width: "48px",
      height: "48px",
      backgroundColor: "#33353E",
      color: "#fff",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: "#222",
      },
    }}
  >
    <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
  </IconButton>
);

const CustomNextArrow = ({ onClick }: { onClick?: () => void }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "-100px",
      left: "50%",
      transform: "translateX(-400%)",
      zIndex: 10,
      width: "48px",
      height: "48px",
      backgroundColor: "#33353E",
      color: "#fff",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: "#222",
      },
    }}
  >
    <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
  </IconButton>
);


const SliderTeam = () => {

  const sliderRef = useRef<Slider | null>(null);

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    infinite: true,
    autoplay: true,
    // speed: 6000,
    speed: 1500,
    // autoplaySpeed: 0,
    // autoplaySpeed: 4000,
    slidesToShow: 5,
    cssEase: "linear",
    slidesToScroll: 1,
    // centerPadding: "120px",
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "80px",
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: { xs: "65%", sm: "55%", md: "50%" },
          transform: "translateX(-50%)",
          display: "flex",
          gap: "16px",
          zIndex: 20,
        }}
      >
        <CustomPrevArrow onClick={handlePrev} />
        <CustomNextArrow onClick={handleNext} />
      </Box>
      <Slider ref={sliderRef} {...settings}>
        {sliders.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "0 8px",
            }}
          >
            <Box
              component="img"
              src={item.img.src}
              alt={item.name}
              sx={{
                width: "180px",
                marginBottom: "16px",
                objectFit: "cover",
              }}
            />

            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                color: "#33353E",
                lineHeight: "24px",
                textAlign: "left"
              }}
            >
              {item.name}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#697384",
                textAlign: "left",
                lineHeight: "20px",
                marginTop: "8px",
                width: '68%'
              }}
            >
              {item.position}
            </Typography>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default SliderTeam;

