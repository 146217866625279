import Assistance from './1_assistance.png';
import Auction from './5_auction.png';
import bonuses from './6_bonuses.png';
import carservice from './4_carservice.png';
import carvaluation from './10_carvaluation.png';
import Fines from './8_fines.png';
import Fuel from './11_fuel.png';
import Garage from './3_garage.png';
import Insurance from './2_insurance.png';
import Logbook from './12_logbook.png';
import Parking from './9_parking.png';
import Promotions from './7_promotions.png';
import Telematics from './13_telematics.png';

export const client_img = {
    Assistance,
    Auction,
    bonuses,
    carservice,
    carvaluation,
    Fines,
    Fuel,
    Garage,
    Insurance,
    Logbook,
    Parking,
    Promotions,
    Telematics
};
