import * as React from "react"

function ExclamationMarkIcon() {
    return (
        <svg width="13" height="49" viewBox="0 0 13 49" fill="none">
            <path d="M6.30144 35.8789C9.85079 35.8789 12.6642 38.6386 12.6642 42.2621C12.6642 45.8168 9.85079 48.5 6.30144 48.5C2.6831 48.5 0 45.8168 0 42.2621C0 38.6386 2.6831 35.8789 6.30144 35.8789ZM9.0612 31.6361H3.60302C3.08174 31.6361 2.65243 31.2386 2.60643 30.7188L0.344979 1.57023C0.298983 0.99689 0.758933 0.5 1.34155 0.5H11.315C11.8976 0.5 12.3499 0.989245 12.3116 1.57023L10.0501 30.7188C10.0118 31.2386 9.58249 31.6361 9.0612 31.6361Z" fill="#F7B500" />
        </svg>

        // <svg xmlns="http://www.w3.org/2000/svg" width="7" height="26" viewBox="0 0 7 26" fill="none">
        //     <path d="M3.33226 19.0173C5.2092 19.0173 6.69695 20.4766 6.69695 22.3928C6.69695 24.2725 5.2092 25.6915 3.33226 25.6915C1.41885 25.6915 0 24.2725 0 22.3928C0 20.4766 1.41885 19.0173 3.33226 19.0173ZM4.79165 16.7737H1.90531C1.62965 16.7737 1.40263 16.5635 1.37831 16.2886L0.182428 0.874541C0.158105 0.571354 0.401331 0.308594 0.709423 0.308594H5.98348C6.29158 0.308594 6.53075 0.567311 6.51048 0.874541L5.3146 16.2886C5.29433 16.5635 5.06731 16.7737 4.79165 16.7737Z" fill="#F7B500" />
        // </svg>
    )
}

export default ExclamationMarkIcon
