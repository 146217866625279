import * as React from "react"

function FuelRetailers() {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x={0.5} width={47} height={48} rx={10} fill="#24262C" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 33v-9.63a1 1 0 00-.24-.65l-5.46-6.37a1 1 0 00-.76-.35H17a1 1 0 00-1 1v16a1 1 0 001 1h14a1 1 0 001-1zm-11.707-9.707a1 1 0 011.414 0L24 25.586l2.293-2.293a1 1 0 011.414 1.414L25.414 27l2.293 2.293a1 1 0 01-1.414 1.414L24 28.414l-2.293 2.293a1 1 0 01-1.414-1.414L22.586 27l-2.293-2.293a1 1 0 010-1.414z"
                fill="#F7B500"
            />
            <path
                d="M29.765 15.643a1 1 0 011.409.123l1.928 2.298a1 1 0 01-.123 1.409l-.766.643L29 16.286l.766-.643z"
                fill="#F7B500"
            />
            <rect x={18} y={14} width={6} height={3} rx={1} fill="#F7B500" />
        </svg>
    )
}

export default FuelRetailers
