import * as React from "react"

function Autoclubs() {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x={0.5} width={47} height={48} rx={10} fill="#24262C" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 15c-1.657 0-3 1.367-3 3.053 0 1.303.48 4.319 5.053 7.438a2.016 2.016 0 01.92-2.183l.007-.005.023-.013.02-.011.007-.004a2.006 2.006 0 011.738-.102 2 2 0 01.956.814v-4.724c0-.555.228-1.058.588-1.418a2.005 2.005 0 011.736-.564v-.273c0-.34.085-.659.233-.938A2.972 2.972 0 0023 15c-1.657 0-3 1.85-3 1.85S18.657 15 17 15zm8.739 4.762v-.533.533zm-.691-1.455l.029.01-.03-.01zm-3.23 6.123a.972.972 0 01.06.092l-.005-.008a1.004 1.004 0 00-.055-.083v-.001z"
                fill="#F7B500"
            />
            <path
                d="M30.387 20.4v3.097a.155.155 0 00.31 0v-3.13c.009-.264.12-.503.295-.678a1.005 1.005 0 011.424 0c.182.183.296.435.296.712v9.602c0 .824-.338 1.573-.88 2.117a2.988 2.988 0 01-2.117.88H26.72c-.775 0-1.484-.273-2.1-.703-.736-.513-1.34-1.247-1.768-2.009L21.847 28.5l-.005-.008-.134.077.134-.077-1.702-2.949a1.006 1.006 0 01-.105-.774 1.005 1.005 0 011.849-.237l.13-.084-.13.084 1.549 2.392a.154.154 0 00.29-.073v-7.588c0-.277.114-.528.297-.711a1.005 1.005 0 011.423 0c.175.175.287.413.296.677v4.268a.154.154 0 10.309 0v-6.49c0-.276.113-.528.296-.71a1.005 1.005 0 011.72.71v6.49a.154.154 0 10.308 0v-4.69c0-.276.114-.528.296-.71a1.004 1.004 0 011.423 0c.183.182.296.434.296.71V20.4z"
                fill="#F7B500"
            />
        </svg>
    )
}

export default Autoclubs
