import * as React from "react"

function EngageIcon() {
    return (
        <svg
            width={23}
            height={20}
            viewBox="0 0 23 20"
            fill="none"
        >
            <path
                d="M3.994 12.525l4.964-4.964 1.185 1.187c.56.562 1.303.872 2.095.872.792 0 1.536-.31 2.096-.872a2.979 2.979 0 000-4.193l-1.86-1.864.924-.812a5.593 5.593 0 017.372 8.413l-1.327 1.226-1.473-1.473a2.2 2.2 0 00-3.108 0c-.409.409-.636.95-.643 1.527a2.19 2.19 0 00-2.168 2.169 2.19 2.19 0 00-1.527.642 2.2 2.2 0 000 3.109l1.18 1.18-.998.922a1.639 1.639 0 01-2.16-2.465 1.639 1.639 0 11-2.16-2.465 1.639 1.639 0 01-2.313-.153c-.512-.583-.482-1.32-.08-1.986z"
                fill="#222221"
            />
            <path
                d="M1.638 1.638a5.593 5.593 0 017.91 0l3.844 3.854c.46.462.6 1.172.358 1.775a1.645 1.645 0 01-1.647 1.018 1.637 1.637 0 01-1.028-.485L8.958 5.681l-5.593 5.594-1.727-1.727a5.593 5.593 0 010-7.91zM17.697 14.11l-1.895-1.896a.864.864 0 01-.255-.615.867.867 0 011.484-.615l1.895 1.896a.87.87 0 01.004 1.225l-.017.016a.863.863 0 01-.602.243.864.864 0 01-.614-.255zM15.527 16.278l-1.895-1.895a.864.864 0 01-.254-.615c0-.232.09-.45.254-.614a.864.864 0 01.615-.255c.232 0 .45.09.615.255l1.895 1.895a.864.864 0 01.255.614c0 .231-.09.448-.253.612l-.01.01a.87.87 0 01-1.222-.007zM13.358 18.447l-1.895-1.895a.87.87 0 011.23-1.23l1.895 1.896a.864.864 0 01.254.614c0 .232-.09.45-.253.614l-.005.005a.87.87 0 01-1.226-.004z"
                fill="#222221"
            />
        </svg>
    )
}

export default EngageIcon
