import * as React from "react"

function Carservice() {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={40} height={40} rx={10} fill="#fff" fillOpacity={0.05} />
            <g clipPath="url(#clip0_1688_3609)">
                <path
                    d="M19.99 10C14.47 10 10 14.48 10 20s4.47 10 9.99 10C25.52 30 30 25.52 30 20s-4.48-10-10.01-10zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0126.92 16zM20 12.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM12.26 22c-.16-.64-.26-1.31-.26-2s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2h-3.38zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 0113.08 24zm2.95-8h-2.95a7.987 7.987 0 014.33-3.56A15.65 15.65 0 0016.03 16zM20 27.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM22.34 22h-4.68c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM24.36 22c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
                    fill="#DEE1E9"
                />
            </g>
            <defs>
                <clipPath id="clip0_1688_3609">
                    <path fill="#fff" transform="translate(10 10)" d="M0 0H20V20H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Carservice
