import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonGetDemo = styled(Button)<ButtonProps>(({ theme }) => ({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    color: theme.palette.grey[700],
    textTransform: 'none',
    backgroundColor: theme.palette.warning.main,
    borderRadius: '12px',
    boxShadow: 'none',
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2),
    },

}));

export default ButtonGetDemo;
