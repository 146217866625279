export const COLORS = {
    MAIN: '#33353E',
    MAIN_LIGHT: '#3A3E49',
    MAIN_LIGHT1: '#4C4F5D',
    MAIN_DARK: '#1F2029',
    WARNING: '#F7B500',
    WARNING_LIGHT: '#FFD700',
    WARNING_DARK: '#FFD700',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    GREY: '#F0F0F0',
    GREY_LIGHT: '#EAECF2',
} as const;

export type Colors = keyof typeof COLORS;