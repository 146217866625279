import * as React from "react"

function Banks() {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={40} height={40} rx={10} fill="#fff" fillOpacity={0.05} />
            <path
                d="M12 29h16.2M14.7 25.4v-6.3M18.3 25.4v-6.3M21.898 25.4v-6.3M25.5 25.4v-6.3M20.099 11l7.2 4.5h-14.4l7.2-4.5z"
                stroke="#DEE1E9"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default Banks
