import * as React from "react"

function AppInactive() {
    return (
        <svg
            width={124}
            height={124}
            viewBox="0 0 124 124"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1515_25179)">
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint0_radial_1515_25179)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint1_radial_1515_25179)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint2_radial_1515_25179)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint3_radial_1515_25179)"
                />
                <path
                    d="M38 30.38A8.38 8.38 0 0146.38 22h31.24A8.38 8.38 0 0186 30.38v62.9a8.38 8.38 0 01-8.38 8.38H46.38A8.38 8.38 0 0138 93.28v-62.9z"
                    fill="#3A3B47"
                />
                <path
                    d="M39.203 42.024a6 6 0 016-6h33.615a6 6 0 016 6v51.862a6 6 0 01-6 6H45.203a6 6 0 01-6-6V42.024z"
                    fill="#23242B"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M77.62 24.095H46.38a6.285 6.285 0 00-6.285 6.285v62.9a6.285 6.285 0 006.285 6.285h31.24a6.285 6.285 0 006.285-6.285v-62.9a6.285 6.285 0 00-6.285-6.285zM46.38 22A8.38 8.38 0 0038 30.38v62.9a8.38 8.38 0 008.38 8.38h31.24A8.38 8.38 0 0086 93.28v-62.9A8.38 8.38 0 0077.62 22H46.38z"
                    fill="#0B0B0D"
                />
                <rect
                    x={48.3262}
                    y={78.5986}
                    width={27.3692}
                    height={3.04102}
                    rx={1.52051}
                    fill="#424653"
                />
                <rect
                    x={54.4082}
                    y={84.6807}
                    width={15.2051}
                    height={3.04102}
                    rx={1.52051}
                    fill="#424653"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M57.404 97.513a.35.35 0 01.35-.349h8.493a.35.35 0 110 .699h-8.493a.35.35 0 01-.35-.35z"
                    fill="#0B0B0D"
                />
                <path
                    d="M52.299 22h19.404v1.106a4 4 0 01-4 4H56.3a4 4 0 01-4-4V22z"
                    fill="#0B0B0D"
                />
                <ellipse
                    cx={63.0199}
                    cy={57.921}
                    rx={12.766}
                    ry={12.766}
                    fill="#A9ACC6"
                />
                <path
                    d="M74.764 56.9c0 7.05-5.715 12.766-12.766 12.766-7.05 0-12.766-5.716-12.766-12.766s5.716-12.766 12.766-12.766S74.764 49.849 74.764 56.9z"
                    fill="#76788A"
                />
                <path
                    d="M54.483 55.842c-.42 0-.808.133-1.124.36v-1.699a.12.12 0 00-.12-.12H52.5a.12.12 0 00-.12.12v5.005c0 .067.053.12.12.12h.738a.12.12 0 00.12-.12v-.175a1.93 1.93 0 003.056-1.566 1.929 1.929 0 00-1.932-1.925zm0 2.984a1.06 1.06 0 01-1.061-1.058 1.06 1.06 0 011.061-1.058 1.06 1.06 0 011.061 1.058 1.06 1.06 0 01-1.061 1.058zM60.496 58.893a.12.12 0 01.002.172c-.52.507-1.177.643-1.652.643-1.382 0-2.139-.863-2.139-1.922 0-1.095.858-1.958 2.059-1.958 1.004 0 1.966.67 2.02 2.066a.12.12 0 01-.121.125h-2.881c.022.464.451.805 1.142.805.348 0 .695-.16.953-.372a.12.12 0 01.16.005l.457.436zm-2.69-1.527h1.869c-.073-.48-.56-.66-.924-.66-.349 0-.815.16-.945.66zM70.95 58.337c.385 0 .69.3.69.694a.674.674 0 01-.69.677.664.664 0 01-.684-.677c0-.394.29-.694.684-.694zm.3-.46h-.593a.109.109 0 01-.108-.1l-.246-3.164a.108.108 0 01.108-.116h1.083c.063 0 .112.053.108.116l-.245 3.164a.107.107 0 01-.108.1zM69.545 57.77a1.93 1.93 0 00-3.055-1.566v-.175a.12.12 0 00-.12-.12h-.738a.12.12 0 00-.12.12v5.139c0 .066.053.12.12.12h.738a.12.12 0 00.12-.12v-1.834c.316.227.704.36 1.124.36a1.926 1.926 0 001.931-1.924zm-.87 0a1.06 1.06 0 01-1.061 1.058 1.06 1.06 0 01-1.061-1.058 1.06 1.06 0 011.06-1.058 1.06 1.06 0 011.062 1.058zM64.853 58.893a.12.12 0 01.002.172c-.52.507-1.177.643-1.652.643-1.382 0-2.139-.863-2.139-1.922 0-1.095.858-1.958 2.06-1.958 1.003 0 1.965.67 2.02 2.066a.12.12 0 01-.122.125h-2.88c.021.464.45.805 1.141.805.348 0 .696-.16.953-.372a.12.12 0 01.16.005l.457.436zm-2.69-1.527h1.868c-.072-.48-.56-.66-.924-.66-.348 0-.813.16-.945.66z"
                    fill="#0B0B0D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M61.998 68.27c6.28 0 11.37-5.091 11.37-11.37 0-6.28-5.09-11.37-11.37-11.37-6.279 0-11.369 5.09-11.369 11.37 0 6.279 5.09 11.37 11.37 11.37zm0 1.396c7.05 0 12.766-5.716 12.766-12.766S69.05 44.134 61.998 44.134c-7.05 0-12.766 5.715-12.766 12.766 0 7.05 5.716 12.766 12.766 12.766z"
                    fill="#0B0B0D"
                />
            </g>
            <defs>
                <radialGradient
                    id="paint0_radial_1515_25179"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop />
                    <stop offset={0.35} stopOpacity={0} />
                    <stop offset={1} />
                </radialGradient>
                <radialGradient
                    id="paint1_radial_1515_25179"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop />
                    <stop offset={0.35} stopOpacity={0} />
                    <stop offset={1} />
                </radialGradient>
                <radialGradient
                    id="paint2_radial_1515_25179"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop />
                    <stop offset={0.35} stopOpacity={0} />
                    <stop offset={1} />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_1515_25179"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop />
                    <stop offset={0.35} stopOpacity={0} />
                    <stop offset={1} />
                </radialGradient>
                <clipPath id="clip0_1515_25179">
                    <rect width={124} height={124} rx={62} fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AppInactive
