import * as React from "react"

function Dealerships() {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={40} height={40} rx={10} fill="#fff" fillOpacity={0.05} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.267 19.267a2.499 2.499 0 110-3.535 2.499 2.499 0 010 3.535zm9.201-.685l-6.417-6.417a.995.995 0 00-.614-.288l-9.344-.872a1.002 1.002 0 00-1.089 1.088l.872 9.344c.023.232.124.45.29.614l6.416 6.418a1.817 1.817 0 002.57-.001l7.316-7.316c.343-.342.532-.799.532-1.285 0-.486-.189-.942-.532-1.285z"
                fill="#DEE1E9"
            />
        </svg>
    )
}

export default Dealerships
