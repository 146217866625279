import * as React from "react"

// interface IProps {
//     color?: string
// }

function ArrowLeftIcon(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <path
                d="M10.258 12l4.523 4.381a.993.993 0 01-.156 1.4 1.003 1.003 0 01-1.405-.154l-5.024-5.004a.993.993 0 010-1.246l5.024-5.004c.345-.429.974-.498 1.405-.154.431.344.5.97.155 1.4L10.258 12z"
                fill="#F2F3F7"
            />
        </svg>
    )
}

export default ArrowLeftIcon
