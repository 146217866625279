import * as React from "react"

function RetainIcon() {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
        >
            <mask
                id="a"
                style={{
                    maskType: "luminance"
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={22}
                height={22}
            >
                <path d="M0 0h22v22H0V0z" fill="#fff" />
            </mask>
            <g mask="url(#a)" fill="#222221">
                <path d="M11 4.813c.27 0 .514.143.625.368l.89 1.783 2.019.262a.676.676 0 01.555.424.594.594 0 01-.165.645L13.43 9.67l.372 1.967a.608.608 0 01-.282.623.74.74 0 01-.735.033L11 11.365l-1.785.928a.74.74 0 01-.735-.033.608.608 0 01-.282-.623l.373-1.967-1.495-1.375a.594.594 0 01-.165-.645.676.676 0 01.555-.424l2.02-.262.889-1.783A.694.694 0 0111 4.812z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.063 8.938a8.938 8.938 0 1113.75 7.532v4.843a.688.688 0 01-.995.614L11 20.02l-3.818 1.908a.688.688 0 01-.995-.614V16.47a8.93 8.93 0 01-4.125-7.532zm15.124 0a6.187 6.187 0 11-12.374 0 6.187 6.187 0 0112.375 0z"
                />
            </g>
        </svg>
    )
}

export default RetainIcon
