import React from 'react'
import { Box, Typography, Avatar } from "@mui/material";
import BeepOrantaImg from '../../images/iPhone 14 Pro.png';
import JacekMejzner from '../../images/JacekMejzner.png';
import QuotationMarkIcon from '../../icons/QuotationMarkIcon';

const BeepGreat = () => {
    // const theme = useTheme();


    // const styles = {
    //     color: theme.palette.grey[500],
    //     fontWeight: 700,
    // }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: { xs: 'column-reverse', md: 'row' },
            width: "100%",
            px: 20,
            // maxHeight: '600px',
            // height: '600px',
            // gap: 10
        }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 20
                }}
            >
                <Box
                    component="img"
                    src={BeepOrantaImg}
                    alt="iPhone mockup"
                    sx={{
                        width: { xs: '80%', md: '100%' },
                        maxWidth: '380px',
                        height: 'auto',
                        objectFit: 'contain',
                    }}
                />
            </Box>

            <Box
                sx={{
                    width: '100%',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: { xs: "center", md: "flex-start" },
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    maxWidth: "600px",
                    position: 'relative',
                    mt: 6,
                    gap: 1
                }}>
                    <Box sx={{
                        position: 'absolute',
                        left: -22,
                        top: -2,
                    }}>
                        <QuotationMarkIcon />
                    </Box>
                    <Typography
                        variant='body1'
                        sx={{
                            color: 'grey.700',
                            fontWeight: 700,
                            textAlign: "justify",
                            position: 'relative',
                            display: "inline-block",
                        }}
                    >
                        Beep! is a great solution for launching an app-based car ecosystem proposition in a fast and cost effective fashion. When we were looking for a solution to digitalise our services and improve customer engagement, Beep! stood out as the ideal partner. Their team's professional expertise has been key to the successful delivery of our project. We have also been impressed with their ongoing support and commitment to the platform’s continued development.
                        <span
                            style={{
                                transform: 'rotate(180deg)',
                                position: "absolute",
                                bottom: -2,
                            }}
                        >
                            <QuotationMarkIcon />
                        </span>
                    </Typography>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: 8
                }}>
                    <Avatar
                        src={JacekMejzner}
                        sx={{
                            width: 50,
                            height: 50,
                            borderRadius: 25,
                            mr: 2
                        }}
                    />
                    <Box>
                        <Typography
                            variant='h4'
                            sx={{
                                textAlign: 'left',
                                color: 'grey.700',
                            }}
                        >
                            Jacek Mejzner
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                textAlign: 'left',
                                color: 'grey.700',
                                fontWeight: 700,
                            }}
                        >
                            Chairman of the Management Board, Oranta
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BeepGreat