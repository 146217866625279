import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import CheckIcon from '../img/CheckIcon';
import InfoIcon from '../../../icons/InfoIcon';

interface Option {
    title: string;
    description: string;
}

interface ListProps {
    options: Option[];
}

const HoverList: React.FC<ListProps> = ({ options }) => {
    const theme = useTheme();
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    return (
        <List
            sx={{
                // flexGrow: 1,
                // height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
            }}
        >
            {options.map((option, index) => (
                <ListItem
                    key={index}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'relative',
                        // cursor: 'pointer',
                        flexGrow: 1,
                        minWidth: '100%',
                        paddingLeft: '0 !important',
                        paddingRight: '0 !important',
                    }}
                >
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>

                    <ListItemText sx={{}} primary={option.title} />

                    <Box
                        sx={{
                            position: 'relative', display: 'flex', alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <InfoIcon color={hoveredIndex === index ? theme.palette.common.white : theme.palette.grey[400]} />
                    </Box>
                    {/* {hoveredIndex === index && (
                        <Box
                            sx={{
                                position: 'absolute',
                                // top: '100%',
                                bottom: '100%',
                                left: '60%',
                                // transform: 'translateX(-50%)',
                                minWidth: '290px',
                                maxWidth: '390px',
                                backgroundColor: theme.palette.grey[100],
                                borderRadius: '8px',
                                padding: '8px',
                                border: '1px solid',
                                borderColor: theme.palette.grey[600],
                                opacity: 1,
                                transition: 'opacity 0.3s ease-in-out',
                                boxShadow: 3,
                                zIndex: 10,
                            }}
                        >
                            <Typography variant="body2" color="grey.500" sx={{ fontSize: '12px', fontWeight: 700 }}>
                                {option.description}
                            </Typography>
                        </Box>
                    )} */}

                    {hoveredIndex === index && (
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '100%',
                                left: '58%',
                                minWidth: '290px',
                                maxWidth: '390px',
                                backgroundColor: theme.palette.grey[100],
                                borderRadius: '8px',
                                padding: '8px',
                                border: '1px solid',
                                borderColor: theme.palette.grey[600],
                                opacity: 1,
                                transition: 'opacity 0.3s ease-in-out',
                                boxShadow: 3,
                                zIndex: 10,
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: '100%',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: 0,
                                    height: 0,
                                    borderLeft: '4px solid transparent',
                                    borderRight: '4px solid transparent',
                                    borderTop: `4px solid ${theme.palette.grey[600]}`,
                                },
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 'calc(100% - 1px)',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: 0,
                                    height: 0,
                                    borderLeft: '4px solid transparent',
                                    borderRight: '4px solid transparent',
                                    borderTop: `4px solid ${theme.palette.grey[100]}`,
                                    zIndex: 11,
                                },
                            }}
                        >
                            <Typography variant="body2" color="grey.500" sx={{ fontSize: '12px', fontWeight: 700 }}>
                                {option.description}
                            </Typography>
                        </Box>
                    )}


                </ListItem>
            ))}
        </List>
    );
};

export default HoverList;
