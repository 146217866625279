import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Typography, useMediaQuery } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';


const data = [
    {
        value: '7,000',
        euro: '€',
        firstUnit: '>',
        description: 'average annual cost',
        additionalInfo: 'of owning, financing and using a car in the EU',
    },
    {
        value: '250',
        firstUnit: '>',
        unit: 'million',
        description: 'passenger cars',
        additionalInfo: 'in the EU',
    },
    {
        value: '1.8',
        euro: '€',
        unit: 'trillion',
        description: 'car ecosystem',
        additionalInfo: 'in the EU',
    },
    {
        value: '>90',
        unit: ' %',
        description: 'smartphone penetration',
        additionalInfo: 'among car drivers in the EU',
    },
];

const HeroStats: React.FC = () => {
    const theme = useTheme();

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                // py: theme.palette.customSpacing.lg,
                [theme.breakpoints.down('sm')]: {
                    py: theme.spacing(8),
                },
            }}
        >
            {
                data.map((item, index) => (
                    <StatItem
                        key={index}
                        {...item}

                    />
                ))
            }
        </Grid >
    );
};

interface StatItemProps {
    value: string;
    firstUnit?: string;
    unit?: string;
    euro?: string;
    description: string;
    additionalInfo: string;
}

const StatItem: React.FC<StatItemProps> = ({ value, unit, euro, description, firstUnit, additionalInfo }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const { ref, inView } = useInView({ triggerOnce: true });

    // const animatedValue = useSpring({
    //     from: { val: 0 },
    //     to: { val: inView ? value : 0 },
    //     config: { duration: 2500 },
    // });

    return (
        <Grid item xs={6} sm={6} md={3}/*  ref={ref} */
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
            }}>
            <Box
                textAlign="start"
                sx={{
                    width: '100%',
                    // display: 'flex',
                    maxWidth: '320px',
                    padding: 2,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>

                    <Typography
                        variant={isMobile ? 'h2' : 'h1'}
                        sx={{ color: theme.palette.grey[700] }}
                    >
                        {firstUnit}
                    </Typography>

                    <Typography
                        variant={isMobile ? 'h4' : 'h3'}
                        component="span"
                        sx={{ color: theme.palette.grey[700], mr: 0.123 }}
                    >
                        {euro}
                    </Typography>
                    <Typography
                        variant={isMobile ? 'h2' : 'h1'}
                        sx={{ color: theme.palette.grey[700] }}
                    >
                        {value}
                    </Typography>
                    <Typography
                        variant={isMobile ? 'h4' : 'h3'}
                        component="span"
                        sx={{ color: theme.palette.grey[700], ml: 1 }}
                    >
                        {unit}
                    </Typography>
                </Box>

                <Typography
                    variant="subtitle1"
                    sx={{
                        color: theme.palette.grey[700],
                        fontWeight: 700,
                    }}
                >
                    {description}
                </Typography>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.grey[700],
                        fontWeight: 600,
                    }}
                >
                    {additionalInfo}
                </Typography>
            </Box>
        </Grid>
    );
};

export default HeroStats;