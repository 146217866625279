import * as React from "react"

function Others() {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x={0.5} width={47} height={48} rx={10} fill="#24262C" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 24c0-5.523 4.477-10 10-10 5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.523 0-10-4.477-10-10zm10-5a1 1 0 100 2 1 1 0 000-2zm0 3a1 1 0 00-1 1v5a1 1 0 002 0v-5a1 1 0 00-1-1z"
                fill="#F7B500"
            />
        </svg>
    )
}

export default Others
