import * as React from "react"

function CheckIcon() {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1501_3791)" fill="#F7B500">
                <circle cx={12} cy={12} r={12} fillOpacity={0.16} />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 12a9 9 0 019-9 9 9 0 019 9 9 9 0 01-9 9 9 9 0 01-9-9zm12.332-1.675a.692.692 0 10-1.126-.804l-2.987 4.181-1.499-1.499a.692.692 0 00-.978.979l2.076 2.076a.69.69 0 001.053-.086l3.461-4.847z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1501_3791">
                    <path fill="#fff" d="M0 0H24V24H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CheckIcon
