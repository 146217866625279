import React from 'react';
import { Box, TextField, InputAdornment, Button, CircularProgress } from '@mui/material';
import { format } from 'date-fns';

import PersonIcon from '../img/PersonIcon';
import CompanyIcon from '../img/CompanyIcon';
import EmailIcon from '../img/EmailIcon';
import CommentIcon from '../img/CommentIcon';
import CalendarIcon from '../img/CalendarIcon';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DatePickerWrapper from '../../uknown/DatePicker';

interface IFormField {
    name: string;
    label: string;
    icon: React.ReactNode;
    type?: string;
    isError?: boolean;
    helperText?: string;
}

interface IProps {
    formValues: Record<string, string | null>;
    errors: Record<string, boolean | string>;
    isLoading: boolean;
    isLocalTablet: boolean;
    handleChange: (field: string, value: string) => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    setErrors: React.Dispatch<React.SetStateAction<Record<string, boolean | string>>>;
}

const FormCard: React.FC<IProps> = ({ formValues, errors, isLoading, isLocalTablet, handleChange, handleSubmit, setErrors }) => {
    const formFields: IFormField[] = [
        { name: 'name', label: 'Name', icon: <PersonIcon /> },
        { name: 'company', label: 'Company', icon: <CompanyIcon /> },
        { name: 'email', label: 'Email', icon: <EmailIcon />, isError: !!errors.email, helperText: typeof errors.email === 'string' ? errors.email : '' },
        { name: 'date', label: 'Preferred call date', icon: <CalendarIcon /> },
        { name: 'comment', label: 'Comment', icon: <CommentIcon /> }
    ];

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box sx={{
                display: 'flex', flexDirection: 'column',
                gap: { xs: 5, md: 8 }
            }}>
                {formFields.map((field) => (
                    field.name === 'date' ? (
                        <DatePickerWrapper>
                            <ReactDatePicker
                                selected={formValues.date ? new Date(formValues.date) : null}
                                onChange={(date) => {
                                    const localDate = date ? format(date, 'yyyy-MM-dd') : '';
                                    handleChange('date', localDate);
                                }}
                                dateFormat="yyyy-MM-dd"
                                minDate={new Date()}
                                showPopperArrow={false}
                                withPortal
                                customInput={
                                    <TextField
                                        label="Preferred call date"
                                        variant="outlined"
                                        size={isLocalTablet ? 'small' : 'medium'}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CalendarIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </DatePickerWrapper>

                    ) : (
                        <TextField
                            key={field.name}
                            label={field.label}
                            variant="outlined"
                            size={isLocalTablet ? 'small' : 'medium'}
                            fullWidth
                            autoComplete="off"
                            value={formValues[field.name]}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                            onFocus={() => field.name === 'email' && setErrors((prev) => ({ ...prev, email: false }))}
                            onBlur={(e) => {
                                if (field.name === 'email') {
                                    setErrors((prev) => ({
                                        ...prev,
                                        email: !e.target.value.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)
                                    }));
                                }
                            }}
                            error={field.isError}
                            helperText={field.helperText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {field.icon}
                                    </InputAdornment>
                                )
                            }}
                        />
                    )
                ))}



                <Button
                    variant="contained"
                    type="submit"
                    size='large'
                    disabled={isLoading || !!errors.email}
                    endIcon={isLoading && <CircularProgress size={22} />}
                    sx={{ borderRadius: '12px', textTransform: 'none', alignSelf: { xs: 'flex-start' } }}
                >
                    Get in touch!
                </Button>
            </Box>
        </form>
    );
};

export default FormCard;
