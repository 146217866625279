import * as React from "react"

function OrantaIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={160}
            height={47}
            viewBox="0 0 160 47"
            fill="none"
        >
            <path
                d="M109.317 23.37v13.733h-.006v8.702L98.76 45.8l-2.585-5.882-4.373-10.233v16.109H85.18V23.37h10.59l6.924 16.06V23.37h6.623zM137.436 23.363l-2.458 5.602h-8.286V45.8h-6.167V28.965h-9.24v-5.602h26.151zM22.579 25.646a10.958 10.958 0 00-7.748-3.226v-.011h-3.88v.01c-3 0-5.738 1.23-7.72 3.216a10.92 10.92 0 00-3.226 7.748H0v2.403h.005c0 3.012 1.235 5.75 3.216 7.725 1.992 1.992 4.73 3.232 7.753 3.232v.011h3.868v-.01c3.001 0 5.74-1.235 7.726-3.216a10.916 10.916 0 003.237-7.748v-2.397a10.93 10.93 0 00-3.226-7.737zm-2.854 10.03a5.308 5.308 0 01-1.569 3.764 5.303 5.303 0 01-3.747 1.558H11.5a5.299 5.299 0 01-3.764-1.569 5.36 5.36 0 01-1.57-3.747h.006v-2.206h-.006a5.384 5.384 0 011.57-3.764 5.37 5.37 0 013.742-1.558h2.913c1.465 0 2.799.609 3.77 1.569a5.307 5.307 0 011.564 3.747v2.206zM150.101 23.363h-9.964L130.441 45.8h6.722l2.562-5.893h10.782l2.563 5.893h6.705l-9.674-22.436zM142.14 34.3l2.249-5.224h1.465l2.228 5.224h-5.942zM51.875 30.507v-.01l-.022-7.14H27.738v22.437h6.135v-5.893h8.614c.044 0 .088 0 .132-.006l-.028.011 2.563 5.894h6.721l-3.473-8.039a8.84 8.84 0 00.708-.647 9.274 9.274 0 002.765-6.607zM41.95 34.3h-8.054V28.97H45.68v1.58c0 1.032-.416 1.97-1.086 2.65A3.737 3.737 0 0141.95 34.3zM73.537 23.363h-9.965L53.877 45.8h6.721l2.563-5.893h10.782l2.562 5.893h6.705l-9.673-22.436zM65.575 34.3l2.25-5.224h1.465l2.227 5.224h-5.942z"
                fill="#F2F3F7"
            />
            <path
                d="M.373 17.8C23.973 9.617 50.858 5.03 79.895 5.03c28.982 0 56.307 4.544 80.104 12.768v-5.223C135.533 4.389 108.186.099 79.895.099 51.55.099 24.647 4.439.373 12.576v5.223z"
                fill="#FF671D"
            />
        </svg>
    )
}

export default OrantaIcon
