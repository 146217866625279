import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import CardItem from '../CardItem';
import { client_img } from '../img/client';
// import ServicePoints from '../img/partner/1_service.png'
import SpecialOffers from '../img/partner/2_offers.png'
import InomingRequests from '../img/partner/3_requests.png'
import AcceptedRequests from '../img/partner/4_requests.png'
import CarSevices from '../img/partner/5_ car_services.png'

import AdminClients from '../img/admin/admin.png'
import AdminClient from '../img/admin/admin2.png'
import CarouselIndicator from '../CarouselIndicator';
import CustomIconButton from '../../uknown/CustomIconButton';

const clientsItems = [
    { title: 'Car Insurance', subtitle: 'Quickly purchase compulsory (CMTPL), voluntary (VMTPL), own damage (CASCO), or green card car insurance with flexible payment options and policy management directly within the app. The app has the option of providing products from either a single underwriter or a a panel of underwriters through an insurance aggregator', imageUrl: client_img.Insurance },
    { title: 'Fuel', subtitle: 'Find nearby gas stations and access up-to-date fuel prices. Benefit from fuel discounts and pay for fuel within the app', imageUrl: client_img.Fuel },
    { title: 'Parking', subtitle: 'Search and navigate to municipal or private car parks within a specified radius. Access information and, where available, pay for parking within the app', imageUrl: client_img.Parking },
    { title: 'Roadside Assistance', subtitle: 'Order individual services or purchase annual packages tailored to different needs. Arrange emergency assistance directly through the app', imageUrl: client_img.Assistance },
    { title: 'Car services', subtitle: 'Find services such as tyre replacement, bodywork repair, or periodic maintenance from partner providers. Make appointments and manage bookings conveniently within the app', imageUrl: client_img.carservice },
    { title: 'Car Auction', subtitle: 'Search for and bid on cars for sale, or list a car for auction', imageUrl: client_img.Auction },
    { title: 'Traffic Fines', subtitle: 'Check for and receive notifications about traffic fines, with the ability to search by document or fine notice number. New fines are updated within two hours of the violation', imageUrl: client_img.Fines },
    { title: 'Car Valuation', subtitle: 'Online estimation of a car`s market value', imageUrl: client_img.carvaluation },
    { title: 'Telematics', subtitle: 'Obtain detailed analysis of current driving style and how to improve safety and efficiency', imageUrl: client_img.Telematics },
    { title: 'Wallet', subtitle: 'Accumulate discounts from in-app purchases and redeem them for future transactions', imageUrl: client_img.bonuses },
    { title: 'Promotions and Gifts', subtitle: 'Access exclusive promotions and gifts within the app', imageUrl: client_img.Promotions },
    { title: 'My Garage', subtitle: 'Enjoy a centralized hub for navigation and functionality', imageUrl: client_img.Garage },
    { title: 'Logbook', subtitle: 'Manually or automatically track a car’s mileage and the total cost of owning the car by making or recording all car-related purchases within the app. Set important reminders or create custom ones', imageUrl: client_img.Logbook },
];

const partnersItems = [
    { title: 'Get Noticed', subtitle: 'Boost your business visibility by listing your services on our platform! Reach thousands of potential customers actively looking for services that you provide', imageUrl: AcceptedRequests },
    { title: 'Make Offers', subtitle: 'Create exclusive offers and send notifications to a targeted audience in order to drive more sales', imageUrl: InomingRequests },
    { title: 'Manage Enquiries', subtitle: 'Get service enquiries from your customers and manage them conveniently through a mobile application or web panel', imageUrl: CarSevices },
    { title: 'Accepted requests', subtitle: 'Accept client requests based on your available time slots and ensure your clients are notified about their upcoming visit to your service.', imageUrl: SpecialOffers },
]

interface CarouselProps {
    activeApp: 'clients' | 'partners' | 'admin';
}

const Carousel: React.FC<CarouselProps> = ({ activeApp }) => {
    const theme = useTheme();
    let sliderRef = React.useRef<Slider | null>(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isLarge = useMediaQuery(theme.breakpoints.down('xl'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    const [activeSlide, setActiveSlide] = useState(0);

    const data = React.useMemo(() => {
        switch (activeApp) {
            case 'clients':
                return clientsItems;
            case 'partners':
                return partnersItems;
            default:
                return [];
        }
    }, [activeApp]);

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: activeApp === 'clients' ? 4 : 4,
        slidesToScroll:/*  activeApp === 'clients' ? 2 : */ 1,
        initialSlide: 0,
        centerMode: true,
        centerPadding:/*  activeApp === 'clients' ? '0px' : */ '80px',
        beforeChange: (oldIndex: number, newIndex: number) => setActiveSlide(newIndex),
        appendDots: (dots: React.ReactNode) => {

            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', px: { xs: 4, sm: 0 } }}>
                    <CustomIconButton type='left' onClick={() => sliderRef.current?.slickPrev()} />
                    <CarouselIndicator
                        currentIndex={activeSlide}
                        totalSlides={data.length}
                        isMobile={isLarge}
                    />
                    <CustomIconButton type='right' onClick={() => sliderRef.current?.slickNext()} />
                </Box>
            )
        },
        responsive: [
            {
                breakpoint: theme.breakpoints.values.xl,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    centerMode: true,
                }
            },
            {
                breakpoint: theme.breakpoints.values.lg,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    centerMode: true,

                }
            },
            {
                breakpoint: theme.breakpoints.values.md,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToScroll: 3

                }
            },
            {
                breakpoint: theme.breakpoints.values.sm,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: "20px",
                    slidesToScroll: 1
                }
            },
        ]
    };

    return (
        activeApp === 'admin' ? (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    margin: '0 auto',
                    width: {
                        xs: `calc(100% - ${theme.spacing(16)})`,
                        md: `calc(100% - ${theme.spacing(86)})`,
                    },
                    my: { xs: 8, md: 20 },
                }}
            >
                {isMobile && <Typography
                    variant='h6'
                    color='grey.200'
                    sx={{
                        textAlign: 'left',
                        zIndex: 10,
                        fontWeight: 600,
                        mb: 8,
                    }}
                >
                    The Beep! Admin Panel is a powerful, user-friendly dashboard that provides white-label partners with comprehensive oversight of all platform interactions. It enables real-time monitoring, detailed analytics, and efficient management of services, ensuring seamless operations and optimal user engagement
                </Typography>}
                <Box
                    sx={{
                        height: 'auto',
                        width: '100%',
                        zIndex: 1,
                    }}
                >
                    <Box
                        component="img"
                        src={isMobile ? AdminClient : AdminClients}
                        alt="Admin image 1"
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                        }}
                    />
                </Box>
            </Box>
        ) : (
            <Box
                className="slider-container"
                sx={{
                    my: { xs: 8, md: 20 },
                    position: 'relative',
                    width: '100%',
                    margin: '0 auto',
                }}
            >
                {!isTablet &&
                    <>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                width: '10%',
                                background: `linear-gradient(90deg, ${theme.palette.background.default} 0%, rgba(51, 53, 62, 0.00) 100%)`,
                                zIndex: 1,
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                width: '10%',
                                background: `linear-gradient(270deg, ${theme.palette.background.default} 0%, rgba(51, 53, 62, 0.00) 100%)`,
                                zIndex: 1,
                            }}
                        />
                    </>
                }
                <Slider {...settings} ref={slider => (sliderRef.current = slider)}>
                    {data.map((item, index) => {
                        const isActive =
                            index === activeSlide ||
                            index === (activeSlide - 1 + data.length) % data.length

                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    // minHeight: "100%",
                                    // alignItems: "stretch",
                                }}
                            >
                                <CardItem
                                    title={item.title}
                                    subtitle={item.subtitle}
                                    imageUrl={item.imageUrl}
                                    isActive={isActive}
                                />
                            </Box>
                        );
                    })}
                </Slider>
            </Box>
        ));
};

export default Carousel;
