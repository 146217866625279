// import { IconButton, useTheme } from '@mui/material';
import React from 'react';

interface CarouselIndicatorProps {
    currentIndex: number;
    totalSlides: number;
    isMobile?: boolean;
    // onPrevious: () => void;
    // onNext: () => void;
}

const CarouselIndicator: React.FC<CarouselIndicatorProps> = ({ currentIndex, totalSlides, isMobile }) => {
    const renderBars = () => {
        return Array.from({ length: totalSlides }, (_, index) => {
            let height;
            let color = '#4C4F5D';

            if (isMobile ? index === currentIndex : index === currentIndex || index === currentIndex + 1) {
                height = 48;
                color = '#F7B500';
            } else if (index === currentIndex - 1 || index === currentIndex + 1) {
                height = 40;
            } else if (index === currentIndex - 2 || index === currentIndex + 2) {
                height = 32;
            } else if (index === currentIndex - 3 || index === currentIndex + 3) {
                height = 24;
            } else {
                height = 16;
            }

            return (
                <rect
                    key={index}
                    x={68 + index * 18}
                    y={(48 - height) / 2}
                    width="2"
                    height={height}
                    rx="1"
                    fill={color}
                />
            );
        });
    };

    const width = totalSlides === 4 ? 182 : 336

    return (
        <svg width={width} height="48" viewBox={`0 0 ${width} 48`} fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* <foreignObject x="0" y="2" width="44" height="44">
                <IconButton onClick={onPrevious} style={{ width: '100%', height: '100%', background: 'none', border: 'none', cursor: 'pointer' }}>
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y={2} width={44} height={44} rx={22} fill="#3A3E49" />
                        {currentIndex === 0 ?
                            <path d="M20.2577 24L24.7806 28.3812C25.1259 28.8106 25.0563 29.4374 24.6252 29.7813C24.1942 30.1252 23.5648 30.0559 23.2196 29.6266L18.1959 24.6227C17.9033 24.2587 17.9033 23.7413 18.1959 23.3773L23.2196 18.3734C23.5648 17.944 24.1942 17.8748 24.6252 18.2187C25.0563 18.5626 25.1259 19.1894 24.7806 19.6188L20.2577 24Z" fill="#868C99" />
                            :
                            <path d="M20.2577 24L24.7806 28.3812C25.1259 28.8106 25.0563 29.4374 24.6252 29.7813C24.1942 30.1252 23.5648 30.0559 23.2196 29.6266L18.1959 24.6227C17.9033 24.2587 17.9033 23.7413 18.1959 23.3773L23.2196 18.3734C23.5648 17.944 24.1942 17.8748 24.6252 18.2187C25.0563 18.5626 25.1259 19.1894 24.7806 19.6188L20.2577 24Z" fill="#F2F3F7" />
                        }
                    </svg>
                </IconButton>

            </foreignObject> */}

            {renderBars()}

            {/* <foreignObject x="292" y="2" width="44" height="44">
                <IconButton onClick={onNext} style={{ width: '100%', height: '100%', background: 'none', border: 'none', cursor: 'pointer' }}>
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="44" height="44" rx="22" fill="#3A3E49" />
                        <path d="M23.7423 24L19.2194 19.6188C18.8741 19.1894 18.9437 18.5626 19.3748 18.2187C19.8058 17.8748 20.4352 17.9441 20.7804 18.3734L25.8041 23.3773C26.0967 23.7413 26.0967 24.2587 25.8041 24.6227L20.7804 29.6266C20.4352 30.0559 19.8058 30.1252 19.3748 29.7813C18.9437 29.4374 18.8741 28.8106 19.2194 28.3812L23.7423 24Z" fill="#F2F3F7" />
                    </svg>
                </IconButton>

            </foreignObject> */}
        </svg>
    );
};

export default CarouselIndicator;
