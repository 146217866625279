import * as React from "react"

function FacebookIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16s6.268 14 14 14zm-3.61-13.533h1.837v6.139c0 .281.112.394.39.394h2.32c.334 0 .427-.113.427-.432v-6.12h2.153c.316 0 .409-.094.409-.394v-2.272c0-.3-.112-.413-.409-.413h-2.153c0-.196-.002-.388-.004-.578-.004-.373-.008-.738.004-1.111 0-.526.26-.77.761-.77.501-.019 1.003-.019 1.504-.019.26 0 .371-.113.371-.375v-2.14c0-.263-.111-.376-.371-.376h-1.82c-.5 0-.965.075-1.428.244a3.089 3.089 0 00-1.652 1.39c-.372.656-.502 1.37-.502 2.12v1.653H12.39c-.279 0-.39.094-.39.394v2.272c0 .281.093.394.39.394z"
                fill="#F2F3F7"
            />
        </svg>
    )
}

export default FacebookIcon
