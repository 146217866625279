import React from 'react';
import {
    Box, Grid, useTheme, useMediaQuery, Modal, IconButton,
    Typography,
    Button
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ExclamationMarkIcon from '../../../icons/ExclamationMarkIcon';
// import GetInTouchIcon from '../../BookDemoModal/img/GetInTouchIcon';
import IconWithText from '../IconWithText';


interface IProps {
    open: boolean;
    handleClose: () => void;
    image: string;
    leftBlock: React.ReactNode;
    isSubmitted?: boolean;
    errors: Record<string, boolean | string>;
    hondleRetry: () => void;
    title: string;
    description: string;
}


const ModalWindow = ({ handleClose, open, image, leftBlock, isSubmitted, errors, title, description }: IProps) => {
    const theme = useTheme();
    const localBreakpoints = { sm: 600, md: 900 };
    const isLocalTablet = useMediaQuery(`(max-width:${localBreakpoints.md}px)`);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="book-demo-modal"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: { xs: '80vw', sm: '90vw' },
                    maxHeight: '100vh',
                    overflow: 'hidden',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    borderRadius: {
                        xs: '16px',
                        // sm: isLocalTablet ? '24px' : '32px',
                        // md: '32px',
                    },
                    p: 1
                }}
            >

                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // flexDirection: isLocalTablet ? 'column' : 'row',
                        backgroundColor: theme.palette.background.default,
                        borderRadius: {
                            xs: '16px',
                            sm: isLocalTablet ? '24px' : '32px',
                            md: '42px',
                        },
                        // p: { xs: 2, sm: 4 },
                        p: 1,
                        // maxWidth: { xs: '90vw', md: '80vw', lg: '100vw' },
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            zIndex: 10,
                            backgroundColor: '#131518',
                            borderRadius: '8px',
                            color: theme.palette.text.primary,
                            '&:hover': {
                                backgroundColor: '#1A1D22',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid
                        item
                        xs={12}
                        // sm={5}
                        md={5}
                        lg={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: {
                                xs: '16px',
                                sm: isLocalTablet ? '24px' : '32px',
                            },
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            component="img"
                            src={image}
                            alt="Demo Image"
                            sx={{
                                width: '100%',
                                height: '100%',
                                maxHeight: { xs: '300px', md: '100%' },
                                objectFit: 'cover',
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        // sm={7}
                        md={7}
                        lg={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            px: {
                                xs: 2,
                                sm: 4,
                                md: 6,
                            },
                            py: {
                                xs: 3,
                                sm: 8,
                                md: 4,
                            },
                            width: '100%',
                            gap: 3,
                        }}
                    >
                        {isSubmitted ? (
                            <>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        textAlign: { xs: 'center', sm: 'left' },
                                        mb: 2,
                                        fontWeight: 600,
                                        width: '100%',
                                    }}
                                >
                                    {errors.errorSubmit || 'Your email has been sent successfully'}
                                    {' '}
                                    <ExclamationMarkIcon />
                                </Typography>

                                {/* <IconWithText
                                    text={`${errors.errorSubmit}` || 'Your email has been sent successfully'}
                                    variant="h1"
                                    iconSize="large"
                                /> */}

                                {!errors.errorSubmit && (
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            textAlign: { xs: 'center', sm: 'left' },
                                            mb: 3,
                                            fontWeight: 600,
                                            width: '90%',
                                        }}
                                    >
                                        Let’s have a call to discuss your requirements and give you an estimate of the cost
                                    </Typography>
                                )}

                                {/* <Button
                                    variant="contained"
                                    onClick={hondleRetry}
                                    sx={{
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        alignSelf: { xs: 'center', sm: 'flex-start' },
                                    }}
                                >
                                    Retry
                                </Button> */}
                            </>
                        ) : (
                            <>
                                <IconWithText
                                    text={title}
                                    variant="h1"
                                    iconSize="large"
                                    align={isMobile ? 'center' : 'left'}
                                />

                                <Typography
                                    variant="h6"
                                    sx={{
                                        textAlign: { xs: 'center', md: 'left' },
                                        lineHeight: 1.5,
                                        mb: 2,
                                        fontWeight: 500,
                                    }}
                                >
                                    {description}
                                </Typography>

                                {leftBlock}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};


export default ModalWindow;