import * as React from "react"

function DevAppActive() {
    return (
        <svg
            width={124}
            height={124}
            viewBox="0 0 124 124"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1515_25322)">
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint0_radial_1515_25322)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint1_radial_1515_25322)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint2_radial_1515_25322)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint3_radial_1515_25322)"
                />
                <path
                    d="M38 30.38A8.38 8.38 0 0146.38 22h31.24A8.38 8.38 0 0186 30.38v62.9a8.38 8.38 0 01-8.38 8.38H46.38A8.38 8.38 0 0138 93.28v-62.9z"
                    fill="#F2F3F7"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M77.62 24.095H46.38a6.285 6.285 0 00-6.285 6.285v62.9a6.285 6.285 0 006.285 6.285h31.24a6.285 6.285 0 006.285-6.285v-62.9a6.285 6.285 0 00-6.285-6.285zM46.38 22A8.38 8.38 0 0038 30.38v62.9a8.38 8.38 0 008.38 8.38h31.24A8.38 8.38 0 0086 93.28v-62.9A8.38 8.38 0 0077.62 22H46.38z"
                    fill="#0B0B0D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M57.404 97.513a.35.35 0 01.35-.349h8.493a.35.35 0 110 .698h-8.493a.35.35 0 01-.35-.349z"
                    fill="#0B0B0D"
                />
                <path
                    d="M52.297 22H71.7v1.106a4 4 0 01-4 4H56.297a4 4 0 01-4-4V22z"
                    fill="#0B0B0D"
                />
                <ellipse
                    cx={63.0218}
                    cy={62.8765}
                    rx={12.766}
                    ry={12.766}
                    fill="#A9ACC6"
                />
                <path
                    d="M74.766 61.855c0 7.05-5.715 12.766-12.766 12.766-7.05 0-12.766-5.715-12.766-12.766 0-7.05 5.716-12.766 12.766-12.766s12.766 5.716 12.766 12.766z"
                    fill="#F7B500"
                />
                <path
                    d="M54.483 60.798c-.42 0-.808.132-1.124.36v-1.699a.12.12 0 00-.12-.12H52.5a.12.12 0 00-.12.12v5.005c0 .066.053.12.12.12h.738a.12.12 0 00.12-.12v-.175a1.93 1.93 0 003.056-1.566 1.929 1.929 0 00-1.932-1.925zm0 2.984a1.06 1.06 0 01-1.061-1.058 1.06 1.06 0 011.061-1.058 1.06 1.06 0 011.061 1.058 1.06 1.06 0 01-1.061 1.058zM60.496 63.848a.12.12 0 01.002.173c-.52.507-1.177.643-1.652.643-1.382 0-2.139-.863-2.139-1.922 0-1.096.858-1.959 2.059-1.959 1.004 0 1.966.67 2.02 2.066a.12.12 0 01-.121.125h-2.881c.022.464.451.805 1.142.805.348 0 .695-.16.953-.372a.12.12 0 01.16.005l.457.436zm-2.69-1.527h1.869c-.073-.479-.56-.66-.924-.66-.349 0-.815.16-.945.66zM70.95 63.293c.385 0 .69.3.69.693a.674.674 0 01-.69.677.664.664 0 01-.684-.677c0-.393.29-.693.684-.693zm.3-.46h-.593a.109.109 0 01-.108-.1l-.246-3.164a.108.108 0 01.108-.117h1.083c.063 0 .112.053.108.117l-.245 3.164a.107.107 0 01-.108.1zM69.545 62.725a1.93 1.93 0 00-3.055-1.566v-.175a.12.12 0 00-.12-.12h-.738a.12.12 0 00-.12.12v5.14c0 .066.053.12.12.12h.738a.12.12 0 00.12-.12V64.29c.316.227.704.36 1.124.36a1.926 1.926 0 001.931-1.925zm-.87 0a1.06 1.06 0 01-1.061 1.059 1.06 1.06 0 01-1.061-1.059 1.06 1.06 0 011.06-1.058 1.06 1.06 0 011.062 1.058zM64.853 63.848a.12.12 0 01.002.173c-.52.507-1.177.643-1.652.643-1.382 0-2.139-.863-2.139-1.922 0-1.096.858-1.959 2.06-1.959 1.003 0 1.965.67 2.02 2.066a.12.12 0 01-.122.125h-2.88c.021.464.45.805 1.141.805.348 0 .696-.16.953-.372a.12.12 0 01.16.005l.457.436zm-2.69-1.527h1.868c-.072-.479-.56-.66-.924-.66-.348 0-.813.16-.945.66z"
                    fill="#0B0B0D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M62 73.225c6.28 0 11.37-5.09 11.37-11.37 0-6.279-5.09-11.369-11.37-11.369-6.279 0-11.369 5.09-11.369 11.37 0 6.278 5.09 11.369 11.37 11.369zm0 1.396c7.05 0 12.766-5.715 12.766-12.766 0-7.05-5.715-12.766-12.766-12.766-7.05 0-12.766 5.716-12.766 12.766S54.95 74.621 62 74.621z"
                    fill="#0B0B0D"
                />
            </g>
            <defs>
                <radialGradient
                    id="paint0_radial_1515_25322"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop stopColor="#F7B500" />
                    <stop offset={0.426666} stopColor="#F7B500" stopOpacity={0} />
                    <stop offset={1} stopColor="#F7B500" />
                </radialGradient>
                <radialGradient
                    id="paint1_radial_1515_25322"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop stopColor="#F7B500" />
                    <stop offset={0.426666} stopColor="#F7B500" stopOpacity={0} />
                    <stop offset={1} stopColor="#F7B500" />
                </radialGradient>
                <radialGradient
                    id="paint2_radial_1515_25322"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop stopColor="#F7B500" />
                    <stop offset={0.426666} stopColor="#F7B500" stopOpacity={0} />
                    <stop offset={1} stopColor="#F7B500" />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_1515_25322"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop stopColor="#F7B500" />
                    <stop offset={0.426666} stopColor="#F7B500" stopOpacity={0} />
                    <stop offset={1} stopColor="#F7B500" />
                </radialGradient>
                <clipPath id="clip0_1515_25322">
                    <path fill="#fff" d="M0 0H124V124H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DevAppActive
