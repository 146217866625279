import React from 'react';
import { Box, TextField, InputAdornment, Button, CircularProgress, Card, CardContent, Typography, Grid, Radio, RadioGroup, FormControlLabel, styled, Tooltip, IconButton, TooltipProps, tooltipClasses } from '@mui/material';

import PersonIcon from '../../../icons/PersonIcon';
import CompanyIcon from '../../../icons/CompanyIcon';
import EmailIcon from '../../../icons/EmailIcon';
import CommentIcon from '../../../icons/CommentIcon';
import CountryIcon from '../../../icons/CountryIcon';
import InfoIcon from '../../../icons/InfoIcon';

interface IFormField {
    name?: string;
    label?: string;
    icon?: React.ReactNode;
    type?: string;
    isError?: boolean;
    helperText?: string;
    size?: number;
    component?: React.ReactNode;
    isCustomComponent?: boolean;
}

interface IProps {
    formValues: Record<string, string>;
    errors: Record<string, boolean | string>;
    isLoading: boolean;
    isLocalTablet?: boolean;
    handleChange: (field: string, value: string) => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    setErrors?: React.Dispatch<React.SetStateAction<Record<string, boolean | string>>>;
    radioValue: string;
    onRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 166,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        borderRadius: 8,
        p: 2
    },
    '& .MuiTooltip-arrow': {
        color: '#EAECF2', // Цвет стрелки
    },
    // '& .MuiTooltip-tooltip': {
    //     backgroundColor: '#EAECF2', // Цвет тултипа
    //     color: '#333', // Цвет текста
    // },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
    '&.MuiRadio-root': {
        color: '#8E8E93',
    },
    '&.Mui-checked': {
        color: theme.palette.primary.main,
    }
}));

const CustomLabel = styled(Typography)<{ isActive: boolean }>(({ isActive }) => ({
    color: isActive ? '#F2F3F7' : '#8E8E93',
    fontWeight: 600
}));

const FormCard: React.FC<IProps> = ({ formValues, errors, isLoading, isLocalTablet = false, handleChange, handleSubmit, setErrors, radioValue, onRadioChange }) => {
    const formFields: IFormField[] = [
        { name: 'name', label: 'Name', icon: <PersonIcon /> },
        { size: 6, name: 'company', label: 'Company', icon: <CompanyIcon /> },
        { size: 6, name: 'domain', label: 'Domain', icon: <CompanyIcon /> },
        {
            isCustomComponent: true,
            component: (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, ml: 2 }}>
                    <Box>
                        <Typography variant='h6'>Number of Customer</Typography>
                    </Box>
                    <RadioGroup
                        sx={{
                            '& .MuiRadio-root': { color: 'secondary' }, ml: 2, width: '100%',
                            gap: 5
                        }}

                        row aria-label='controlled' name='controlled' value={radioValue} onChange={onRadioChange}>
                        <FormControlLabel value='<50k' control={<CustomRadio color='warning' />} label={<CustomLabel isActive={radioValue === '<50k'}>{'<50k'}</CustomLabel>} />
                        <FormControlLabel value='50-500k' control={<CustomRadio />} label={<CustomLabel isActive={radioValue === '50-500k'}>{'50-500k'}</CustomLabel>} />
                        <FormControlLabel value='>500k' control={<CustomRadio />} label={<CustomLabel isActive={radioValue === '>500k'}>{'>500k'}</CustomLabel>} />
                    </RadioGroup>
                </Box>
            )
        },
        { name: 'country', label: 'Country', icon: <CountryIcon /> },
        { name: 'email', label: 'Email', icon: <EmailIcon />, isError: !!errors.email, helperText: typeof errors.email === 'string' ? errors.email : '' },
        { name: 'comment', label: 'Comment', icon: <CommentIcon /> }
    ];

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 5, md: 8 } }}>
                <Grid container spacing={4}>
                    {formFields.map((field, index) => (
                        <Grid item xs={field.size || 12} key={index}>
                            {field.isCustomComponent ? (
                                field.component
                            ) : (
                                <TextField
                                    label={field.label}
                                    variant="outlined"
                                    size={isLocalTablet ? 'small' : 'medium'}
                                    fullWidth
                                    autoComplete="off"
                                    value={field.name ? formValues[field.name] : ''}
                                    onChange={(e) => field.name && handleChange(field.name, e.target.value)}
                                    onFocus={() => field.name === 'email' && setErrors && setErrors((prev) => ({ ...prev, email: false }))}
                                    onBlur={(e) => {
                                        if (field.name === 'email' && setErrors) {
                                            setErrors((prev) => ({
                                                ...prev,
                                                email: !e.target.value.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value) ? 'Please enter a valid email address.' : ''
                                            }));
                                        }
                                    }}
                                    error={field.isError}
                                    helperText={field.helperText}
                                    InputProps={{
                                        startAdornment: field.icon ? (
                                            <InputAdornment position="start">
                                                {field.icon}
                                            </InputAdornment>
                                        ) : undefined,
                                        endAdornment: field.name === 'domain' ? (
                                            <InputAdornment position="end">
                                                <CustomTooltip title='What best describes the services that your company provides?' arrow placement="top">
                                                    <IconButton>
                                                        <InfoIcon color='#EAECF2' />
                                                    </IconButton>
                                                </CustomTooltip>
                                            </InputAdornment>
                                        ) : undefined
                                    }}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>

                <Button
                    variant="contained"
                    type="submit"
                    size='large'
                    disabled={isLoading || !!errors.email}
                    endIcon={isLoading && <CircularProgress size={22} />}
                    sx={{ borderRadius: '12px', textTransform: 'none', alignSelf: { xs: 'flex-start' } }}
                >
                    Get an estimate!
                </Button>
            </Box>
        </form>
    );
};

export default FormCard;
