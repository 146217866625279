import React, { useRef, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

interface CardProps {
    title: string;
    descrWith?: { xs: string; sm: string };
    description?: string;
    icon?: any;
    style?: any;
    positionStyle?: any
}

const CardHelp: React.FC<CardProps> = ({ style, title, description, icon, descrWith, positionStyle }) => {
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [hovered, setHovered] = useState<boolean>(false);
    const cardRef = useRef<HTMLDivElement | null>(null);

    return (
        <Grid container direction="column" position="relative" ref={cardRef}>
            <Box
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                sx={{
                    display: 'flex',
                    // minHeight: isMobile ? '240px' : '149px',
                    width: '216px',
                    height: '155px',
                    flexShrink: 0,
                    minHeight: '100%',
                    minWidth: '100%',
                    padding: { xs: 4 },
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'space-between',
                    gap: '16px',
                    borderRadius: '12px',
                    backgroundColor: '#EAECF2',
                    border: '1px solid #EAECF2',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                    },
                    ...style,
                }}
            >
                <Box>{icon}</Box>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 700,
                        textAlign: 'left',
                        color: theme.palette.grey['600'],
                        lineHeight: '24px',
                    }}
                >
                    {title}
                </Typography>
            </Box>

            <Box
                sx={{
                    display: hovered ? 'flex' : 'none',
                    position: 'absolute',
                    top: '100%',
                    width: descrWith,
                    backgroundColor: theme.palette.grey['400'],
                    borderRadius: '12px',
                    padding: '12px',
                    opacity: hovered ? 1 : 0,
                    transition: 'all 0.3s ease',
                    boxShadow: theme.shadows[3],
                    zIndex: 10,
                    ...positionStyle,
                }}
            >
                <Typography variant='body2' sx={{ textAlign: 'left', lineHeight: '20px !important', fontSize: '12px', fontWeight: 700, color: theme.palette.common.white }}>
                    {description}
                </Typography>
            </Box>
        </Grid>
    )
};

export default CardHelp;