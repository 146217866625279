import { IconButton } from '@mui/material';
// import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useTheme } from '@mui/material/styles';
import ArrowRightIcon from '../../TheBeepPlatform/img/ArrowRightIcon';
import ArrowLeftIcon from '../../TheBeepPlatform/img/ArrowLeftIcon';

interface CustomIconButtonProps {
    onClick: () => void;
    disabled?: boolean;
    type: 'left' | 'right';
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({ onClick, disabled = false, type }) => {
    const theme = useTheme();

    return (
        <IconButton
            onClick={onClick}
            disabled={disabled}
            sx={{
                display: 'block',
                width: '40px',
                height: '40px',
                backgroundColor: theme.palette.grey[600],
                color: '#fff',
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                    color: '#000',
                },
                '&:disabled': {
                    backgroundColor: theme.palette.grey[400],
                    color: theme.palette.text.disabled,
                    cursor: 'not-allowed',
                },
            }}
        >
            {type === 'left' && <ArrowLeftIcon />}
            {type === 'right' && <ArrowRightIcon />}
        </IconButton>
    );
};

export default CustomIconButton;
