import React from 'react'
import { Box, Typography, Grid, useTheme, Button, useMediaQuery } from '@mui/material';
import footerImage from './img/Footer.png'
import DriverCode from './img/client.png'
import PartnerCode from './img/partner.png'
import LogoBeepFooter from './img/LogoBeepFooter';
import TelegramIcon from './img/TelegramIcon';
import InstagramIcon from './img/InstagramIcon';
import FacebookIcon from './img/Facebook';
import { Link } from 'react-scroll';
import ButtonGetDemo from '../uknown/ButtonGetDemo';

const socialLinks = [
    "https://www.facebook.com/beepautoclub",
    "https://t.me/BeepClub_bot",
    "https://www.instagram.com/accounts/login/?next=/beep.club/"
];

interface IProps {
    setOpenDemo: (value: boolean) => void;
}

const Footer = ({ setOpenDemo }: IProps) => {
    const theme = useTheme();

    const isLocalTablet = useMediaQuery(`(max-width:950px)`);

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
                backgroundColor: theme.palette.primary.main,
                borderRadius: { xs: '24px', sm: '36px' },
                overflow: 'hidden',
                mb: { xs: 4, sm: 8 },
            }}
        >
            <Grid
                item
                xs={12}
                sm={isLocalTablet ? 12 : 6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    padding: { xs: '24px', sm: '48px' },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        // gap: { xs: '16px', sm: '12px' },
                        mb: 4,
                    }}
                >
                    <Typography variant="caption">Beep! Club Limited</Typography>

                    <Box sx={{
                        display: 'flex',
                        width: { xs: '80%', sm: '100%' },
                        mt: 4,
                        mb: 2
                    }}>
                        <LogoBeepFooter />
                    </Box>

                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '12px',
                            textAlign: 'left',
                            color: theme.palette.grey[700],
                            fontWeight: 600,
                            // width: '60%'
                        }}
                    >
                        Registered in England and Wales: 12230614
                        Registered office: Capital Tower, 91 Waterloo Road, London SE1 9RT
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        gap: { xs: 4, md: 12 },
                        flexWrap: 'wrap',
                        width: '100%',
                        alignItems: { xs: 'center', sm: 'flex-end' },
                        justifyContent: { xs: 'flex-start', sm: 'space-evenly', md: 'flex-start', },
                    }}
                >
                    {[
                        {
                            src: DriverCode,
                            title: 'Driver app',
                            description: 'For your driver clients',
                            link: 'https://app.beep.club/5kr1',
                        },
                        {
                            src: PartnerCode,
                            title: 'Partner app',
                            description: 'For your car service clients',
                            link: 'https://beeppartner.page.link/naxz',
                        },
                    ].map((item, index) => (
                        <Box key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'row', sm: 'column' },
                                alignItems: 'center',
                                borderRadius: '8px',
                                justifyContent: 'flex-start',
                                gap: 4,
                            }}>

                            <Box
                                component="a"
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={item.src}
                                    sx={{
                                        width: { xs: '168px', sm: '168px' },
                                        borderRadius: '16px',
                                        marginBottom: 4,
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    textAlign: isLocalTablet ? "start" : "center",
                                    alignItems: 'center',
                                }}>
                                <Typography variant="h5" color="secondary">
                                    {item.title}
                                </Typography>
                                <Typography variant="caption" sx={{ fontWeight: 500 }} >{item.description}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                sm={isLocalTablet ? 12 : 6}
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    p: 1
                }}>

                <Box
                    component="img"
                    src={footerImage}
                    alt="Footer Background"
                    sx={{
                        // minWidth: { xs: '100%', md: '618px' },
                        // maxHeight: { xs: '100%', md: '540px' },
                        // width: '100%',
                        // height: '100%',
                        // borderRadius: { xs: '24px', sm: '30px' },
                        width: '100%',
                        height: '100%',
                        borderRadius: { xs: '24px', sm: '32px' },
                        maxHeight: { xs: '100%', md: '540px' },
                        objectFit: 'cover',
                        // objectFit: 'contain',
                    }}
                />

                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: { xs: '24px', sm: '48px' },
                            left: { xs: '24px', sm: '48px' },
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        {[FacebookIcon, TelegramIcon, InstagramIcon].map((Icon, index) => (
                            <Button
                                key={index}
                                href={socialLinks[index]}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    minWidth: 'unset',
                                    padding: 0,
                                    color: theme.palette.common.white,
                                    '&:hover': { color: theme.palette.secondary.main },
                                }}
                            >
                                <Icon />
                            </Button>
                        ))}
                    </Box>

                    <Typography
                        variant="h1"
                        sx={{
                            position: 'absolute',
                            left: { xs: '24px', sm: '48px' },
                            bottom: { xs: '40%', md: '25%' },
                            // color: theme.palette.common.white,
                            letterSpacing: '0.1px',
                            lineHeight: '1.3',
                            textAlign: 'start',
                            width: '80%',
                        }}
                    >
                        because your car should bring you joy!
                    </Typography>

                    <Link to="book-demo-section" smooth={true} duration={500}>
                        <ButtonGetDemo variant="contained"
                            onClick={() => setOpenDemo(true)}
                            sx={{
                                position: 'absolute',
                                bottom: { xs: '24px', sm: '48px' },
                                left: { xs: '24px', sm: '48px' },
                                borderRadius: '12px',
                                textTransform: 'none',
                            }}
                        >
                            Get in touch!
                        </ButtonGetDemo>
                    </Link>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Footer
