import * as React from "react"

function EmailIcon() {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M8.463 9.915a.897.897 0 01-.472.132.897.897 0 01-.473-.132L0 5.325v6.101a2.362 2.362 0 002.361 2.361H13.64A2.362 2.362 0 0016 11.428V5.324l-7.537 4.59z"
                fill="#868C99"
            />
            <path
                d="M13.64 2H2.361A2.347 2.347 0 00.076 3.851L8.01 8.687l7.915-4.836A2.347 2.347 0 0013.639 2z"
                fill="#868C99"
            />
        </svg>
    )
}

export default EmailIcon
