import { Theme } from '@mui/material/styles';

const Typography = (theme: Theme) => {
    return {
        h1: {
            fontSize: '3.5rem', // 56px
            fontWeight: 700,
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
        h2: {
            fontSize: '2.5rem', // 40px
            fontWeight: 700,
            textAlign: 'left',
            letterSpacing: '-0.5px',
            color: theme.palette.text.primary,
        },
        h3: {
            fontSize: '2rem', // 32px
            fontWeight: 700,
            textAlign: 'left',
            letterSpacing: '0px',
            color: theme.palette.text.primary,
        },
        h4: {
            // fontSize: '1.25rem', // 20px
            // fontWeight: 500,
            // letterSpacing: '0.5px',
            // color: theme.palette.text.primary,
            fontSize: '1.5rem', // 24px
            textAlign: 'left',
            fontWeight: 700,
            letterSpacing: '0px',
            color: theme.palette.text.disabled,
        },
        h5: {
            // fontSize: '1rem', // 16px
            // fontWeight: 500,
            // // letterSpacing: '0px',
            // color: theme.palette.text.disabled,
            fontSize: '1.25rem', // 20px
            textAlign: 'left',
            fontWeight: 700,
            letterSpacing: '0.5px',
            color: theme.palette.text.primary,
        },
        h6: {
            // fontSize: '0.875rem', // 14px
            // letterSpacing: '0.15px',
            // color: theme.palette.text.primary,
            textAlign: 'left',
            fontSize: '1rem', // 16px
            fontWeight: 500,
            color: theme.palette.text.disabled,
        },
        subtitle1: {
            fontSize: '1rem', // 16px
            letterSpacing: '0.15px',
            textAlign: 'left',
            color: theme.palette.primary.main,
        },
        subtitle2: {
            fontSize: '0.875rem', // 14px
            textAlign: 'left',
            letterSpacing: '0.1px',
            color: theme.palette.text.disabled,
        },
        body1: {
            fontSize: '1rem', // 16px
            letterSpacing: '0.15px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
        body2: {
            fontSize: '0.875rem', // 14px
            lineHeight: 1.429,
            textAlign: 'left',
            letterSpacing: '0.15px',
            color: theme.palette.text.disabled,
        },
        button: {
            fontSize: '0.875rem', // 14px
            textAlign: 'left',
            letterSpacing: '0.4px',
            color: theme.palette.text.primary,
        },
        caption: {
            fontSize: '0.75rem', // 12px
            // lineHeight: 1.25,
            letterSpacing: '0.4px',
            textAlign: 'left',
            fontWeight: 700,
            color: theme.palette.text.secondary,
        },
        overline: {
            fontSize: '0.75rem', // 12px
            lineHeight: 1.25,
            textAlign: 'left',
            letterSpacing: '0.4px',
            fontWeight: 700,
            color: theme.palette.text.primary,
        },
    };
};

export default Typography;
