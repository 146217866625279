import React, { useState } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Box, /* useMediaQuery,  */useTheme } from '@mui/material';
import Header from "./components/Header";
import HeroComponent from './components/HeroBanner';
import HeroStats from './components/HeroStats';
import WhoWeHelpBlock from './components/WhoWeHelp';
import HowWeHelpBlock from './components/HowWeHelp';
import TheBeepPlatform from './components/TheBeepPlatform';
import Section from './components/uknown/Section';
import Footer from './components/Footer';
import BookDemoModal from './components/BookDemoModal';
import OurPartners from './components/OurPartners';
import FiaRegion from './components/FiaRegion';
import HeroBottom from './components/HeroBottom';
import OurSolutions from './components/OurSolutions';
import AboutUsBlock from './components/AboutUs';
import FAQSBlock from './components/FAQs';
import CaseStudyBlock from './components/CaseStudy';
import SliderTeam from './components/AboutUs/SliderTeam';
import GetEstimateModal from './components/GetEstimate';
import BeepGreat from './components/BeepGreat';

function App() {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openEstimate, setOpenEstimate] = useState(false);
  const [openDemo, setOpenDemo] = useState(false);

  return (
    <ParallaxProvider>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'enter',
        alignSelf: 'stretch',
        textAlign: 'center',
        minHeight: '100vh',
        margin: '0 auto',
        overflow: 'hidden',
        width: '100%',
      }}>

        <Section id='header' bgColor='trasparent'>
          <Header setOpenDemo={setOpenDemo} setOpenEstimate={setOpenEstimate} />
        </Section>

        <Section id="hero-section"
          style={{ position: "relative", zIndex: 2 }}
          bgColor={theme.palette.background.default}>
          <Parallax
            translateY={[-25, 10]}
          >
            <HeroComponent />
          </Parallax>
        </Section>

        <Section
          id="hero-section"
          padding={theme.palette.customSpacing}
          bgColor={theme.palette.background.default}
        >
          <Parallax translateY={[-10, 30]}>
            <HeroBottom />
          </Parallax>
        </Section>

        <Section
          id="platform-section"
          padding={theme.palette.customSpacing}
          bgColor={theme.palette.warning.main}
        >
          <Parallax translateY={[-10, 20]}>
            <HeroStats />
          </Parallax>
        </Section>

        <Section id="who-we-help"
          padding={theme.palette.customSpacing.lg}
          bgColor={theme.palette.background.paper}
        >
          <Parallax translateY={[10, -10]}>
            <WhoWeHelpBlock />
          </Parallax>
        </Section>

        <Section id="how-we-help"
          bgColor={theme.palette.grey['100']}
          padding={theme.palette.customSpacing}
        >
          <Parallax translateY={[20, -20]}>
            <HowWeHelpBlock />
          </Parallax>
        </Section>

        <Section id="solutions"
          bgColor={theme.palette.grey[700]}
          padding={theme.palette.customSpacing}
        >
          <Parallax translateY={[10, -5]}>
            <OurSolutions setOpenEstimate={setOpenEstimate} />
          </Parallax>
        </Section>

        <Parallax translateY={[0, 0]}>
          <Box
            sx={{
              width: '100%',
              backgroundColor: theme.palette.warning.main,
              position: 'relative',
            }}
          >
            <BeepGreat />
          </Box>
        </Parallax>

        <Box
          id="platform"
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.default,
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
            py: theme.palette.customSpacing,
          }}
        >
          <Parallax translateY={[10, -10]}>
            <TheBeepPlatform />
          </Parallax>
        </Box>

        <Section id="about-us" bgColor={theme.palette.background.paper}>
          <Parallax translateY={[20, -20]}>
            <AboutUsBlock />
          </Parallax>
        </Section>

        <Box
          id="platform-section"
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            height: '100%',
            py: theme.palette.customSpacing,
          }}
        >
          <Parallax translateY={[-10, 20]}>
            <SliderTeam />
          </Parallax>
        </Box>

        <Section id="FAQs"
          padding={theme.palette.customSpacing}
          bgColor={theme.palette.warning.main}>
          <Parallax translateY={[10, -10]}>
            <FAQSBlock />
          </Parallax>
        </Section>

        <Section id="case-study"
          padding={theme.palette.customSpacing}
          bgColor={theme.palette.customColors.lightBg}>
          <Parallax translateY={[10, 0]}>
            <CaseStudyBlock />
          </Parallax>
        </Section>

        <Box id="platform-section" sx={{ backgroundColor: theme.palette.customColors.lightBg }}>
          <Parallax translateY={[30, 10]}>
            <Box
              id="platform-section"
              sx={{
                width: '100%',
                backgroundColor: theme.palette.warning.main,
                py: theme.palette.customSpacing,
              }}
            >
              <Parallax translateY={[10, -20]}>
                <OurPartners />
              </Parallax>
            </Box>
          </Parallax>
        </Box>

        <Section id="book-demo-section"
          padding={theme.palette.customSpacing}
          bgColor={theme.palette.grey[100]}>
          <Parallax translateY={[20, -10]}>
            <FiaRegion />
          </Parallax>
        </Section>

        <Section id="get-section"
          padding={theme.palette.customSpacing}
          bgColor={theme.palette.grey[100]}>
          <Parallax translateY={[0, 0]}>
            <Footer setOpenDemo={setOpenDemo} />
          </Parallax>
        </Section>

        <GetEstimateModal setOpenEstimate={setOpenEstimate} openEstimate={openEstimate} />
        <BookDemoModal openDemo={openDemo} setOpenDemo={setOpenDemo} />
      </Box>
    </ParallaxProvider >
  );
}

export default App;

