import * as React from "react"

function CarFinance() {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x={0.5} width={47} height={48} rx={10} fill="#24262C" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34 22H14v7a2 2 0 002 2h16a2 2 0 002-2v-7zm-2 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                fill="#F7B500"
            />
            <path d="M14 18a1 1 0 011-1h18a1 1 0 011 1v2H14v-2z" fill="#F7B500" />
        </svg>
    )
}

export default CarFinance
