import * as React from "react"

function InfoIcon({ color }: { color?: string }) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.333 11.333l.037-.017a.666.666 0 01.945.757l-.63 2.52a.667.667 0 00.945.759l.037-.019M20 12a7.999 7.999 0 11-15.998 0A7.999 7.999 0 0120 12zm-8-3.333h.007v.007H12v-.007z"
                stroke={color || "#868C99"}
                strokeWidth={1.2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default InfoIcon
