import { Box, Container, useTheme } from '@mui/material';

interface IProps {
    id: string;
    children: React.ReactNode;
    variant?: string;
    bgColor?: string;
    padding?: any;
    maxWidth?: boolean;
    style?: any;
}

const Section = ({ id, children, variant, bgColor, padding, maxWidth, style }: IProps) => {
    const theme = useTheme();

    return (
        <Box
            id={id}
            sx={{
                width: '100%',
                backgroundColor: bgColor,
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
                minWidth: '320px',
                ...style,
            }}
        >
            <Container
                maxWidth={"xl"}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: {
                        xs: `calc(100% - ${theme.spacing(8)})`,
                        sm: `calc(100% - ${theme.spacing(12)})`,
                        xl: theme.breakpoints.values.xl,
                    },
                    py: padding
                }}
            >
                {children}
            </Container>
        </Box>
    );
}

export default Section;
