import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import EstimateImg from '../../images/EstimateImg.png';
import EstimateSubmitImg from '../../images/EstimateSubmitImg.png';
import axios from 'axios';
import ModalWindow from '../uknown/ModalWindow';
import FormCard from './components/FormCard';

const API_URL = 'https://api.saas-beep.club/AdminSupport-GetEstimate';

interface IProps {
    openEstimate: boolean;
    setOpenEstimate: (value: boolean) => void;
}

const GetEstimateModal = ({ openEstimate, setOpenEstimate }: IProps) => {
    // const theme = useTheme();
    const localBreakpoints = { sm: 600, md: 900, };
    const isLocalTablet = useMediaQuery(`(max-width:${localBreakpoints.md}px)`);

    const [formValues, setFormValues] = useState({
        name: '',
        company: '',
        email: '',
        date: '',
        comment: '',
    });
    const [errors, setErrors] = useState<Record<string, boolean | string>>({
        email: false,
        errorSubmit: '',
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (field: string, value: string) => {
        setFormValues((prev) => ({ ...prev, [field]: value }));
    };
    const handleClose = () => {
        setOpenEstimate(false);
        setIsSubmitted(false);
        setFormValues({ email: '', name: '', company: '', date: '', comment: '' });
        setErrors({ email: false, errorSubmit: '' });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!formValues.email.trim() || errors.email) {
            setErrors((prev) => ({
                ...prev,
                email: true
            }));
            return;
        }

        const formData = {
            email: formValues.email,
            message: formValues.comment || '',
            name: formValues.name || '',
            company: formValues.company || '',
        };

        setIsLoading(true);

        try {
            const response = await axios.post(API_URL, { data: formData });

            if (response.status !== 200) {
                setErrors((prev) => ({
                    ...prev,
                    errorSubmit: 'Error submitting form. Please try again.',
                }));
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrors((prev) => ({
                ...prev,
                errorSubmit: 'Error submitting form. Please try again later.',
            }));
        } finally {
            setIsLoading(false);
        }
        setIsSubmitted(true);
    };

    const hondleRetry = () => {
        setIsSubmitted(false)
        if (!errors.errorSubmit) {
            setFormValues({ email: '', name: '', company: '', date: '', comment: '' });
        }
    }
    const [radioValue, setRadioValue] = useState('');
    const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue(e.target.value);
    };

    return (
        <ModalWindow
            open={openEstimate}
            handleClose={handleClose}
            image={isSubmitted ? EstimateSubmitImg : EstimateImg}
            isSubmitted={isSubmitted}
            hondleRetry={hondleRetry}
            errors={errors}
            title="Get in estimate"
            description="Let’s have a call to discuss your requirements and give you an estimate of the cost"
            leftBlock={
                <FormCard
                    radioValue={radioValue}
                    onRadioChange={onRadioChange}
                    formValues={formValues}
                    errors={errors}
                    isLoading={isLoading}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isLocalTablet={isLocalTablet}
                    setErrors={setErrors}
                />
            }
        />
    );
};

export default GetEstimateModal;

