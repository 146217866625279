import Grid from '@mui/material/Grid';

import { Box } from '@mui/material';
import { Link } from 'react-scroll';
import LayoutAppBar from '../uknown/Toolbar';
import { ButtonMenu } from '../uknown/ButtonMenu';
import ButtonGetDemo from '../uknown/ButtonGetDemo';
import LogoBeepXl from '../../icons/LogoBeepXl';

interface IProps {
    setOpenDemo: (value: boolean) => void;
    setOpenEstimate: (value: boolean) => void;
}

function Header({ setOpenDemo, setOpenEstimate }: IProps) {
    return (
        <LayoutAppBar>
            <Grid
                container
                sx={{
                    display: 'flex',
                    alignItems: { xs: 'end', md: 'center' },
                }}
            >
                <Grid item
                    xs={5}
                    sm={6}
                    md={1}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <Link to="hero-section" smooth={true} duration={500}>
                        <Box sx={{
                            display: 'flex',
                            flexGrow: 1,
                            px: { xs: 3, sm: 4 }
                        }}>
                            <LogoBeepXl />
                        </Box>
                    </Link>
                </Grid>


                <Grid item
                    md={9.3}
                    lg={9.5}
                    sx={{
                        justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' },
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: { sm: 0, md: 1, lg: 2, xl: 4 }
                    }}>
                        <Link to="who-we-help" smooth={true} duration={500}>
                            <ButtonMenu variant="text">Who we help</ButtonMenu>
                        </Link>

                        <Link to="how-we-help" smooth={true} duration={500}>
                            <ButtonMenu variant="text">How we help</ButtonMenu>
                        </Link>

                        <Link to="solutions" smooth={true} duration={500}>
                            <ButtonMenu variant="text">Our solutions</ButtonMenu>
                        </Link>

                        <ButtonMenu onClick={() => setOpenEstimate(true)} variant="text">Pricing</ButtonMenu>

                        <Link to="platform" smooth={true} duration={500}>
                            <ButtonMenu variant="text">Platform</ButtonMenu>
                        </Link>

                        <Link to="about-us" smooth={true} duration={500}>
                            <ButtonMenu variant="text">About us</ButtonMenu>
                        </Link>

                        <Link to="case-study" smooth={true} duration={500}>
                            <ButtonMenu variant="text">Case Study</ButtonMenu>
                        </Link>

                        <Link to="FAQs" smooth={true} duration={500} offset={-80}>
                            <ButtonMenu variant="text">FAQs</ButtonMenu>
                        </Link>
                    </Box>
                </Grid>

                <Grid item
                    xs={7}
                    sm={6}
                    md={1.7}
                    lg={1.5}
                    sx={{
                        display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                        gap: 2
                    }}>

                    <ButtonMenu
                        sx={{
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            display: { xs: 'flex', md: 'none' },
                        }}
                        onClick={() => setOpenEstimate(true)} variant="text"
                    >
                        Pricing
                    </ButtonMenu>

                    <ButtonGetDemo
                        onClick={() => setOpenDemo(true)}
                        variant="contained"
                    >
                        Get intouch
                    </ButtonGetDemo>
                </Grid>
            </Grid>
        </LayoutAppBar >
    );
}

export default Header;
