const DefaultPalette = () => {
    const whiteColor = '#F2F3F7';
    const secondaryColor = '#24262C';
    const disabledColor = '#868C99';
    const mainColor = '#F7B500';
    const borderColor = '#4C4F5D';

    // const grey = '#232830';

    return {
        customColors: {
            main: mainColor,
            bodyBg: '#33353E',
            darkBg: '#1F2029',
            lightBg: whiteColor,
        },
        common: {
            black: 'rgba(0, 0, 0, 0.4)',
            white: whiteColor,
            green: '#22D56E',
        },
        primary: {
            main: mainColor,
        },
        secondary: {
            main: secondaryColor,
        },
        warning: {
            main: mainColor,
        },
        error: {
            main: '#FF1414',
        },
        info: {
            main: '#26C6F9',
        },
        success: {
            main: '#22D56E',
        },
        grey: {
            100: '#EAECF2',
            200: '#DEE1E9',
            400: disabledColor,
            500: borderColor,
            600: '#3A3E49',
            650: '#33353E',
            700: secondaryColor,
        },
        text: {
            primary: whiteColor,
            secondary: secondaryColor,
            disabled: disabledColor,
        },
        divider: borderColor,
        background: {
            default: '#33353E',
            paper: whiteColor,
        },
        action: {
            active: mainColor,
            hover: borderColor,
            hoverOpacity: 0.05,
            selected: mainColor,
            disabled: secondaryColor,
            disabledBackground: disabledColor,
            focus: `rgba(${mainColor}, 0.12)`,
        },
    };
};

export default DefaultPalette;
