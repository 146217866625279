import * as React from "react"

function Others() {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={40} height={40} rx={10} fill="#fff" fillOpacity={0.05} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20c0-5.523 4.477-10 10-10 5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.523 0-10-4.477-10-10zm10-5a1 1 0 100 2 1 1 0 000-2zm0 3a1 1 0 00-1 1v5a1 1 0 002 0v-5a1 1 0 00-1-1z"
                fill="#DEE1E9"
            />
        </svg>
    )
}

export default Others
