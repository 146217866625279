import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import FiaIcon from './img/FiaRegion.png'

const FiaRegion = () => {
    const theme = useTheme();
    const isLocalTablet = useMediaQuery(`(max-width:800px)`);

    return (
        <Grid
            container
            spacing={{ xs: 4, sm: 8 }}
            sx={{
                display: 'flex',
                width: '100%',
                margin: '0 auto',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: { xs: 'center', sm: 'left' },
                my: { xs: 12, sm: 0 },
            }}
        >
            <Grid
                item
                xs={12}
                sm={isLocalTablet ? 12 : 5.3}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    component="img"
                    src={FiaIcon}
                    alt="Demo Image"
                    sx={{
                        minWidth: { xs: '280px', sm: '336px' },
                        width: { xs: '80%', sm: '336px' },
                        maxHeight: { xs: '162px', md: '100%' },
                        objectFit: 'cover',
                    }}
                />
            </Grid>

            <Grid
                item
                xs={12}
                sm={isLocalTablet ? 12 : 6.7}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    alignItems: { xs: 'center', md: 'flex-start' },
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        width: { xs: '100%', sm: '76%' },
                        color: theme.palette.text.secondary,
                        fontSize: { xs: '24px', md: '40px' },
                        fontWeight: 700,
                        lineHeight: { xs: '32px', md: '54px' },
                        letterSpacing: { xs: '-1.6px' },
                    }}
                >
                    Beep! is very proud to have won the 2023 FIA Region Start-up Challenge in the Car Category
                </Typography>
            </Grid>
        </Grid>
    )
}

export default FiaRegion
