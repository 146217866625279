import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';

interface IProps {
    animationData: any
    animationSizes: {
        width: number
        height: number
    }
}

const AnimationComponent = ({ animationData, animationSizes }: IProps) => {
    const [isPlaying, setIsPlaying] = useState(false);
    // const theme = useTheme();


    useEffect(() => {
        setIsPlaying(false);
        const timeout = setTimeout(() => {
            setIsPlaying(true);
        }, 0);

        return () => clearTimeout(timeout);
    }, [animationData]);

    return (
        <div>
            <Lottie
                loop
                play={isPlaying}
                animationData={animationData}
                style={{
                    ...animationSizes
                }}
            />
        </div>
    );
};

export default AnimationComponent;
