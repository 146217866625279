import * as React from "react"

function ArrowRightIcon(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <path
                d="M12.742 12L8.22 16.381a.993.993 0 00.155 1.4c.431.344 1.06.275 1.406-.154l5.023-5.004a.993.993 0 000-1.246L9.781 6.373a1.003 1.003 0 00-1.406-.154.993.993 0 00-.155 1.4L12.742 12z"
                fill="#F2F3F7"
            />
        </svg>
    )
}

export default ArrowRightIcon
