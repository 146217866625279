import * as React from "react"

function FuelRetailers() {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={40} height={40} rx={10} fill="#fff" fillOpacity={0.05} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 29v-9.63a1 1 0 00-.24-.65l-5.46-6.37a1 1 0 00-.76-.35H13a1 1 0 00-1 1v16a1 1 0 001 1h14a1 1 0 001-1zm-11.707-9.707a1 1 0 011.414 0L20 21.586l2.293-2.293a1 1 0 011.414 1.414L21.414 23l2.293 2.293a1 1 0 01-1.414 1.414L20 24.414l-2.293 2.293a1 1 0 01-1.414-1.414L18.586 23l-2.293-2.293a1 1 0 010-1.414z"
                fill="#DEE1E9"
            />
            <path
                d="M25.765 11.643a1 1 0 011.409.123l1.928 2.298a1 1 0 01-.123 1.409l-.766.643L25 12.286l.766-.643z"
                fill="#DEE1E9"
            />
            <rect x={14} y={10} width={6} height={3} rx={1} fill="#DEE1E9" />
        </svg>
    )
}

export default FuelRetailers
