import * as React from "react"

function WhiteLabelActive() {
    return (
        <svg
            width={124}
            height={124}
            viewBox="0 0 124 124"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1515_25249)">
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint0_radial_1515_25249)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint1_radial_1515_25249)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint2_radial_1515_25249)"
                />
                <circle
                    opacity={0.1}
                    cx={62}
                    cy={62}
                    r={62}
                    fill="url(#paint3_radial_1515_25249)"
                />
                <path
                    d="M25.78 33.858a6.899 6.899 0 014.885-8.445l24.849-6.634a6.899 6.899 0 018.444 4.886l13.357 50.03a6.899 6.899 0 01-4.886 8.445l-24.848 6.634a6.899 6.899 0 01-8.445-4.886L25.78 33.858z"
                    fill="#3A3B47"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M55.959 20.446L31.11 27.079a5.174 5.174 0 00-3.664 6.334l13.356 50.03a5.174 5.174 0 006.334 3.665l24.848-6.634a5.174 5.174 0 003.665-6.334L62.292 24.11a5.174 5.174 0 00-6.333-3.664zm-25.294 4.967a6.899 6.899 0 00-4.886 8.445l13.357 50.03a6.899 6.899 0 008.445 4.886l24.848-6.634a6.899 6.899 0 004.886-8.444l-13.357-50.03a6.899 6.899 0 00-8.444-4.887l-24.849 6.634z"
                    fill="#0B0B0D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M55.469 83.135a.287.287 0 01.203-.352l6.756-1.803a.288.288 0 01.148.555l-6.755 1.803a.287.287 0 01-.352-.203z"
                    fill="#0B0B0D"
                />
                <path
                    d="M35.373 24.157l15.434-4.121.053.197a4 4 0 01-2.833 4.896l-7.705 2.057a4 4 0 01-4.896-2.833l-.053-.197z"
                    fill="#0B0B0D"
                />
                <ellipse
                    cx={52.5855}
                    cy={54.3922}
                    rx={10.5096}
                    ry={10.5096}
                    transform="rotate(-14.948 52.586 54.392)"
                    fill="#76788A"
                />
                <path
                    d="M61.708 51.086c1.497 5.608-1.835 11.368-7.443 12.865-5.608 1.497-11.368-1.835-12.865-7.443-1.497-5.608 1.836-11.368 7.444-12.865 5.607-1.497 11.367 1.835 12.864 7.443z"
                    fill="#FDFCF8"
                />
                <path
                    d="M45.353 54.552a1.58 1.58 0 00-.818.525l-.36-1.351a.099.099 0 00-.121-.07l-.588.156a.099.099 0 00-.07.121l1.063 3.981a.099.099 0 00.121.07l.587-.156a.099.099 0 00.07-.121l-.037-.14a1.589 1.589 0 002.098-1.895 1.588 1.588 0 00-1.945-1.12zm.634 2.373a.872.872 0 11.619-1.067.872.872 0 01-.62 1.067zM50.782 55.701a.1.1 0 01.038.137c-.306.514-.8.762-1.178.862-1.1.294-1.884-.232-2.109-1.074-.233-.871.267-1.74 1.222-1.995.798-.213 1.706.116 2.045 1.214a.1.1 0 01-.07.125l-2.291.612c.116.364.53.545 1.08.398.276-.074.519-.275.678-.498a.1.1 0 01.128-.03l.457.25zm-2.464-.643l1.486-.397c-.16-.365-.585-.406-.875-.328-.277.073-.614.3-.611.725zM58.98 53.04a.562.562 0 01.697.404.555.555 0 01-.405.686.547.547 0 01-.688-.394.554.554 0 01.397-.696zm.141-.43l-.471.126a.09.09 0 01-.107-.056l-.867-2.465a.09.09 0 01.06-.116l.862-.23a.09.09 0 01.11.07l.477 2.569a.088.088 0 01-.064.102zM57.742 52.887a1.589 1.589 0 00-2.763-.597l-.037-.14a.099.099 0 00-.12-.07l-.588.157a.099.099 0 00-.07.121l1.092 4.088a.099.099 0 00.12.07l.588-.156a.099.099 0 00.07-.121l-.39-1.46c.3.115.637.138.97.049a1.586 1.586 0 001.128-1.941zm-.692.184a.872.872 0 01-1.688.451.873.873 0 011.688-.45zM54.25 54.776a.099.099 0 01.038.137c-.305.513-.799.761-1.177.862-1.1.294-1.884-.232-2.11-1.075-.232-.87.267-1.74 1.223-1.995.798-.213 1.705.116 2.045 1.214a.1.1 0 01-.07.126l-2.291.611c.115.365.53.545 1.079.398.276-.074.52-.274.679-.498a.1.1 0 01.128-.03l.457.25zm-2.464-.643l1.486-.397c-.159-.366-.585-.406-.875-.329-.277.074-.613.3-.611.726z"
                    fill="#0B0B0D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.968 62.84a9.36 9.36 0 10-4.828-18.086 9.36 9.36 0 004.828 18.086zm.297 1.11c5.608-1.496 8.94-7.256 7.443-12.864-1.497-5.608-7.257-8.94-12.864-7.443-5.608 1.497-8.94 7.257-7.444 12.865 1.497 5.608 7.257 8.94 12.865 7.443z"
                    fill="#0B0B0D"
                />
                <path
                    d="M59.187 38.664a6.899 6.899 0 018.45-4.879l24.842 6.657a6.899 6.899 0 014.878 8.45L83.954 98.908a6.897 6.897 0 01-8.449 4.878l-24.842-6.656a6.899 6.899 0 01-4.878-8.45l13.402-50.017z"
                    fill="#F2F3F7"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M92.032 42.108L67.19 35.45a5.174 5.174 0 00-6.337 3.659L47.451 89.128a5.174 5.174 0 003.658 6.337l24.843 6.657a5.175 5.175 0 006.337-3.659L95.69 48.445a5.174 5.174 0 00-3.659-6.337zm-24.396-8.322a6.899 6.899 0 00-8.449 4.878L45.785 88.682a6.899 6.899 0 004.878 8.449l24.842 6.656a6.897 6.897 0 008.45-4.878l13.402-50.018a6.899 6.899 0 00-4.878-8.449l-24.843-6.657z"
                    fill="#0B0B0D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M60.312 96.183a.287.287 0 01.352-.204l6.754 1.81a.287.287 0 01-.149.555l-6.753-1.81a.287.287 0 01-.204-.351z"
                    fill="#0B0B0D"
                />
                <path
                    d="M72.342 35.046l15.43 4.135-.053.197a4 4 0 01-4.899 2.828l-7.702-2.064a4 4 0 01-2.829-4.899l.053-.197z"
                    fill="#0B0B0D"
                />
                <ellipse
                    cx={72.1619}
                    cy={69.8374}
                    rx={10.5096}
                    ry={10.5096}
                    transform="rotate(15 72.162 69.837)"
                    fill="#A9ACC6"
                />
                <path
                    d="M81.718 71.527c-1.503 5.607-7.265 8.934-12.872 7.432-5.606-1.503-8.934-7.265-7.431-12.872 1.502-5.607 7.265-8.934 12.871-7.431 5.607 1.502 8.934 7.265 7.432 12.871z"
                    fill="#F7B500"
                />
                <path
                    d="M65.816 66.365a1.58 1.58 0 00-.97.047l.361-1.35a.099.099 0 00-.07-.122l-.587-.157a.099.099 0 00-.12.07l-1.067 3.98a.099.099 0 00.07.121l.587.158a.099.099 0 00.12-.07l.038-.14a1.589 1.589 0 002.764-.594 1.588 1.588 0 00-1.126-1.943zm-.636 2.374a.872.872 0 01-.618-1.068.872.872 0 011.069-.615.872.872 0 01.618 1.067.873.873 0 01-1.069.615zM69.946 70.072c.03.047.013.11-.035.138-.522.292-1.073.26-1.451.16-1.1-.295-1.517-1.143-1.291-1.985.233-.871 1.1-1.375 2.054-1.119.798.214 1.42.952 1.166 2.073a.1.1 0 01-.123.074l-2.29-.614c-.082.374.187.736.736.884.276.074.587.021.837-.093a.1.1 0 01.126.038l.271.444zm-1.814-1.788l1.486.399c.045-.396-.305-.644-.594-.722-.277-.075-.682-.047-.892.323zM78.38 71.859a.562.562 0 01.401.698.555.555 0 01-.693.391.547.547 0 01-.4-.684.554.554 0 01.692-.405zm.336-.303l-.47-.126a.09.09 0 01-.066-.102l.48-2.569a.09.09 0 01.11-.069l.861.23a.09.09 0 01.061.116l-.87 2.464a.088.088 0 01-.106.056zM77.381 71.108a1.589 1.589 0 00-2.096-1.897l.037-.14a.099.099 0 00-.07-.12l-.587-.158a.099.099 0 00-.12.07l-1.096 4.087a.099.099 0 00.07.122l.587.157a.099.099 0 00.121-.07l.39-1.459c.204.248.485.437.818.526a1.586 1.586 0 001.946-1.118zm-.692-.186a.872.872 0 01-1.688-.452.872.872 0 011.688.452zM73.415 71.001c.029.048.013.11-.036.138-.52.293-1.073.26-1.45.16-1.1-.295-1.517-1.143-1.292-1.985.234-.87 1.1-1.374 2.055-1.118.798.213 1.42.952 1.166 2.072a.1.1 0 01-.123.074l-2.29-.614c-.082.374.186.737.736.884.276.074.587.021.837-.093a.1.1 0 01.126.038l.27.444zM71.6 69.214l1.486.398c.045-.396-.305-.644-.594-.722-.277-.075-.68-.046-.892.324z"
                    fill="#0B0B0D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M69.144 77.848a9.36 9.36 0 104.845-18.082 9.36 9.36 0 00-4.845 18.082zm-.298 1.11c5.607 1.503 11.37-1.824 12.872-7.43 1.502-5.607-1.825-11.37-7.432-12.872-5.606-1.503-11.37 1.825-12.871 7.431-1.503 5.607 1.825 11.37 7.431 12.872z"
                    fill="#0B0B0D"
                />
            </g>
            <defs>
                <radialGradient
                    id="paint0_radial_1515_25249"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop stopColor="#F7B500" />
                    <stop offset={0.426666} stopColor="#F7B500" stopOpacity={0} />
                    <stop offset={1} stopColor="#F7B500" />
                </radialGradient>
                <radialGradient
                    id="paint1_radial_1515_25249"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop stopColor="#F7B500" />
                    <stop offset={0.426666} stopColor="#F7B500" stopOpacity={0} />
                    <stop offset={1} stopColor="#F7B500" />
                </radialGradient>
                <radialGradient
                    id="paint2_radial_1515_25249"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop stopColor="#F7B500" />
                    <stop offset={0.426666} stopColor="#F7B500" stopOpacity={0} />
                    <stop offset={1} stopColor="#F7B500" />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_1515_25249"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 62 -62 0 62 62)"
                >
                    <stop stopColor="#F7B500" />
                    <stop offset={0.426666} stopColor="#F7B500" stopOpacity={0} />
                    <stop offset={1} stopColor="#F7B500" />
                </radialGradient>
                <clipPath id="clip0_1515_25249">
                    <path fill="#fff" d="M0 0H124V124H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default WhiteLabelActive
