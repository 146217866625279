import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../constant/colors';

export const ButtonMenu = styled(Button)<ButtonProps>(({ theme }) => ({
    fontWeight: 700,
    fontSize: 14,
    color: COLORS.WHITE,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
        boxShadow: 'none',
        background: COLORS.MAIN_LIGHT1,
    },
    '&:active': {
        boxShadow: 'none',
        color: COLORS.BLACK,
        borderColor: COLORS.WARNING,
        backgroundColor: COLORS.WARNING
    },
    '&:focus': {
        color: COLORS.BLACK,
        borderColor: COLORS.WARNING,
        backgroundColor: COLORS.WARNING
    }
}));

