import * as React from "react"

function CarFinance() {
    return (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={40} height={40} rx={10} fill="#fff" fillOpacity={0.05} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 18H10v7a2 2 0 002 2h16a2 2 0 002-2v-7zm-2 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                fill="#DEE1E9"
            />
            <path d="M10 14a1 1 0 011-1h18a1 1 0 011 1v2H10v-2z" fill="#DEE1E9" />
        </svg>
    )
}

export default CarFinance
