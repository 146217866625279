import React, { useState } from 'react'
import { Box, Typography, Collapse, IconButton, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const data = [
    {
        title: 'Is Beep! available in all countries?',
        description: 'Yes, Beep! supports multiple countries and currencies, allowing for the rapid integration of local services to ensure swift deployment in various regions'
    },
    {
        title: 'Is Beep! GDPR compliant?',
        description: 'Beep! adheres to industry-standard data privacy practices, implementing robust physical, electronic, and procedural safeguards to protect user information, ensuring compliance with global data protection regulations'
    },
    {
        title: 'How long does it take to launch a white-label app with Beep!?',
        description: 'The timeline varies based on specific requirements; however, a standard white-label app built on the core Beep! platform can typically be launched within approximately three months'
    },
    {
        title: 'What customization options are available for a white-label app?',
        description: 'Beep! offers extensive customization options for white-label apps, including branding elements such as logos, color schemes, and app store descriptions, allowing businesses to tailor the app to their unique identity'
    },
    {
        title: 'What support does Beep! provide during the white-label app development process?',
        description: 'Beep! offers comprehensive support throughout the white-label app development process, including assistance with customization, integration, and deployment, ensuring a seamless experience from inception to launch'
    },
    {
        title: 'My Company already has a live app, do I have to close it?',
        description: 'No, you don`t have to close your existing app. Beep! can seamlessly integrate its platform into your current app, enabling you to enhance its features and functionality without disrupting your existing user base or operations'
    },
    {
        title: 'I want a Beep! white-label app for my company, how do I get it?',
        description: 'To get started with Beep!, arrange a demo with our team to explore the platform`s capabilities.From there, we`ll agree on the services to be included, finalize the commercial terms, and proceed with customization and integration to launch your app'
    },
    {
        title: 'What are the commercials?',
        description:
            `Our pricing model includes:
(i) a one- time customization and integration fee, and
(ii) an ongoing licence fee based on monthly active users(MAU) or a revenue share, depending on what best suits the white - label partner
(iii) monthly platform maintenance fee`
    },
]

const FAQSBlock = () => {
    const theme = useTheme();

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <Typography
                variant='h1'
                sx={{

                    letterSpacing: "-2.24px",
                    color: "grey.650",
                }}
            >
                FAQs
            </Typography>
            <Box
                sx={{
                    background: theme.palette.customColors.lightBg,
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "stretch",
                    borderRadius: 4,
                    mt: 16,
                }}>
                {data.map((faq, index) => (
                    <CollapseItem key={index} title={faq.title} description={faq.description} />
                ))}
            </Box>
        </Box>
    )
}

export default FAQSBlock



const CollapseItem: React.FC<{ title: string; description: string }> = ({ title, description }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const styles = {
        color: theme.palette.grey[500],
        fontWeight: 700,
    }

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: 6,
                    // padding: '24px'
                }}
                onClick={() => setOpen(!open)}
            >
                <Typography
                    variant='h5'
                    sx={{
                        textAlign: 'left', letterSpacing: "-0.02em",
                        ...styles
                    }}
                >
                    {title}
                </Typography>

                <IconButton>
                    {open ? <KeyboardArrowUpIcon color="secondary" /> : <KeyboardArrowDownIcon color="secondary" />}
                </IconButton>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Typography
                    variant='h6'
                    sx={{
                        textAlign: 'left', px: 6,
                        mb: 6,
                        whiteSpace: "pre-wrap",
                        ...styles,
                    }}
                >
                    {description}
                </Typography>
            </Collapse>
        </Box>
    );
};