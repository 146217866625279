import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography, Collapse, Paper, Grid, useTheme, Slider, useMediaQuery } from '@mui/material';
import { useSprings, animated, to as interpolate, } from 'react-spring';

import AcquireImg from './img/1.png';
import RetainImg from './img/2.png';
import EngageImg from './img/3.png';
import HowWeHelp from './img/HowWeHelp';
import AcquireIcon from './img/1_AcquireIcon';
import RetainIcon from './img/2_RetainIcon';
import EngageIcon from './img/3_EngageIcon';
import Acquire from './animations/1_Acquire.json'
import Retain from './animations/2_Retain.json'
import Engage from './animations/3_Engage.json'

import { styled } from '@mui/material/styles'
import { ButtonProps } from '@mui/material/Button'
import AnimationComponent from '../uknown/AnimationComponent';
import { useDrag } from '@use-gesture/react'
import ExclamationMarkIcon from '../../icons/ExclamationMarkIcon';

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing(1.5),
    ml: theme.spacing(1),
    textAlign: 'left',
    textTransform: 'none',
    color: theme.palette.text.secondary,
    padding: theme.spacing(4),
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.grey[200]
    }
}))

const items = [
    {
        title: 'Acquire Customers',
        description: 'Attract new customers to your business by becoming a leader in digital car management',
        image: AcquireImg,
        icon: AcquireIcon,
        animation: Acquire
    },
    {
        title: 'Engage Customers',
        description: 'Use personalised data-driven insights, advice, and incentives to boost customer engagement and increase cross-selling and up-selling opportunities',
        image: EngageImg,
        icon: EngageIcon,
        animation: Engage
    },
    {
        title: 'Retain Customers',
        description: 'Foster long-term loyalty and reduce attrition among your customers by engaging them in helping them manage and reduce the cost of car ownership',
        image: RetainImg,
        icon: RetainIcon,
        animation: Retain
    },
];



const to = (i: number, activeIndex: number, length: number) => ({
    y: i < activeIndex ? -100 * (activeIndex - i) : 20 * (i - activeIndex),
    scale: i === activeIndex ? 1 : 0.8,
    opacity: i === activeIndex ? 1 : 0,
    zIndex: (i + length - activeIndex) % length,
    config: { mass: 1, tension: 170, friction: 26 },
});

const from = (_i: number) => ({ y: 1000, scale: 1.5, opacity: 0, zIndex: 0 });

const trans = (y: number, scale: number) => `translateY(${y}px) scale(${scale})`;

const HowWeHelpBlock = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [progress, setProgress] = useState<Record<number, number>>({});
    const [isPaused, setIsPaused] = useState(false);

    const [expanded, setExpanded] = useState<number | null>(0);
    const [activeSlide, setActiveSlide] = useState(0);
    const [springs, api] = useSprings(items.length, i => ({
        ...to(i, activeSlide, items.length),
        rom: from(i),
    }));

    const handleNextSlide = useCallback(() => {
        setActiveSlide((prev) => {
            const nextSlide = (prev + 1) % items.length;
            api.start(i => to(i, nextSlide, items.length));
            setExpanded(nextSlide);
            return nextSlide;
        });
    }, [api]);

    const handlePrevSlide = useCallback(() => {
        setActiveSlide((prev) => {
            const prevSlide = (prev - 1 + items.length) % items.length;
            api.start(i => to(i, prevSlide, items.length));
            return prevSlide;
        });
    }, [api]);

    useEffect(() => {
        if (isPaused) return;

        const interval = setInterval(() => {
            handleNextSlide();
        }, 5000);

        return () => clearInterval(interval);
    }, [activeSlide, isPaused, handleNextSlide, handlePrevSlide]);

    const bind = useDrag(({ down, movement: [, my], direction: [, yDir], velocity: [vx, vy] }) => {
        const velocity = Math.abs(vy);
        if (!down && velocity > 0.1) {
            if (yDir > 0) {
                handlePrevSlide();
            } else {
                handleNextSlide();
            }
        }
    });

    useEffect(() => {
        if (expanded !== null) {
            setProgress((prev) => ({ ...prev, [expanded]: 0 }));

            const interval = setInterval(() => {
                setProgress((prev) => {
                    const currentProgress = prev[expanded] || 0;
                    const nextProgress = currentProgress + 2;

                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return { ...prev, [expanded]: 100 };
                    }

                    return { ...prev, [expanded]: nextProgress };
                });
            }, 100);

            return () => clearInterval(interval);
        }
    }, [expanded]);

    const handleExpand = (index: number) => {
        setExpanded(expanded === index ? null : index);
        setActiveSlide(index);
        api.start(i => to(i, index, items.length));
    };

    const getSize = () => {
        if (isMobile) return { width: 260, height: 340 };
        return { width: 480, height: 640 };
    };

    const handleMouseEnter = () => {
        api.pause();
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        api.resume();
        setIsPaused(false);
    };

    const animationSizes = getSize();

    return (
        <Grid
            container
            spacing={isMobile ? 6 : 22}
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                justifyContent: { xs: 'center', lg: 'center' },
                py: 16,
            }}
        >
            {isTablet && (
                <Box sx={{
                    width: '100%',
                    maxWidth: {
                        xs: `calc(100% - ${theme.spacing(16)})`,
                        sm: `calc(70% - ${theme.spacing(16)})`,
                    }
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                        <HowWeHelp />
                    </Box>
                    <Description />
                </Box>
            )}

            <Grid
                item
                xs={12}
                md={5}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        minWidth: { xs: '212px', sm: '496px' },
                        minHeight: { xs: '332px', sm: '660px' },
                        backgroundColor: 'grey.200',
                        borderRadius: '32px',
                        position: 'relative',
                    }}
                >
                    {springs.map((props, i) => {
                        const { y, scale, opacity } = props;

                        return (
                            <animated.div
                                key={i}
                                {...bind()}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    // minWidth: '212px',
                                    // minHeight: '332px',
                                    height: '100%',
                                    willChange: 'transform, opacity',
                                    transform: interpolate([y, scale], trans),
                                    opacity,
                                    // zIndex,
                                }}
                            >
                                <Box
                                    component="img"
                                    src={items[i].image}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '12px',
                                    }}
                                />
                            </animated.div>
                        )
                    })}

                    <Box
                        sx={{
                            position: 'absolute',
                            top: { xs: -8, sm: 15 },
                            left: { xs: -15, sm: 10 },
                            zIndex: 100,
                            backgroundColor: 'transparent',
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <AnimationComponent
                            key={activeSlide}
                            animationData={items[activeSlide].animation}
                            animationSizes={animationSizes}
                        />
                    </Box>


                    <Box
                        sx={{
                            position: 'absolute',
                            top: '100%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: { xs: '182px', sm: '416px' },
                            height: '8px',
                            borderRadius: '0px 0px 16px 16px',
                            backgroundColor: 'grey.500',
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 'calc(100% + 8px)',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: {
                                xs: '142px', sm: '352px'
                            },
                            height: '8px',
                            borderRadius: '0px 0px 16px 16px',
                            backgroundColor: 'grey.400',
                        }}
                    />
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                md={6}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    // ml: { xs: 0, md: 6 },
                    minHeight: '100%',
                    maxWidth: {
                        xs: '100%',
                        // sm: '660px'
                    },
                }}
            >
                {!isTablet && (
                    <ContentBlock theme={theme}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="h1" sx={{ fontWeight: 700, textAlign: 'left', color: 'secondary.main' }}>
                                {/* How Beep can help you */}
                                How we help
                            </Typography>
                            {' '}
                            <ExclamationMarkIcon />
                        </Box>
                        <Description />
                    </ContentBlock>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // alignItems: 'center',
                        width: '100%',
                        minHeight: '420px',
                        gap: 12,
                        mt: 10,
                    }}
                >

                    {items.map((item, index) => (
                        <ExpandableItem
                            key={index}
                            item={item}
                            index={index}
                            expanded={activeSlide}
                            handleExpand={handleExpand}
                            progress={progress}
                        />
                    ))}
                </Box>

            </Grid>
        </Grid>
    );
};


const ContentBlock: React.FC<any> = ({ children, theme }) => (
    <Box sx={{
        width: '100%',
        // margin: '0 auto', minWidth: '620px'
    }}>{children}</Box>
);

const Description = () => {
    return (
        <Typography
            variant="h6"
            sx={{
                textAlign: 'left',
                width: { xs: '100%', md: '68%' },
                mt: 8,
            }}
        >
            We empower car services providers to acquire, engage and retain car driving customers
        </Typography>
    );
};

const ExpandableItem: React.FC<{
    item: any;
    index: number;
    expanded: number | null;
    handleExpand: (index: number) => void;
    progress: Record<number, number>;
}> = ({ item, index, expanded, handleExpand, progress }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                maxWidth: '480px',
                transition: theme.transitions.create('min-height', {
                    duration: theme.transitions.duration.standard,
                    easing: theme.transitions.easing.easeInOut,
                }),
            }}
        >
            {expanded !== index && (
                <CustomButton onClick={() => handleExpand(index)} startIcon={<item.icon />}>
                    <Typography variant="h5" sx={{ textAlign: 'left', color: 'secondary.main', whiteSpace: 'nowrap' }}>
                        {item.title}
                    </Typography>
                </CustomButton>
            )}

            <Collapse
                easing='ease-in-out'
                in={expanded === index} timeout="auto" unmountOnExit
            >
                <Paper
                    sx={{
                        position: 'relative',
                        padding: theme.spacing(6),
                        gap: theme.spacing(3),
                        borderRadius: '16px',
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: 'none',
                    }}
                >
                    <Button
                        onClick={() => handleExpand(index)}
                        sx={{
                            display: 'flex',
                            gap: theme.spacing(1.5),
                            color: theme.palette.text.secondary,
                            textTransform: 'none',
                            textAlign: 'left',
                            '&:hover': { backgroundColor: 'transparent' },
                        }}
                        startIcon={<item.icon />}
                    >
                        <Typography variant="h5" sx={{ textAlign: 'left', color: 'secondary.main', whiteSpace: 'nowrap' }}>
                            {item.title}
                        </Typography>
                    </Button>

                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: 'left',
                            fontWeight: 600,
                            width: '90%',
                        }}
                    >
                        {item.description}
                    </Typography>

                    <Slider
                        orientation="vertical"
                        track="inverted"
                        value={progress[index] || 100}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 12,
                            height: '70%',
                            my: theme.spacing(6),
                            transform: 'rotate(180deg)',
                            opacity: progress[index] <= 4 ? 0 : 1,
                            '& .MuiSlider-thumb': { display: 'none' },
                            '& .MuiSlider-rail': { backgroundColor: theme.palette.grey[200] },
                            '& .MuiSlider-track': { backgroundColor: theme.palette.primary.main },
                        }}
                    />
                </Paper>
            </Collapse>
        </Box>
    );
};

export default HowWeHelpBlock;
