import React from 'react';
import { Box, Button, useTheme } from '@mui/material';

import { styled } from '@mui/material/styles'
import { ButtonProps } from '@mui/material/Button'

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    backgroundColor: 'transparent',
    textTransform: 'none',
    color: theme.palette.text.primary,
    padding: '12px 24px',
    borderRadius: '12px',
    boxShadow: 'none',
    fontWeight: 700,
    transition: 'all 0.3s ease',
    minWidth: '117px',
    [theme.breakpoints.down('sm')]: {
        padding: '10px 10px',
    },
    '&:hover': {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.grey[700],
    }
}))

interface ButtonGroupProps {
    setActiveApp: React.Dispatch<React.SetStateAction<'clients' | 'partners' | 'admin'>>;
    activeApp: 'clients' | 'partners' | 'admin';
}

const ButtonGroupComponent: React.FC<ButtonGroupProps> = ({ activeApp, setActiveApp }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                gap: { xs: '1px', sm: '2px' },
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#3A3E49',
                borderRadius: '16px',
                p: 1
            }}
        >
            <CustomButton
                variant="contained"
                onClick={() => setActiveApp('clients')}
                sx={{
                    backgroundColor: activeApp === 'clients' ? theme.palette.warning.main : 'transparent',
                    color: activeApp === 'clients' ? theme.palette.grey[700] : theme.palette.text.primary,
                    fontSize: '14px',
                }}
            >
                Driver App
            </CustomButton>

            <CustomButton
                variant="contained"
                onClick={() => setActiveApp('partners')}
                sx={{
                    backgroundColor: activeApp === 'partners' ? theme.palette.warning.main : 'transparent',
                    color: activeApp === 'partners' ? theme.palette.grey[700] : theme.palette.text.primary,
                    fontSize: '14px',
                }}
            >
                Partner App
            </CustomButton>

            <CustomButton
                variant="contained"
                onClick={() => setActiveApp('admin')}
                sx={{
                    backgroundColor: activeApp === 'admin' ? theme.palette.warning.main : 'transparent',
                    color: activeApp === 'admin' ? theme.palette.grey[700] : theme.palette.text.primary,
                    fontSize: '14px',
                }}
            >
                Admin Panel
            </CustomButton>
        </Box>
    );
};

export default ButtonGroupComponent;
