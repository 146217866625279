import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

interface IconWithTextProps {
    text: string;
    secondText?: string;
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';
    color?: string;
    align?: 'left' | 'center' | 'right';
    iconSize?: 'small' | 'medium' | 'large';
    style?: any
    textStyle?: React.CSSProperties;
}

export const ExclamationMarkIcon: React.FC<{ size: 'small' | 'medium' | 'large' }> = ({ size }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sizes = {
        small: { width: isMobile ? 4 : 5, height: isMobile ? 14 : 18 },
        medium: { width: isMobile ? 5 : 7, height: isMobile ? 20 : 26 },
        large: { width: isMobile ? 8 : 12, height: isMobile ? 36 : 48 },
    };

    const { width, height } = sizes[size];

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 7 26" fill="none">
            <path d="M3.33226 19.0173C5.2092 19.0173 6.69695 20.4766 6.69695 22.3928C6.69695 24.2725 5.2092 25.6915 3.33226 25.6915C1.41885 25.6915 0 24.2725 0 22.3928C0 20.4766 1.41885 19.0173 3.33226 19.0173ZM4.79165 16.7737H1.90531C1.62965 16.7737 1.40263 16.5635 1.37831 16.2886L0.182428 0.874541C0.158105 0.571354 0.401331 0.308594 0.709423 0.308594H5.98348C6.29158 0.308594 6.53075 0.567311 6.51048 0.874541L5.3146 16.2886C5.29433 16.5635 5.06731 16.7737 4.79165 16.7737Z" fill="#F7B500" />
        </svg>
    );
};
interface ExclamationMarkProps {
    size: 'small' | 'medium' | 'large';
    fontSize: number;
}
export const ExclamationMark: React.FC<ExclamationMarkProps> = ({ size, fontSize }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const baseHeight = fontSize

    const sizeAdjustments = {
        small: 0.6,
        medium: 0.8,
        large: 1.0,
    };

    const height = baseHeight * sizeAdjustments[size];
    const aspectRatio = 7 / 26;
    const width = height * aspectRatio;

    const adjustedHeight = isMobile ? height * 0.9 : height;
    const adjustedWidth = isMobile ? width * 0.9 : width;


    const finalHeight = Math.max(adjustedHeight, 10);
    const finalWidth = Math.max(adjustedWidth, 3);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={finalWidth}
            height={finalHeight}
            viewBox="0 0 7 26"
            fill="none"
            style={{
                margin: '0 4px',
                display: 'inline-block',
            }}
        >
            <path
                d="M3.33226 19.0173C5.2092 19.0173 6.69695 20.4766 6.69695 22.3928C6.69695 24.2725 5.2092 25.6915 3.33226 25.6915C1.41885 25.6915 0 24.2725 0 22.3928C0 20.4766 1.41885 19.0173 3.33226 19.0173ZM4.79165 16.7737H1.90531C1.62965 16.7737 1.40263 16.5635 1.37831 16.2886L0.182428 0.874541C0.158105 0.571354 0.401331 0.308594 0.709423 0.308594H5.98348C6.29158 0.308594 6.53075 0.567311 6.51048 0.874541L5.3146 16.2886C5.29433 16.5635 5.06731 16.7737 4.79165 16.7737Z"
                fill="#F7B500"
            />
        </svg>
    );
};


const IconWithText: React.FC<IconWithTextProps> = ({ text, secondText, variant = 'h1', color = 'text.primary', align = 'left', iconSize, style, textStyle }) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const effectiveVariant = variant === 'h1' || variant === 'h2' || variant === 'h4' || variant === 'body1' || variant === 'body2'
    //     ? 'h3'
    //     : variant;

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isTablet ? 'center' : align,
            gap: { xs: 1, sm: 2 },
            width: '100%',
            maxWidth: '90%',
            ...style
        }}>
            <Typography
                variant={variant}
                sx={{
                    whiteSpace: 'nowrap',
                    fontWeight: 700, color,
                    textAlign: isTablet ? 'center' : align,
                    ...textStyle
                }}
            >
                {text}
            </Typography>

            {!!iconSize &&
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ExclamationMarkIcon size={isTablet ? 'medium' : iconSize} />
                    {/* <ExclamationMark size={isTablet ? 'medium' : iconSize} variant={effectiveVariant as 'h6' | 'h5' | 'h3'} /> */}
                </Box>
            }

            {!!secondText &&
                <Typography
                    variant={variant}
                    sx={{
                        fontWeight: 700,
                        color,
                        textAlign: isMobile ? 'center' : align,
                        ...textStyle,
                    }}>
                    {secondText}
                </Typography>
            }
        </Box>
    );
};

export default IconWithText;
