import React, { useRef, useState, useLayoutEffect } from "react";
import { Box, Typography, Container, useTheme, Grid, useMediaQuery } from "@mui/material";
import { motion, useScroll, useTransform } from "framer-motion";

// import { Parallax } from "react-scroll-parallax";
// import { useInView } from "react-intersection-observer";
import mobileimg from './img/mobileimg.png'
import Dealerships from './img/1_Dealerships';
import Autoclubs from './img/2_Autoclubs';
import Carservice from './img/3_Carservice';
import FuelRetailers from './img/4_FuelRetailers';
import Others from './img/5_Others';
import Insurers from './img/6_Insurers';
import CarFinance from './img/7_CarFinance';
import Assistance from './img/8_Assistance';
import IconWithText from '../uknown/IconWithText';
import Banks from './img/6_Banks';

const leftSide = [
    {
        title: 'Car Dealerships',
        icon: <Dealerships />,
    },
    {
        title: 'Car Service Networks',
        icon: <Carservice />,
    },
    {
        title: 'Fuel Retailers',
        icon: <FuelRetailers />,
    },
    {
        title: 'Car Finance Providers',
        icon: <CarFinance />,
    },
    {
        title: 'Roadside Assistance Providers',
        icon: <Assistance />,
    },
    {
        title: 'Auto Clubs',
        icon: <Autoclubs />,
    },
    {
        title: 'Car Insurers',
        icon: <Insurers />,
    },
    {
        title: 'Banks',
        icon: <Banks />,
    },
    {
        title: 'Other car-related businesses',
        icon: <Others />,
    },
];

const rightSide = [
    {
        primaryTitle: 'If you do have an app',
        secondaryTitle: 'Beep! White Label Platform',
        description: 'We offer an all-in-one solution that includes a core platform along with two mobile apps – one for customers and another for service providers',
    },
    {
        primaryTitle: 'If you have an app',
        secondaryTitle: 'Beep! Embedded Platform',
        description: 'We offer a core platform with a web widget that allows you to effortlessly display features within your existing app, eliminating the need for integration',
    },
    {
        primaryTitle: 'If you need bespoke development',
        secondaryTitle: 'Beep! App Development Services',
        description: 'We can develop a tailored solution that meets your specific requirements, providing the necessary flexibility and scalability to suit your unique needs',
    },
]

interface ScrollAnimatedItemProps {
    children: React.ReactNode;
    offsetStart?: number;
    offsetEnd?: number;
    initialY?: number;
    finalY?: number;
    initialOpacity?: number;
    finalOpacity?: number;
}

const ScrollAnimatedItem: React.FC<ScrollAnimatedItemProps> = ({
    children,
    offsetStart = 0,
    offsetEnd = 0,
    initialY = 50,
    finalY = 0,
    initialOpacity = 0,
    finalOpacity = 1,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { scrollY } = useScroll();
    const [elementTop, setElementTop] = useState<number | null>(null);
    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

    useLayoutEffect(() => {
        const updatePosition = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const scrollTop = window.scrollY;
                setElementTop(rect.top + scrollTop);
                setWindowHeight(window.innerHeight);
            }
        };

        updatePosition();
        window.addEventListener("resize", updatePosition);
        return () => window.removeEventListener("resize", updatePosition);
    }, []);

    const safeElementTop = elementTop ?? 0;
    const animationStart = safeElementTop - windowHeight + offsetStart;
    const animationEnd = safeElementTop - windowHeight + offsetEnd;

    const yRange = useTransform(
        scrollY,
        [animationStart, animationEnd],
        [initialY, finalY],
    );

    const opacity = useTransform(
        scrollY,
        [animationStart, animationEnd],
        [initialOpacity, finalOpacity],
    );

    return (
        <motion.div ref={ref} style={{ y: yRange, opacity }}>
            {children}
        </motion.div>
    );
};

const HeroMainSection: React.FC = () => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container sx={{
            pb: 40,
            pt: isTablet ? 60 : 0,
        }}>
            {isTablet ? (
                <Grid container spacing={12} alignItems="center">

                    <Grid item xs={12}>
                        <Box component="img" src={mobileimg} sx={{ width: "100%", maxWidth: "372px" }} />
                    </Grid>

                    <Grid item xs={12} md={4} alignItems="center">
                        <IconWithText text="Our Solutions" variant="h3" align="center" iconSize="medium" />

                        {rightSide.map((solution, index: number) => (
                            <Box key={index} sx={{ mt: 12, mb: 6, px: 12 }}>
                                <Typography variant="h4" color="grey.100" sx={{ fontSize: "12px", fontWeight: 700, mb: 2 }}>
                                    {solution.primaryTitle}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: "12px", fontWeight: 700 }}>
                                    {solution.secondaryTitle}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 700 }}>
                                    {solution.description}
                                </Typography>
                            </Box>
                        ))}
                    </Grid>

                    <Grid item xs={12}>
                        <IconWithText text="Who we help" variant="h3" align="center" iconSize="medium" />

                        {leftSide.map((item, index: number) => (
                            <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 4, mt: 12, mb: 4, px: 12 }}>
                                {item.icon}
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    {item.title}
                                </Typography>
                            </Box>
                        ))}

                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container
                    spacing={4}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginTop: 8 }}
                >
                    <Grid item xs={12} md={4}>
                        <ScrollAnimatedItem
                            offsetStart={0}
                            offsetEnd={500}
                            initialY={60}
                            finalY={5}
                            initialOpacity={0}
                            finalOpacity={1}
                        >
                            <IconWithText text="Who we help" variant="h3" iconSize="medium" />
                        </ScrollAnimatedItem>

                        {leftSide.map((item, index) => (
                            <ScrollAnimatedItem
                                key={index}
                                offsetStart={index}
                                offsetEnd={200 + index * 30}
                                initialY={150}
                                finalY={40}
                                initialOpacity={0}
                                finalOpacity={1}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", gap: 4, mb: 4 }}>
                                    {item.icon}
                                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                        {item.title}
                                    </Typography>
                                </Box>
                            </ScrollAnimatedItem>
                        ))}
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <ScrollAnimatedItem
                            offsetStart={0}
                            offsetEnd={900}
                            initialY={100}
                            finalY={50}
                            initialOpacity={0.3}
                            finalOpacity={1}
                        >
                            <Box
                                component="img"
                                src={mobileimg}
                                sx={{ width: "100%", maxWidth: "372px", height: "auto" }}
                            />
                        </ScrollAnimatedItem>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <ScrollAnimatedItem
                            offsetStart={0}
                            offsetEnd={200}
                            initialY={50}
                            finalY={0}
                            initialOpacity={0}
                            finalOpacity={1}
                        >
                            <IconWithText text="Our Solutions" variant="h3" iconSize="medium" />
                        </ScrollAnimatedItem>

                        {rightSide.map((solution, index) => (
                            <ScrollAnimatedItem
                                key={index}
                                offsetStart={index * 50}
                                offsetEnd={300 + index * 50}
                                initialY={150}
                                finalY={20}
                                initialOpacity={0}
                                finalOpacity={1}
                            >
                                <Box sx={{ mb: 12 }}>
                                    <Typography color="grey.100" sx={{ fontSize: "24px", fontWeight: 700, mb: 2 }}>
                                        {solution.primaryTitle}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontSize: "12px", fontWeight: 700 }}>
                                        {solution.secondaryTitle}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: 700, pr: 14 }}>
                                        {solution.description}
                                    </Typography>
                                </Box>
                            </ScrollAnimatedItem>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default HeroMainSection;
