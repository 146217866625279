import { styled, useTheme } from '@mui/material/styles'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import MuiToolbar, { ToolbarProps } from '@mui/material/Toolbar'

interface Props {
    children: React.ReactNode
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    width: '100vw',
    left: '0',
    right: '0',
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[600],
    borderRadius: theme.spacing(5),
    minWidth: '320px',
    [theme.breakpoints.down('sm')]: {
        maxWidth: `calc(100% - ${theme.spacing(8)})`,
    },
    [theme.breakpoints.between('sm', 'xl')]: {
        maxWidth: `calc(100% - ${theme.spacing(12)})`,
    },
}))

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
    width: '100%',
}))

const LayoutAppBar = (props: Props) => {
    const theme = useTheme()
    const { children } = props

    return (
        <AppBar
            elevation={0}
            color='default'
            sx={{
                mt: theme.spacing(4),
                maxWidth: theme.breakpoints.values.xl,
            }}
            position={'fixed'}
        >
            <Toolbar>
                {children}
            </Toolbar>
        </AppBar>
    )
}

export default LayoutAppBar
