import * as React from "react"

function CommentIcon() {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_844_3665)">
                <path
                    d="M13.162 1.202c-2.524-.27-8.3-.27-10.824 0C1.308 1.322.322 2.31.202 3.337a35.55 35.55 0 000 7.575c.12 1.029 1.106 2.015 2.135 2.135.837.09 2.03.15 3.345.179.451.773.931 1.57 1.391 2.367a.767.767 0 001.354 0c.46-.798.94-1.594 1.39-2.367 1.315-.03 2.508-.09 3.345-.18 1.029-.118 2.016-1.105 2.135-2.134.27-2.525.27-5.05 0-7.575-.12-1.029-1.106-2.015-2.135-2.135zm-4.21 8.589H3.996a.727.727 0 110-1.453h4.956a.727.727 0 110 1.453zm2.552-3.276H3.996a.727.727 0 110-1.453h7.508a.727.727 0 110 1.453z"
                    fill="#868C99"
                />
            </g>
            <defs>
                <clipPath id="clip0_844_3665">
                    <path fill="#fff" d="M0 0H16V16H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CommentIcon
