const breakpoints = () => ({
    values: {
        xs: 0,
        sm: 600,
        md: 1100,
        lg: 1280,
        xl: 1536,
    },
});

export default breakpoints;
