import React from 'react'
import { Typography, Container, useTheme, Box } from '@mui/material';
import TheBeepPlatformIcon from './img/TheBeepPlatformIcon';
import ButtonGroupComponent from '../uknown/ButtonsGroup';
import Carousel from './Carousel';

const TheBeepPlatform = () => {
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [activeApp, setActiveApp] = React.useState<'clients' | 'partners' | 'admin'>('clients');

    return (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',

            }}>
            <Container
                maxWidth={"xl"}
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        px: { xs: '16px' },
                        maxWidth: theme.breakpoints.values.lg,
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: { xs: 'start', sm: 'center' },
                        width: { xs: '80%', sm: '100%' },
                        mb: { xs: '8px', sm: '24px', },
                    }}>
                        <TheBeepPlatformIcon />
                    </Box>

                    <Typography
                        variant='h6'
                        sx={{
                            textAlign: { xs: 'start', sm: 'center' },
                            mb: { xs: '24px', sm: '24px', md: '48px' },
                            fontWeight: 600,
                            fontSize: { xs: '14px', sm: '16px', },
                        }}
                    >
                        Making car ownership simpler, cheaper and more enjoyable
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center !important',
                        width: '100%'
                    }}>
                        <ButtonGroupComponent setActiveApp={setActiveApp} activeApp={activeApp} />
                    </Box>
                </Box>

            </Container>

            <Carousel activeApp={activeApp} />
        </Box>
    )
}

export default TheBeepPlatform
