
import * as React from "react"

function PersonIcon() {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M8 7.158A3.579 3.579 0 108 0a3.579 3.579 0 000 7.158zM8 8a6.316 6.316 0 00-6.315 6.316c0 .93.754 1.684 1.684 1.684h9.263c.93 0 1.684-.754 1.684-1.684A6.316 6.316 0 008 8z"
                fill="#868C99"
            />
        </svg>
    )
}

export default PersonIcon
