import * as React from "react"

function InstagramIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16s6.268 14 14 14zM9 12.5A3.51 3.51 0 0112.5 9h7a3.51 3.51 0 013.5 3.5v7a3.51 3.51 0 01-3.5 3.5h-7A3.51 3.51 0 019 19.5v-7zm4.083 3.5a2.917 2.917 0 105.834 0 2.917 2.917 0 00-5.834 0zm5.834-3.792a.875.875 0 101.75 0 .875.875 0 00-1.75 0z"
                fill="#F2F3F7"
            />
        </svg>
    )
}

export default InstagramIcon
