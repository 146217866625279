import { ReactNode } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'
import { Theme, ThemeOptions } from '@mui/material'
import typography from './typography'
import breakpoints from './breakpoints'
import DefaultPalette from './palette'
// import spacing from './spacing'

interface Props {
  children: ReactNode
}

export const remToPixels = (rem: string): number => {
  const numericValue = parseFloat(rem);
  return numericValue * 16;
};

export const calculateSpacingFactor = (pixels: number): number => {
  const baseValue = 4; // 0.25rem = 4px
  return pixels / baseValue;
};

const ThemeComponent = (props: Props) => {
  const { children } = props

  const coreThemeConfig: ThemeOptions = {
    palette: {
      ...DefaultPalette(),
      customSpacing: {
        xs: '16px',
        sm: '24px',
        md: '32px',
        lg: '64px',
        xl: '80px',
      },
    },
    typography: {
      fontFamily: 'Manrope, sans-serif',
    },
    spacing: (factor: number) => `${0.25 * factor}rem`,
    breakpoints: breakpoints(),

    shape: {
      borderRadius: 5,
    },
    mixins: {
      toolbar: {
        minHeight: 64,
      },
    },
  }

  let theme = createTheme(coreThemeConfig)

  theme = createTheme(theme, {
    typography: { ...typography(theme) },
    components: {
      ...input(theme),
      ...Button(theme),
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            '@media (min-width:600px)': {
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        },
      },
    },
  })

  theme = responsiveFontSizes(theme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeComponent



const input = (theme: Theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          color: theme.palette.grey[400],
          textAlign: 'center',

        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          lineHeight: '1.5rem',
          fontSize: 26,
          pr: '16px',
          pl: '16px',
          // '&:before': {
          //   borderBottom: `1px solid rgba(${theme.palette.grey[600]}, 0.22)`
          // },
          // '&:hover:not(.Mui-disabled):before': {
          //   borderBottom: `1px solid rgba(${theme.palette.grey[600]}, 0.32)`
          // },
          // '&.Mui-disabled:before': {
          //   borderBottomStyle: 'solid'
          // }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          // backgroundColor: `rgba(${theme.palette.grey[600]}, 0.05)`,
          backgroundColor: theme.palette.grey[600],
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: `rgba(${theme.palette.grey[600]}, 0.08)`
          },
          '&:before': {
            borderBottom: `1px solid rgba(${theme.palette.grey[600]}, 0.22)`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid rgba(${theme.palette.grey[600]}, 0.32)`
          },
          '&.Mui-disabled': {
            // backgroundColor: `rgba(${theme.palette.grey[600]}, 0.05)`,
            backgroundColor: theme.palette.grey[600],
            '&:before': {
              borderBottomStyle: 'solid'
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          paddingLeft: '16px',
          backgroundColor: theme.palette.grey[600],
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          border: `1.2px solid ${theme.palette.grey[600]}`,

          '& .MuiInputBase-input': {
            borderRadius: '16px',
            paddingLeft: '16px',
            backgroundColor: theme.palette.grey[600],
          },

          '&:hover': {
            borderRadius: '16px',
            backgroundColor: theme.palette.grey[500],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[500],
          },

          '&.Mui-focused': {
            borderRadius: '16px',
            backgroundColor: theme.palette.grey[500],
          },

          '&.Mui-error': {
            borderRadius: '16px',
            backgroundColor: theme.palette.grey[600],
            color: theme.palette.error.main,
          },

          '&.MuiFilled': {
            color: theme.palette.grey[600],
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-hovered .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.warning.main}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.warning.main}`,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.error.main}`,
          },
          // '&:-webkit-autofill': {
          //   // backgroundColor: theme.palette.grey[600],
          //   // boxShadow: `0 0 0px 1000px ${theme.palette.grey[600]} inset`,
          //   // WebkitTextFillColor: theme.palette.text.primary, // Цвет текста
          //   // transition: 'background-color 5000s ease-in-out 0s',
          // },
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: 8,
    //       // backgroundColor: `rgba(${theme.palette.grey[600]}, 0.08)`,

    //       '&:hover:not(.Mui-disabled)': {
    //         backgroundColor: theme.palette.grey[500]
    //       },
    //       '&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
    //         // backgroundColor: theme.palette.grey[600],
    //         backgroundColor: theme.palette.grey[500]

    //         // borderColor: `rgba(${theme.palette.grey[600]}, 0.32)`
    //       },
    //       // '&:hover:not(.Mui-disabled):before': {
    //       //   borderBottom: `1px solid rgba(${theme.palette.grey[600]}, 0.32)`
    //       // },
    //       '&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
    //         // backgroundColor: theme.palette.grey[600],

    //         borderColor: theme.palette.error.main
    //       },
    //       '& .MuiOutlinedInput-notchedOutline': {
    //         // backgroundColor: theme.palette.grey[600],

    //         borderColor: `rgba(${theme.palette.customColors.main}, 0.22)`
    //       },
    //       '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    //         // backgroundColor: theme.palette.grey[600],

    //         borderColor: theme.palette.text.disabled,
    //         // backgroundColor: `rgba(${theme.palette.grey[600]}, 0.08)`
    //       },
    //       '&.Mui-disabled': {
    //         // backgroundColor: `rgba(${theme.palette.grey[600]}, 0.05)`,
    //         backgroundColor: theme.palette.grey[600],
    //         '&:before': {
    //           borderBottomStyle: 'solid'
    //         }
    //       }
    //     },
    //   }
    // }
  }
}

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: 8,
          lineHeight: 1.715,
          fontSize: '1rem',
          // padding: `${theme.spacing(1.75, 3)}`,
          // '&.MuiButton-textPrimary:hover': {
          //   backgroundColor: theme.palette.primary.main
          // },
          // '&.MuiButton-textSecondary:hover': {
          //   backgroundColor: theme.palette.secondary.main
          // },
          // '&.MuiButton-textSuccess:hover': {
          //   backgroundColor: theme.palette.success.main
          // },
          // '&.MuiButton-textError:hover': {
          //   backgroundColor: theme.palette.error.main
          // },
          // '&.MuiButton-textWarning:hover': {
          //   backgroundColor: theme.palette.warning.main
          // },
          // '&.MuiButton-textInfo:hover': {
          //   backgroundColor: theme.palette.info.main
          // }
        },
        contained: {
          boxShadow: theme.shadows[3],
          padding: `${theme.spacing(1.75, 5.5)}`
        },
        outlined: {
          lineHeight: 1.572,
          padding: `${theme.spacing(1.75, 5.25)}`,
          '&.MuiButton-outlinedPrimary:hover': {
            backgroundColor: theme.palette.primary.main
          },
          '&.MuiButton-outlinedSecondary:hover': {
            backgroundColor: theme.palette.secondary.main
          },
          '&.MuiButton-outlinedSuccess:hover': {
            backgroundColor: theme.palette.success.main
          },
          '&.MuiButton-outlinedError:hover': {
            backgroundColor: theme.palette.error.main
          },
          '&.MuiButton-outlinedWarning:hover': {
            backgroundColor: theme.palette.warning.main
          },
          '&.MuiButton-outlinedInfo:hover': {
            backgroundColor: theme.palette.info.main
          }
        },
        sizeSmall: {
          lineHeight: 1.693,
          padding: `${theme.spacing(1, 2.25)}`,
          '&.MuiButton-contained': {
            padding: `${theme.spacing(1, 3.25)}`
          },
          '&.MuiButton-outlined': {
            lineHeight: 1.539,
            padding: `${theme.spacing(1, 3)}`
          }
        },
        sizeLarge: {
          lineHeight: 1.734,
          padding: `${theme.spacing(3, 6)}`,
          '&.MuiButton-contained': {
            padding: `${theme.spacing(3, 6)}`
          },
          '&.MuiButton-outlined': {
            lineHeight: 1.6,
            padding: `${theme.spacing(3, 6)}`
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    }
  }
}