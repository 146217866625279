import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import 'react-datepicker/dist/react-datepicker.css';

export const hexToRGBA = (hexCode: string, opacity: number) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const DatePickerWrapper = styled(Box)<BoxProps>(({ theme }) => {
    return {
        '& .custom-datepicker-wrapper': {
            position: 'fixed',
            top: 0,
            left: 0,
            'z-index': 1000,
        },
        '& .react-datepicker-popper': {
            zIndex: 20,
            position: 'fixed',
        },
        '& .react-datepicker-wrapper': {
            width: '100%',
        },
        '& .react-datepicker': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            outline: `1px solid ${theme.palette.background.default}`,
            boxSizing: 'border-box',
            border: `1px solid ${theme.palette.background.default}`,
            '& .react-datepicker__header': {
                padding: 0,
                border: 'none',
                fontWeight: 700,
                backgroundColor: theme.palette.background.default,
                '&:not(.react-datepicker-year-header)': {
                    '& + .react-datepicker__month, & + .react-datepicker__year': {
                        margin: theme.spacing(3.2),
                        marginTop: theme.spacing(6),
                    },
                },
                '&.react-datepicker-year-header': {
                    '& + .react-datepicker__month, & + .react-datepicker__year': {
                        margin: theme.spacing(3.2),
                        marginTop: theme.spacing(4),
                    },
                },
            },

            '& > .react-datepicker__navigation': {
                top: 18,

                '&.react-datepicker__navigation--previous': {
                    width: 24,
                    height: 24,
                    border: 'none',
                    left: 15,
                },
                '&.react-datepicker__navigation--next': {
                    width: 24,
                    height: 24,
                    border: 'none',
                    right: 15,
                },
            },
            '& .react-datepicker__month-container': {
                paddingTop: theme.spacing(3.2),
            },
            '& .react-datepicker__current-month, & .react-datepicker-year-header': {
                // lineHeight: 2.1,
                // fontSize: '1rem',
                fontWeight: 700,

                letterSpacing: '0.15px',
                marginBottom: theme.spacing(6),
                color: theme.palette.text.primary,
            },
            '& .react-datepicker__day-name': {
                // margin: 0,
                lineHeight: 1.5,
                // width: '2.25rem',
                // fontSize: '0.75rem',
                // letterSpacing: '0.4px',
                color: theme.palette.text.disabled,
            },
            '& .react-datepicker__day': {
                // margin: 0,
                // width: '2.25rem',
                fontSize: '1rem',
                borderRadius: '50%',
                // lineHeight: '2.25rem',
                color: theme.palette.text.primary,
                '&.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start, &.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range, &.react-datepicker__day--range-start':
                    {
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        color: `${theme.palette.common.white} !important`,
                        backgroundColor: `${theme.palette.primary.main} !important`,
                    },
                '&.react-datepicker__day--range-end.react-datepicker__day--in-range': {
                    borderTopRightRadius: '50%',
                    borderBottomRightRadius: '50%',
                    color: `${theme.palette.common.white} !important`,
                    backgroundColor: `${theme.palette.primary.main} !important`,
                },
                '&:focus, &:active': {
                    outline: 0,
                },
                '&.react-datepicker__day--outside-month, &.react-datepicker__day--disabled:not(.react-datepicker__day--selected)':
                    {
                        color: theme.palette.text.disabled,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                '&.react-datepicker__day--highlighted, &.react-datepicker__day--highlighted:hover': {
                    color: theme.palette.success.main,
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    '&.react-datepicker__day--selected': {
                        backgroundColor: `${theme.palette.primary.main} !important`,
                    },
                },
            },
            '& .react-datepicker__day--selected, & .react-datepicker__month--selected, & .react-datepicker__year-text--selected, & .react-datepicker__quarter--selected':
                {
                    color: `${theme.palette.common.black} !important`,
                    fontWeight: 700,
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    '&:hover': {
                        backgroundColor: `${theme.palette.primary.dark} !important`,
                    },
                },
            '& .react-datepicker__day--selected': {
                color: `${theme.palette.common.white} !important`,
                fontWeight: 700,
                backgroundColor: `${theme.palette.primary.dark} !important`,
                borderRadius: '50%',
                '&:hover': {
                    backgroundColor: `${theme.palette.primary.main} !important`,
                },
            },
            '& .react-datepicker__day--keyboard-selected': {
                color: `${theme.palette.common.white} !important`,
                fontWeight: 700,
                backgroundColor: `${theme.palette.secondary.dark} !important`,
                borderRadius: '50%',
                '&:hover': {
                    backgroundColor: `${theme.palette.primary.dark} !important`,
                },
            },

            '& .react-datepicker__day:hover': {
                backgroundColor: `${theme.palette.secondary.main} !important`,
                fontWeight: 700,
                borderRadius: '50%',
                cursor: 'pointer',
            },
            '& .react-datepicker__day--selected:hover, & .react-datepicker__day--keyboard-selected:hover': {
                backgroundColor: `${theme.palette.primary.dark} !important`,
                color: `${theme.palette.text.secondary} !important`,
                borderRadius: '50%',
                cursor: 'pointer',
            },
        },
    };
});

export default DatePickerWrapper;
