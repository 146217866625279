import * as React from "react"

function Insurers() {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x={0.5} width={47} height={48} rx={10} fill="#24262C" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.337 25.407a1.315 1.315 0 001.293 0l.2-.114C34 22.868 34 20.295 34 16.672v-.097c0-.561 0-.779-.108-.901-.09-.103-.258-.139-.566-.255l-3.829-1.292c-.448-.17-.58-.17-1.029 0l-3.83 1.293c-.315.15-.483.186-.571.29-.1.116-.1.318-.1.865v.097c0 3.623 0 6.262 4.17 8.621l.2.114zm.317-5.575l1.746-2.209a.823.823 0 111.29 1.024l-2.32 2.911a.794.794 0 01-1.144.103l-1.438-1.259a.826.826 0 011.083-1.246l.783.676z"
                fill="#F7B500"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.125 31.384h-9.9v.396a2.112 2.112 0 11-4.225 0v-2.943c0-1.233.687-2.363 1.782-2.931l1.63-3.834a2.376 2.376 0 012.186-1.447h2.85l.529 1.65H19.73a.792.792 0 00-.725.474l-1.244 2.827h7.855s2.244 1.79 3.498 1.716c.793-.047 2.113-.924 2.113-.924s1.056.858 1.056 2.178v3.268a2.079 2.079 0 11-4.159 0v-.43zm-11.55-2.442c0-.474.383-.858.857-.858h1.585a.858.858 0 010 1.716h-1.585a.858.858 0 01-.858-.858zm10.758-.858a.858.858 0 000 1.716h1.585a.858.858 0 000-1.716h-1.585z"
                fill="#F7B500"
            />
        </svg>
    )
}

export default Insurers
