import * as React from "react"

function QuotationMarkIcon() {
    return (
        <svg
            width={17}
            height={16}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.776 1.096l-.42 2.912c-.859-.075-1.54.075-2.044.448-.504.355-.85.868-1.036 1.54-.168.653-.196 1.4-.084 2.24h3.584v7.196H9.86V7.956c0-2.445.579-4.293 1.736-5.544 1.157-1.27 2.884-1.708 5.18-1.316zm-9.38 0l-.42 2.912c-.859-.075-1.54.075-2.044.448-.504.355-.85.868-1.036 1.54-.168.653-.196 1.4-.084 2.24h3.584v7.196H.48V7.956c0-2.445.579-4.293 1.736-5.544C3.373 1.142 5.1.704 7.396 1.096z"
                fill="#24262C"
            />
        </svg>
    )
}

export default QuotationMarkIcon