import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import BookDemoImg from './img/BookDemoImg_1.png';
import BookDemoImg_mob from './img/BookDemoImg_mob.png';
import axios from 'axios';
import ModalWindow from '../uknown/ModalWindow';
import FormCard from './components/FormCard';

const API_URL = 'https://api.saas-beep.club/AdminSupport-BookDemo';

interface IProps {
    openDemo: boolean;
    setOpenDemo: (value: boolean) => void;
}

const BookDemoModal = ({ openDemo, setOpenDemo }: IProps) => {
    // const theme = useTheme();
    const localBreakpoints = { sm: 600, md: 900, };
    const isLocalTablet = useMediaQuery(`(max-width:${localBreakpoints.md}px)`);
    // const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const [formValues, setFormValues] = useState({
        name: '',
        company: '',
        email: '',
        date: null,
        comment: '',
    });
    const [errors, setErrors] = useState<Record<string, boolean | string>>({
        email: false,
        errorSubmit: '',
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (field: string, value: string) => {
        setFormValues((prev) => ({ ...prev, [field]: value }));
    };
    const handleClose = () => {
        setOpenDemo(false);
        setIsSubmitted(false);
        setFormValues({ email: '', name: '', company: '', date: null, comment: '' });
        setErrors({ email: false, errorSubmit: '' });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!formValues.email.trim() || errors.email) {
            setErrors((prev) => ({
                ...prev,
                email: true
            }));
            return;
        }

        const formData = {
            email: formValues.email,
            message: formValues.comment || '',
            name: formValues.name || '',
            date: formValues.date || '',
            company: formValues.company || '',
        };

        setIsLoading(true);

        try {
            const response = await axios.post(API_URL, { data: formData });

            if (response.status !== 200) {
                setErrors((prev) => ({
                    ...prev,
                    errorSubmit: 'Error submitting form. Please try again.',
                }));
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrors((prev) => ({
                ...prev,
                errorSubmit: 'Error submitting form. Please try again later.',
            }));
        } finally {
            setIsLoading(false);
            setIsSubmitted(true);
        }
    };

    const hondleRetry = () => {
        setIsSubmitted(false)
        if (!errors.errorSubmit) {
            setFormValues({ email: '', name: '', company: '', date: null, comment: '' });
        }
    }

    return (
        <ModalWindow
            open={openDemo}
            handleClose={handleClose}
            image={isSubmitted ? BookDemoImg_mob : BookDemoImg}
            hondleRetry={hondleRetry}
            errors={errors}
            isSubmitted={isSubmitted}
            title="Get in touch"
            description="Let’s have a call to discuss your requirements and arrange a demo of the app"
            leftBlock={
                <FormCard
                    formValues={formValues}
                    errors={errors}
                    isLoading={isLoading}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isLocalTablet={isLocalTablet}
                    setErrors={setErrors}
                />
            } />
    );
};

export default BookDemoModal;
