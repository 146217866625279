import * as React from "react"

function LogoBeepXl(props: any) {
    return (
        <svg
            width={115}
            height={42}
            viewBox="0 0 115 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.516 8.686c-2.498 0-4.811.79-6.694 2.144V.716A.714.714 0 005.108 0H.713A.714.714 0 000 .716v29.799c0 .395.317.716.713.716h4.395a.714.714 0 00.714-.716v-1.043a11.426 11.426 0 006.694 2.144c6.347 0 11.5-5.138 11.5-11.467 0-6.33-5.153-11.463-11.5-11.463zm0 17.767c-3.489 0-6.318-2.821-6.318-6.3 0-3.478 2.83-6.299 6.318-6.299 3.488 0 6.317 2.821 6.317 6.3 0 3.478-2.83 6.3-6.317 6.3zM48.313 26.849a.716.716 0 01.01 1.027c-3.092 3.02-7.006 3.83-9.836 3.83-8.23 0-12.733-5.14-12.733-11.444 0-6.521 5.108-11.66 12.258-11.66 5.975 0 11.703 3.992 12.025 12.298a.718.718 0 01-.718.746H32.165c.13 2.762 2.686 4.792 6.798 4.792 2.071 0 4.142-.948 5.673-2.213a.72.72 0 01.952.03l2.725 2.594zm-16.019-9.092h11.128c-.43-2.85-3.334-3.928-5.5-3.928-2.075-.005-4.85.95-5.628 3.928z"
                fill="#F2F3F7"
            />
            <path
                d="M110.555 23.543c2.294 0 4.113 1.784 4.113 4.126 0 2.297-1.819 4.031-4.113 4.031-2.338 0-4.073-1.734-4.073-4.031 0-2.342 1.735-4.126 4.073-4.126zm1.784-2.742h-3.528a.647.647 0 01-.644-.593l-1.462-18.84a.645.645 0 01.644-.691h6.447c.376 0 .668.316.644.692l-1.462 18.839a.638.638 0 01-.639.593z"
                fill="#F7B500"
            />
            <path
                d="M102.185 20.164c0-6.33-5.153-11.468-11.5-11.468-2.497 0-4.81.79-6.693 2.144V9.798a.714.714 0 00-.714-.717h-4.395a.714.714 0 00-.713.717v30.598c0 .396.317.717.713.717h4.395a.714.714 0 00.714-.717V29.477a11.425 11.425 0 006.694 2.144c6.351.01 11.499-5.123 11.499-11.457zm-5.182 0c0 3.478-2.83 6.3-6.317 6.3-3.489 0-6.318-2.822-6.318-6.3 0-3.479 2.83-6.3 6.318-6.3 3.488 0 6.317 2.821 6.317 6.3zM74.266 26.849a.716.716 0 01.01 1.027c-3.092 3.02-7.006 3.83-9.835 3.83-8.23 0-12.734-5.14-12.734-11.444 0-6.521 5.108-11.66 12.258-11.66 5.975 0 11.703 3.992 12.025 12.298a.718.718 0 01-.718.746H58.118c.13 2.762 2.686 4.792 6.798 4.792 2.071 0 4.142-.948 5.673-2.213a.72.72 0 01.952.03l2.725 2.594zm-16.024-9.092h11.129c-.431-2.85-3.335-3.928-5.5-3.928-2.076-.005-4.846.95-5.629 3.928z"
                fill="#F2F3F7"
            />
        </svg>
    )
}

export default LogoBeepXl
