import * as React from "react"

function Dealerships() {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"

        >
            <rect x={0.5} width={47} height={48} rx={10} fill="#24262C" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.267 23.267a2.499 2.499 0 110-3.535 2.499 2.499 0 010 3.535zm9.201-.685l-6.417-6.417a.995.995 0 00-.614-.288l-9.344-.872a1.002 1.002 0 00-1.089 1.088l.872 9.344c.023.232.124.45.29.614l6.416 6.418a1.817 1.817 0 002.57-.001l7.316-7.316c.343-.342.532-.799.532-1.285 0-.486-.189-.942-.532-1.285z"
                fill="#F7B500"
            />
        </svg>
    )
}

export default Dealerships
