import * as React from "react"

function CompanyIcon() {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_844_3649)">
                <path
                    d="M13.333 3.333h-2V2a2.002 2.002 0 00-2-2H6.667a2.002 2.002 0 00-2 2v1.333h-2A2.67 2.67 0 000 6v7.333A2.67 2.67 0 002.667 16h10.666A2.67 2.67 0 0016 13.333V6a2.67 2.67 0 00-2.667-2.667zM6 2c0-.368.299-.666.667-.667h2.666c.368 0 .666.299.667.667v1.333H6V2zm4.667 5.333H5.333a.667.667 0 110-1.333h5.334a.667.667 0 110 1.333z"
                    fill="#868C99"
                />
            </g>
            <defs>
                <clipPath id="clip0_844_3649">
                    <path fill="#fff" d="M0 0H16V16H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CompanyIcon
