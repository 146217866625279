import { useRef } from 'react'
import { Box, Typography, useMediaQuery, useTheme, } from '@mui/material'
import VideoPlayer from '../uknown/VideoPlayer'
// import Progress from '../uknown/Progress'

// import HeroVideo from './img/video1.mp4'
// import CarManFuel from './img/video2.mp4'
// import PeopleHuman from './img/video3.mp4'
// import AnimationComponent from '../uknown/AnimationComponent'
// import HeroOne from './animations/4 (2).json'
// import HeroF from './animations/5 (2).json'
// import HeroS from './animations/6 (2).json'
import HeroBannerImg from './img/HeroBannerImg2.png'
import { ExclamationMark } from '../uknown/IconWithText'

// const videos = [
//     {
//         src: HeroVideo,
//         label: '1',
//         animation: HeroOne
//     },
//     {
//         src: CarManFuel,
//         label: '2',
//         animation: HeroF
//     },
//     {
//         src: PeopleHuman,
//         label: '3',
//         animation: HeroS
//     },
// ];

// const SLIDE_DURATION = 4800;

const HeroComponent = () => {
    // const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    // const [progress, setProgress] = useState(0);
    // const [videoDuration, setVideoDuration] = useState(5);
    const videoRef = useRef<HTMLVideoElement>(null);
    // const theme = useTheme();

    // const isLocalTablet = useMediaQuery(`(max-width:800px)`);
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    // const isDesktop = useMediaQuery(theme.breakpoints.between('md', 'xl'));

    // const getSize = () => {
    //     if (isMobile) return { width: 280, height: 440 };
    //     if (isTablet) return { width: 360, height: 540 };
    //     if (isDesktop) return { width: 420, height: 580 };
    //     return { width: 580, height: 720 };
    // };

    // const animationSizes = getSize();

    // useEffect(() => {
    //     const startTime = Date.now();

    //     const interval = setInterval(() => {
    //         const elapsedTime = Date.now() - startTime;
    //         const newProgress = Math.min((elapsedTime / SLIDE_DURATION) * 100, 100);

    //         setProgress(newProgress);

    //         if (elapsedTime >= videoDuration * 1000) {
    //             setCurrentVideoIndex((prev) => (prev + 1) % videos.length);
    //             setProgress(0);
    //         }
    //     }, 50);

    //     return () => clearInterval(interval);
    // }, [currentVideoIndex, videoDuration]);


    // const handleVideoSelect = (index: number) => {
    //     setCurrentVideoIndex(index);
    //     setProgress(0);
    // };

    // const handleVideoLoaded = () => {
    //     if (videoRef.current) {
    //         setVideoDuration(videoRef.current.duration);
    //     }
    // };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                minWidth: '320px',
                display: 'flex',
                flexDirection: 'column',
                height: {
                    xs: '580px',
                    sm: '578px',
                    md: '650px',
                    lg: 'auto'
                },
                maxHeight: '480px',
                borderRadius: { xs: '20px', md: '32px' },
                overflow: 'hidden',
                zIndex: 10,
                mt: 34
            }}
        >

            <AdaptiveSection />

            <VideoPlayer
                ref={videoRef}
                // src={HeroVideo}
                handleVideoLoaded={() => ''}
                poster={HeroBannerImg}
                controls
                autoPlay={false}
                loop={true}
                muted={false}
                styles={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'opacity 300ms ease-in-out',
                }}
            />
        </Box>
    )
}

export default HeroComponent

function AdaptiveSection() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const textSize = {
        xs: '16px',
        sm: '20px',
        md: '32px',
        lg: '40px',
    }

    const currentFontSize = isMobile
        ? textSize.xs
        : isTablet
            ? textSize.sm
            : window.innerWidth >= theme.breakpoints.values.lg
                ? textSize.lg
                : textSize.md;

    const fontSizeValue = parseFloat(currentFontSize);

    return (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: { xs: 2, sm: 4, md: 10 },
                zIndex: 2,
            }}
        >
            <Typography
                sx={{
                    fontSize: {
                        xs: textSize.xs,
                        sm: textSize.sm,
                        md: textSize.md,
                        lg: textSize.lg,
                    },
                    fontWeight: 700,
                    mb: { xs: 12, sm: 12, md: 12, lg: 24 },
                    textAlign: 'center',
                    color: theme.palette.common.white,
                    maxWidth: { xs: '70%', md: '80%', lg: '100%', xl: '80%' },
                }}
            >
                Beep
                <ExclamationMark size={isTablet ? 'small' : 'medium'} fontSize={fontSizeValue} />{' '}
                is a mobile car ecosystem platform which enhances  driver engagement and loyalty for your business
            </Typography>

            <Typography
                sx={{
                    fontSize: {
                        xs: textSize.xs,
                        sm: textSize.sm,
                        md: textSize.md,
                        lg: textSize.lg,
                    },
                    fontWeight: 700,
                    textAlign: 'center',
                    color: theme.palette.common.white,
                    maxWidth: { xs: '80%', md: '68%', lg: '70%', xl: '60%' },
                }}
            >
                We make car ownership simpler, cheaper and more enjoyable
                <ExclamationMark size={isTablet ? 'small' : 'medium'} fontSize={fontSizeValue} />{' '}
            </Typography>

        </Box>
    );
}
