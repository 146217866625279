import * as React from "react"

function Assistance() {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x={0.5} width={47} height={48} rx={10} fill="#24262C" />
            <g clipPath="url(#clip0_1481_12259)" fill="#F7B500">
                <path d="M33.975 27.054l-.376 1.454c-.1.35-.275.651-.551.902-.075.075-.175.15-.276.226l-.45 1.628a.925.925 0 01-.176.351.993.993 0 01-1.053.351l-.977-.25c-.526-.15-.852-.702-.702-1.228l.251-.978-7.743-2.08-.251.978c-.15.526-.702.852-1.228.701l-.977-.25a.99.99 0 01-.727-.827c-.025-.126 0-.276.025-.401L19.19 26c-.05-.1-.1-.225-.125-.325-.1-.35-.1-.727-.025-1.077l.376-1.454c.15-.601.576-1.078 1.152-1.328.176-.075.376-.125.577-.15l.576-.752 1.002-1.354c.1-.15.226-.275.351-.4.25-.251.526-.452.827-.602.15-.075.3-.15.476-.2a3.37 3.37 0 011.003-.176c.35-.025.701.025 1.052.125l3.834 1.028c.351.1.677.226.978.4.902.552 1.478 1.505 1.604 2.557l.2 1.679.1.952c.326.226.577.552.702.928.225.375.225.801.125 1.202zm-11.953-3.733c-.502-.176-1.028.1-1.203.6 0 .026-.025.076-.025.101-.176.501.1 1.028.601 1.203.025 0 .075.025.1.025.577.15 1.429.476 1.58-.1.15-.576-.477-1.68-1.053-1.83zm9.096.877l-.2-1.68a2.12 2.12 0 00-.125-.425 1.408 1.408 0 00-.978-.827l-3.859-1.028a1.52 1.52 0 00-1.604.551l-1.002 1.354 5.388 1.453 2.38.602zm.577 1.704c-.577-.15-1.68.476-1.83 1.052-.15.576.752.727 1.329.877a.975.975 0 001.278-.601c.175-.527-.075-1.103-.602-1.278-.05-.025-.125-.025-.175-.05z" />
                <path d="M18.489 22.895l-.401 1.453c-.1.351-.125.727-.075 1.078l-1.103.902c-.125.1-.3.075-.4-.05-.05-.075-.076-.15-.05-.226l.475-2.406a.283.283 0 00-.225-.325h-.025l-2.431-.226c-.15-.025-.276-.15-.25-.3 0-.076.05-.15.125-.201l2.03-1.353c.125-.1.175-.276.075-.401 0 0 0-.025-.026-.025l-1.553-1.88a.282.282 0 01.05-.4c.075-.05.15-.076.225-.05l2.406.475c.15.025.3-.075.326-.225v-.025l.226-2.456c.024-.15.15-.276.3-.25.075 0 .15.05.2.124l1.354 2.03c.075.126.25.176.4.075 0 0 .026 0 .026-.024l1.88-1.554c.125-.1.3-.075.4.05.05.075.075.15.05.225l-.35 1.755c-.075.1-.176.2-.251.3l-1.002 1.354-.326.45c-.977.251-1.804 1.078-2.08 2.106z" />
            </g>
            <defs>
                <clipPath id="clip0_1481_12259">
                    <path fill="#fff" transform="translate(14 14)" d="M0 0H20V20H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Assistance
