import React from 'react';
import { Box } from '@mui/material';

interface VideoPlayerProps {
    src?: string;
    ref?: React.Ref<HTMLVideoElement>;
    handleVideoLoaded?: () => void;
    poster?: string;
    width?: string | number;
    height?: string | number;
    maxHeight?: string | number;
    controls?: boolean;
    autoPlay?: boolean;
    loop?: boolean;
    muted?: boolean;
    styles?: React.CSSProperties;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    src,
    ref,
    handleVideoLoaded,
    styles,
    poster
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
                aspectRatio: '16 / 9',
                ...styles,
            }}
        >
            <video
                ref={ref}
                autoPlay
                src={src || ''}
                loop
                muted
                playsInline
                controls={false}
                poster={poster}
                onLoadedMetadata={handleVideoLoaded}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
            >
                Your browser does not support the video tag.
            </video>
        </Box>
    );
};

export default VideoPlayer;
