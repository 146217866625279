import * as React from "react"

function CountryIcon() {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="#868C99">
                <path d="M.559 7.332A.89.89 0 000 8.157v6.954c0 .314.318.529.61.412l3.834-1.745V7.303a8.413 8.413 0 01-.59-1.29L.56 7.333zM8 11.324c-.39 0-.76-.171-1.014-.47a29.355 29.355 0 01-1.653-2.132v5.056l5.334 1.777V8.722a29.325 29.325 0 01-1.653 2.131c-.253.3-.623.471-1.014.471zm7.39-5.514l-3.834 1.746v8L15.44 14a.888.888 0 00.559-.825V6.223a.444.444 0 00-.61-.413z" />
                <path d="M8.166.667a3.5 3.5 0 00-3.5 3.5c0 1.562 2.288 4.41 3.164 5.445.177.21.495.21.672 0 .877-1.034 3.164-3.883 3.164-5.445a3.5 3.5 0 00-3.5-3.5zm0 4.666a1.167 1.167 0 110-2.333 1.167 1.167 0 010 2.333z" />
            </g>
        </svg>
    )
}

export default CountryIcon
