import * as React from "react"

function TelegramIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16s6.268 14 14 14zm-2.986-7.803l.264-3.63 6.818-5.968c.297-.256-.064-.398-.46-.142l-8.43 5.142-3.628-1.1c-.79-.239-.804-.765.165-1.132l14.194-5.298c.657-.238 1.232.142 1.017 1.114l-2.415 11.033c-.182.778-.657.971-1.332.605l-3.679-2.627-1.775 1.654c-.197.19-.36.35-.74.35z"
                fill="#F2F3F7"
            />
        </svg>
    )
}

export default TelegramIcon
